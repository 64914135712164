import { StandardSubSystemPageType } from "./StandardSubSystemPage.types";
import {
  PaginationDTO,
  SearchParameterDTO,
  StandardSubSystemDTO,
} from "../../../common/types/common.dto.types";
import {
  EnumButtonModeType,
  EnumGridDataShowRowCount,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { PageModeType } from "../../../common/types/common.page.type";
import { createSlice } from "@reduxjs/toolkit";
import SimpleReactValidator from "simple-react-validator";

const initialState = {
  PageMode: {
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  Data: {} as PaginationDTO,
  StandardSubSystem: {
    Id: 0,
    Name: "",
    StandardId: 0,
    Version: 1,
    Status: EnumStatus.Draft,
  } as StandardSubSystemDTO,
  StandardSubSystemSearchParameter: {
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as SearchParameterDTO,
  SubSystemModalOpen: false,
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  ButtonModeType: EnumButtonModeType.Save,
} as StandardSubSystemPageType;

export const standardSubSystemSlice = createSlice({
  name: "standardSubSystemList",
  initialState: initialState,
  reducers: {
    setLoading: (
      state: StandardSubSystemPageType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsLoading: action.payload };
    },
    setStandardSubSystemInitState: (
      state: StandardSubSystemPageType,
      action: { payload: StandardSubSystemPageType; type: string }
    ) => {
      return {
        ...state,
        StandardSubSystem: {
          ...state.StandardSubSystem,
          StandardId: action.payload.StandardSubSystem.StandardId,
          Name: initialState.StandardSubSystem.Name,
        },
        StandardSubSystemSearchParameter: {
          ...state.StandardSubSystemSearchParameter,
          StandardSubSystemSearchParams: {
            ...state.StandardSubSystemSearchParameter
              .StandardSubSystemSearchParams,
            StandardId: action.payload.StandardSubSystem.StandardId,
            Name: action.payload.StandardSubSystem.Name,
          },
        },
      };
    },
    setStandardSubSystemList: (
      state: StandardSubSystemPageType,
      action: { payload: StandardSubSystemPageType; type: string }
    ) => {
      // console.log({ setStandardSubSystemList: action.payload.Data });
      return { ...state, IsEnable: false, Data: action.payload.Data };
    },
    setPagination: (
      state: StandardSubSystemPageType,
      action: { payload: PaginationDTO; type: string }
    ) => {
      return {
        ...state,
        Data: {
          Data: action.payload.Data,
          TotalRecordsCount: action.payload.Data?.TotalRecordsCount,
          SelectedPage: action.payload.Data?.SelectedPage,
        } as PaginationDTO,
      };
    },
    setStandardSubSystemSearchParameter: (
      state: StandardSubSystemPageType,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        StandardSubSystemSearchParameter: {
          ...state.StandardSubSystemSearchParameter,
          StandardSubSystemSearchParams: {
            ...state.StandardSubSystemSearchParameter
              .StandardSubSystemSearchParams,
            StandardId: action.payload.StandardSubSystemSearchParams.StandardId,
          },
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          SortBy: action.payload.SortBy,
          GridPageSize: action.payload.GridPageSize,
        } as SearchParameterDTO,
      };
    },

    setStandardSubSystem: (
      state: StandardSubSystemPageType,
      action: { payload: StandardSubSystemDTO; type: string }
    ) => {
      return { ...state, IsEnable: false, StandardSubSystem: action.payload };
    },
    setDeleteStandardSubSystem: (
      state: StandardSubSystemPageType,
      action: { payload: StandardSubSystemDTO; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        deleteStandardSubSystem: action.payload,
      };
    },
    changePageMode: (
      state: StandardSubSystemPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            IsDisable: false,
            IsShowCloseBtn: true,
            IsShowEditBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: true,
          },
        };
      }
    },
    setPageMode: (
      state: StandardSubSystemPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.Default === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.Default,
            IsDisable: true,
            IsShowAddBtn: true,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowAddBtn: false,
            IsShowEditBtn: true,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: true,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: true,
            IsShowSaveBtn: true,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: true,
            IsShowCloseBtn: false,
          },
        };
      }
    },
    setSubSystemModalOpen: (
      state: StandardSubSystemPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        SubSystemModalOpen: action.payload,
      };
    },
  },
});
export const {
  setStandardSubSystemInitState,
  setDeleteStandardSubSystem,
  setStandardSubSystemList,
  setStandardSubSystem,
  setStandardSubSystemSearchParameter,
  setPagination,
  setLoading,
  setSubSystemModalOpen,
  setPageMode,
} = standardSubSystemSlice.actions;
export const standardSubSystemReducer = standardSubSystemSlice.reducer;
