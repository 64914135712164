// assets
import InspectionSetIco from "../../../icons/inspectionSet.svg";

import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";

import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import {
  InspectionSetDTO,
  SearchParameterDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import { EnumListShowRowCountList } from "../../../common/enums/common.enums";
import { GETINSPECTIONSETLIST } from "./InspectionSetsPage.action";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  setInspectionSetList,
  setPagination,
} from "./InspectionSetsPage.reducer";
import { InspectionSetPageInitialState } from "./InspectionSetsPage.types";
import { getInspectionSetList } from "./InspectionSetsPage.api";

// ==============================|| VIEWS - PAGES - INSPECTIONSETS - SAGA ||============================== //

const getInspectionSetsWatcher = function* (): any {
  // constant
  const icons = { InspectionSetIco };

  yield takeEvery(GETINSPECTIONSETLIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    //Clear state
    yield put(
      setPagination({
        ItemList: [] as Array<PaginationListItemType>,
        ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
        TotalRecordsCount: 0,
        SelectedPage: 1,
        StatusSummaryCount: {} as StatusSummaryCountDTO,
      } as HPRListPaginationType)
    );
    yield put(setInspectionSetList({} as InspectionSetPageInitialState));

    const searchPara: SearchParameterDTO = action.payload;
    const result: InspectionSetPageInitialState = yield call(() =>
      getInspectionSetList(searchPara)
    );
    if (result == null && !result) {
    } else {
      yield put(
        setPagination({
          ItemList: result.Data?.Data?.map(
            (inspectionSet: InspectionSetDTO) => {
              return {
                Icon: icons.InspectionSetIco,
                ShowCommonListIcon: true,
                Id: inspectionSet.Id,
                ItemHeading: inspectionSet.Name,
                Status: inspectionSet.StatusName,
                StatusID: inspectionSet.Status,
                Version: inspectionSet.Version.toFixed(1),
                StandardList: inspectionSet.StandardList,
                ModifiedBy: inspectionSet.ModifiedBy,
                ModifiedDate: inspectionSet.ModifiedDate,
              } as PaginationListItemType;
            }
          ),
          ItemShowCount: result.Data?.PageSize,
          TotalRecordsCount: result.Data?.TotalRecordsCount,
          StatusSummaryCount: result.Data?.StatusSummaryCount,
          SelectedPage: result.Data?.SelectedPage,
        } as HPRListPaginationType)
      );
      yield put(setInspectionSetList(result));
    }
    yield put(setLoading(false));
  });
};

const InspectionSetsSaga = function* () {
  yield all([getInspectionSetsWatcher()]);
};

export default InspectionSetsSaga;
