// project imports
import {
  BulkApproveRemediationTrackerTaskDTO,
  BulkRejectRemediationTrackerTaskDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { RemediationTrackerTasksPageType } from "./RemediationTrackerTasksPage.types";

// ==============================|| VIEWS - PAGES - REMEDIATION TRACKER TASKS - API ||============================== //

// client object for axios calls to backend
const client = new ApiClient();

export const getRemediationTrackerTaskInitialData =
  async (): Promise<ActionResponseType> => {
    try {
      const URL: string = `RemediationTrackerTasks/GetRemediationTrackerTaskInitialData`;
      let data = {} as ActionResponseType;
      await client
        .get<ActionResponseType>(URL)
        .then((response: ActionResponseType) => {
          data = response;
        })
        .catch((error) => {
          data = {} as ActionResponseType;
        });

      return data;
    } catch (error) {
      throw error;
    }
  };

export const getRemediationTrackerTaskList = async (
  searchParameter: SearchParameterDTO
): Promise<RemediationTrackerTasksPageType> => {
  try {
    const URL: string = `RemediationTrackerTasks/GetRemediationTrackerTasks`;
    let data = {} as RemediationTrackerTasksPageType;
    await client
      .post<ActionResponseType>(URL, searchParameter)
      .then((response: ActionResponseType) => {
        data.Data = response.Data;
      })
      .catch((error) => {
        data = {} as RemediationTrackerTasksPageType;
      });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getRemediationTrackerTasksColumnWiseFilter = async (
  searchParameter: SearchParameterDTO
): Promise<RemediationTrackerTasksPageType> => {
  try {
    const URL: string = `RemediationTrackerTasks/GetRemediationTrackerTasksColumnWiseFilter`;
    let data = {} as RemediationTrackerTasksPageType;
    await client
      .post<ActionResponseType>(URL, searchParameter)
      .then((response: ActionResponseType) => {
        data.Data = response.Data;
      })
      .catch((error) => {
        data = {} as RemediationTrackerTasksPageType;
      });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getRemediationTrackerTaskDetails = async (
  searchParameter: SearchParameterDTO
): Promise<RemediationTrackerTasksPageType> => {
  try {
    const URL: string = `RemediationTrackerTasks/GetRemediationTrackerTaskDetails`;
    let data = {} as RemediationTrackerTasksPageType;
    await client
      .post<ActionResponseType>(URL, searchParameter)
      .then((response: ActionResponseType) => {
        data.Data = response.Data;
      })
      .catch((error) => {
        data = {} as RemediationTrackerTasksPageType;
      });

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteRemediationTrackerTask = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `RemediationTrackerTasks/DeleteRemediationTrackerTask/${id}`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, id)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        data = {} as ActionResponseType;
      });

    return data;
  } catch (error) {
    throw error;
  }
};

export const bulkApproveRemediationTrackerTaskApi = async (
  selectedRemediationTrackerTask: BulkApproveRemediationTrackerTaskDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `RemediationTrackerTasks/BulkApproveRemediationTrackerTask`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, selectedRemediationTrackerTask)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        data = {} as ActionResponseType;
      });

    return data;
  } catch (error) {
    throw error;
  }
};

export const bulkRejectRemediationTrackerTaskApi = async (
  selectedRemediationTrackerTask: BulkRejectRemediationTrackerTaskDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `RemediationTrackerTasks/BulkRejectRemediationTrackerTask`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, selectedRemediationTrackerTask)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        data = {} as ActionResponseType;
      });

    return data;
  } catch (error) {
    throw error;
  }
};
