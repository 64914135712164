// saga $ redux
import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";

// project imports
import { EnumPageMode, EnumStatus } from "../../../common/enums/common.enums";
import { SiteDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { GETDROPDOWNS, SAVESITE } from "./LocationPage.action";
import { getDropDownList, saveSite } from "./LocationPage.api";
import {
  changePageMode,
  setDropdownList,
  setReadOnlyMode,
  setSaveLocation,
} from "./LocationPage.reducer";
import { LocationPageType } from "./LocationPage.types";

// ==============================|| VIEWS - PAGES - SITE - SAGA ||============================== //

// watcher for SAVES SITE
const saveLocationWatcher = function* (): any {
  yield takeEvery(SAVESITE, function* (action: AnyAction): any {
    const state: LocationPageType = yield select(
      (s: RootState) => s.locationReducer
    );

    // set page loading on
    yield put(setLoading(true));

    let site: SiteDTO = action.payload["site"];
    let saveAction: number = action.payload["action"];

    site.SaveAction = saveAction;

    // get data from backend
    try {
      let result: ActionResponseType = yield call(() =>
        saveSite({ ...site, EmployeeList: [] })
      );
      if (result.IsSuccess) {
        site = result.Data;
        if (
          saveAction === EnumStatus.Published ||
          saveAction === EnumStatus.Draft
        ) {
          yield put(setSaveLocation(site));

          // change page mode (change disable and button show hide)
          yield put(changePageMode(EnumPageMode.ViewMode));

          yield put(setReadOnlyMode(true));

          yield put(
            openPopup({
              Open: true,
              BodyText: "Site saved successfully",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: "Site deactivated successfully",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
          yield put(setSaveLocation(site));

          yield put(changePageMode(EnumPageMode.ViewMode));
          yield put(setReadOnlyMode(true));
        }
      } else {
        if (saveAction === EnumStatus.Deactivated) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred when deactivating data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "Site not saved successfully",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred saving site",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading off
    yield put(setLoading(false));
  });
};

const getDropDownListWatcher = function* (): any {
  yield takeEvery(GETDROPDOWNS, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    const SiteId: number = action.payload;

    const result: SiteDTO = yield call(() => getDropDownList(SiteId));

    if (result == null && !result) {
    } else {
      yield put(setDropdownList(result));
    }

    if (SiteId == 0) {
      yield put(changePageMode(EnumPageMode.AddMode));
    }

    yield put(setLoading(false));
  });
};

const locationSaga = function* () {
  yield all([saveLocationWatcher(), getDropDownListWatcher()]);
};

export default locationSaga;
