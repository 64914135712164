//-------common imports-----//
import Grid from "@mui/material/Grid";
import { ChangeEvent, useEffect, useRef, useState } from "react";

//Search with filte textbox
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
//Icons Import

// material
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

// project imports
import { setStandardSearchParameter } from "./StandardsPage.reducer";
import { ArrowLeft2, SearchNormal1, AddCircle } from "iconsax-react";
// redux
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getStandardList } from "./StandardsPage.action";
import { useLocation, useNavigate } from "react-router-dom";
import HPRListPagination from "../../../components/common/HPRListPagination/HPRListPagination";
import {
  EnumListShowRowCountList,
  EnumPageType,
  EnumSortTypes,
  EnumStandardSortType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { StandardPageParametersType } from "../StandardPage/StandardPage.types";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import HPRStatus from "../../../components/common/HPRStatus/HPRStatus";
import {
  DropDownDTO,
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StandardDTO,
  StatusSummaryDTO,
} from "../../../common/types/common.dto.types";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import {
  StandardsPageInitialState,
  StandardsPageParametersType,
} from "./StandardsPage.types";

// ==============================|| VIEWS - PAGES - STANDARD ||============================== //

const initialState = {
  Data: {} as PaginationDTO,
  Loading: false,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
  StandardSearchParameter: {
    StandardSearchParams: {
      Name: "",
      Status: EnumStatus.Published,
    } as StandardDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    //GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
    SortBy: {
      Label: "Name",
      Value: EnumStandardSortType.Name.toString(),
      SortType: EnumSortTypes.Ascending,
    } as SortDropDownDTO,
    BasicSearchValue: "",
  } as SearchParameterDTO,
  EmployeeDropDownList: [] as Array<DropDownDTO>,
} as StandardsPageInitialState;

const StandardsPage = (props: PageRegistrationProperties) => {
  // registering page properties
  usePageRegistration(props);

  const dataFetchedRef = useRef(false);
  const PageChangeRef = useRef(false);

  // navigate
  const navigate = useNavigate();

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: StandardsPageParametersType = location.state;

  // reducer
  const standardsState = useAppSelector((state) => state.standardsReducer);
  const dispatch = useAppDispatch();
  //-----standard list heigh ------
  const mainHeading = useRef(null);
  const subHeading = useRef(null);
  const statusTag = useRef(null);
  const searchBar = useRef(null);

  //Set Hight
  const [listHeight, setListHeight] = useState(0);

  // DataGrid Columns
  // const columns: HPRGridColDef[] = [
  //   {
  //     field: "Id",
  //     headerName: "Id",
  //     hideColumn: true,
  //     hideable: false,
  //     filterable: false,
  //     flex: 0.5,
  //   },
  //   {
  //     field: "Name-1",
  //     headerName: "Standard",
  //     flex: 14,
  //     valueGetter: (params) => params.row?.ItemHeading,
  //     value: "",
  //   },
  //   {
  //     field: "SearchTotalQuestionCount-3",
  //     headerName: "Total Question(s)",
  //     flex: 6,
  //     valueGetter: (params) => params.row?.TotalQuestions,
  //     value: "",
  //   },
  //   {
  //     field: "SearchModifiedByIdList-3",
  //     headerName: "Last modified by",
  //     flex: 6,
  //     valueGetter: (params) => params.row?.ModifiedBy,
  //     value: "",
  //   },
  //   {
  //     field: "ModifiedDate-4",
  //     headerName: "Last modified date",
  //     flex: 6,
  //     valueGetter: (params) =>
  //       dayjs(params.row?.ModifiedDate).format("DD-MMM-YYYY HH:mm"),
  //     value: "",
  //   },
  //   {
  //     field: "SearchPrimarySMEIdList-3",
  //     headerName: "Global Primary SME",
  //     flex: 6,
  //     valueGetter: (params) => params.row?.PrimarySME?.Sme?.DisplayName,
  //     value: "",
  //   },
  //   {
  //     field: "SearchSecondarySMEIdList-3",
  //     headerName: "Global Secondary SME(s)",
  //     flex: 6,
  //     valueGetter: (params) =>
  //       params.row?.SecondarySME?.filter((sme: StandardSmeType, smeIndex) => {
  //         return smeIndex < 2;
  //       })
  //         ?.map((sme: StandardSmeType, smeIndex) => {
  //           return sme.Sme.DisplayName;
  //         })
  //         ?.join("; "),
  //     value: "",
  //   },
  //   {
  //     field: "SearchStatusIdList-3",
  //     headerName: "Status",
  //     flex: 6,
  //     renderCell: (params) => {
  //       return (
  //         <div
  //           className={`MuiDataGrid-cellContent status-color ${setStatusColorStyleClass(
  //             params.row?.StatusID
  //           )}`}
  //         >
  //           {params.row?.Status}
  //         </div>
  //       );
  //     },
  //     value: "",
  //   },
  //   {
  //     field: "Version-3",
  //     headerName: "Version",
  //     flex: 6,
  //     valueGetter: (params) => params.row?.Version,
  //     value: "",
  //   },
  //   {
  //     field: " ",
  //     flex: 1,
  //     hide: parameter?.SearchStatusId === EnumStatus.Deactivated,
  //     sortable: false,
  //     disableColumnMenu: true,
  //     renderCell: (params) => {
  //       const onClick = (e: any) => {
  //         e.stopPropagation(); // don't select this row after clicking
  //         ItemOnClickFunction(params.row?.Id);
  //       };

  //       return (
  //         <>
  //           <Button
  //             className="standards-icon-forward d-flex flex-valign-middle cursor-pointer grid-icon-btn"
  //             onClick={onClick}
  //           >
  //             <ArrowRight2 size="32" variant="Outline" />
  //           </Button>
  //         </>
  //       );
  //     },
  //   },
  // ];

  const setStatusColorStyleClass = (status: EnumStatus): string => {
    let colorClass = "";

    if (status === EnumStatus.Published) {
      colorClass = "green";
    } else if (status === EnumStatus.Deactivated) {
      colorClass = "gray";
    } else if (status === EnumStatus.Draft) {
      colorClass = "yellow";
    }

    return colorClass;
  };

  //-----Page load-----
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    dispatch(
      setStandardSearchParameter({
        ...initialState.StandardSearchParameter,
        StandardSearchParams: {
          ...initialState.StandardSearchParameter.StandardSearchParams,
          Status:
            parameter?.SearchStatusId === undefined
              ? EnumStatus.Published
              : parameter?.SearchStatusId,
        },
      })
    );
    dispatch(
      getStandardList({
        ...initialState.StandardSearchParameter,
        StandardSearchParams: {
          ...initialState.StandardSearchParameter.StandardSearchParams,
          Status:
            parameter?.SearchStatusId === undefined
              ? EnumStatus.Published
              : parameter?.SearchStatusId,
        },
      })
    );

    let listheight =
      window.innerHeight -
      // searchBar.current.offsetHeight -
      subHeading.current.offsetHeight -
      statusTag.current.offsetHeight -
      // mainHeading.current.offsetHeight -
      226;
    setListHeight(listheight);
    function handleResize() {
      let listheight =
        window.innerHeight -
        // searchBar.current.offsetHeight -
        subHeading.current.offsetHeight -
        statusTag.current.offsetHeight -
        // mainHeading.current.offsetHeight -
        226;
      setListHeight(listheight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //-----on change serach TextBox-----
  const onsearchNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    dispatch(
      setStandardSearchParameter({
        ...standardsState.StandardSearchParameter,
        StandardSearchParams: {
          ...standardsState.StandardSearchParameter.StandardSearchParams,
          //Name: event.target.value,
        },
        BasicSearchValue: event.target.value,
      })
    );

    if (!event.target.value) {
      dispatch(
        getStandardList({
          ...standardsState.StandardSearchParameter,
          StandardSearchParams: {
            ...standardsState.StandardSearchParameter.StandardSearchParams,
            //Name: "",
          },
          BasicSearchValue: event.target.value,
        })
      );
    }
  };

  //-----enetr Key Press ===> serach TextBox-----
  const onsearchNameKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      dispatch(
        setStandardSearchParameter({
          ...standardsState.StandardSearchParameter,
          IsAdvanceSearch: false,
          SelectedPage: 1,
        })
      );
      dispatch(
        getStandardList({
          ...standardsState.StandardSearchParameter,
          SelectedPage: 1,
          IsAdvanceSearch: false,
        })
      );
    }
  };

  //-----Serach Buttion Click-----
  const serachBtnClick = (IsAdvanceSearch: boolean) => {
    if (
      standardsState.StandardSearchParameter.IsAdvanceSearch === IsAdvanceSearch
    ) {
      dispatch(
        getStandardList({
          ...standardsState.StandardSearchParameter,
          SelectedPage: 1,
          IsAdvanceSearch: IsAdvanceSearch,
        })
      );
    } else {
      PageChangeRef.current = true;
      dispatch(
        setStandardSearchParameter({
          ...standardsState.StandardSearchParameter,
          IsAdvanceSearch: IsAdvanceSearch,
          SelectedPage: 1,
        })
      );
    }
  };

  //-----selected Page Change (callback function)-----
  // const onPageChange = (newPage: number, pageSize: number) => {
  //   //PageChangeRef.current = true;
  //   dispatch(
  //     setStandardSearchParameter({
  //       ...standardsState.StandardSearchParameter,
  //       StandardSearchParams: {
  //         ...standardsState.StandardSearchParameter.StandardSearchParams,
  //       },
  //       SelectedPage: newPage,
  //       GridPageSize: pageSize,
  //     })
  //   );
  //   PageChangeRef.current = true;
  // };

  const onPageChange = (page: number) => {
    //PageChangeRef.current = true;
    dispatch(
      setStandardSearchParameter({
        ...standardsState.StandardSearchParameter,
        StandardSearchParams: {
          ...standardsState.StandardSearchParameter.StandardSearchParams,
        },
        SortBy: selectedSortBy,
        SelectedPage: page,
        ListPageSize: standardsState.Data?.PageSize,
      })
    );
    dispatch(
      getStandardList({
        ...standardsState.StandardSearchParameter,
        SelectedPage: page,
        ListPageSize: standardsState.Data?.PageSize,
      })
    );
  };

  //-----call after selected page change(pagination) or is advanceSearch-----
  useEffect(() => {
    if (!PageChangeRef.current) return;
    PageChangeRef.current = false;
    dispatch(
      getStandardList({
        ...standardsState.StandardSearchParameter,
        SelectedPage: 1,
      })
    );
  }, [standardsState.StandardSearchParameter]);

  const [selectedSortBy, setSelectedSortBy] = useState<SortDropDownDTO>({
    Label: "Name",
    Value: EnumStandardSortType.Name.toString(),
    SortType: EnumSortTypes.Ascending,
  } as SortDropDownDTO);

  const searchByStatusOnClick = (status: EnumStatus) => {
    if (
      status !==
      standardsState.StandardSearchParameter.StandardSearchParams?.Status
    ) {
      PageChangeRef.current = true;
      dispatch(
        setStandardSearchParameter({
          ...standardsState.StandardSearchParameter,
          StandardSearchParams: {
            ...standardsState.StandardSearchParameter.StandardSearchParams,
            Status: status,
          },
          SelectedPage: 1,
        })
      );
    } else {
      dispatch(
        getStandardList({
          ...standardsState.StandardSearchParameter,
          StandardSearchParams: {
            ...standardsState.StandardSearchParameter.StandardSearchParams,
          },
          SelectedPage: 1,
        })
      );
    }
  };

  //-----List Item onclick callback function-----
  const ItemOnClickFunction = (Id: number) => {
    navigate("/standard", {
      state: {
        StandardId: Id,
        SearchStatusId:
          standardsState.StandardSearchParameter.StandardSearchParams.Status,
      } as StandardPageParametersType,
    });
  };

  const AddBtnOnclick = () => {
    navigate("/standard", {
      state: {
        SearchStatusId:
          standardsState.StandardSearchParameter.StandardSearchParams.Status,
      } as StandardPageParametersType,
    });
  };

  const BacktnOnclick = () => {
    navigate("/");
  };

  const setNoRecordsFoundMsg = (TotalRecordsCount: number) => {
    let msg = "";
    if (TotalRecordsCount === 0) {
      msg = "No records found";
    } else if (TotalRecordsCount === 1) {
      msg = "1 record found";
    } else if (TotalRecordsCount > 1) {
      msg = `${TotalRecordsCount} records found`;
    }

    return msg;
  };

  function onChangePagination(Name: string, Id: string) {
    dispatch(
      setStandardSearchParameter({
        ...standardsState.StandardSearchParameter,
        StandardSearchParams: {
          ...standardsState.StandardSearchParameter.StandardSearchParams,
          Status:
            standardsState.StandardSearchParameter.StandardSearchParams?.Status,
        },
        SelectedPage: 1,
        ListPageSize: parseInt(Id),
      })
    );
    PageChangeRef.current = true;
  }

  function onChangeSortBy(SelectedSortState: SortDropDownDTO) {
    dispatch(
      setStandardSearchParameter({
        ...standardsState.StandardSearchParameter,
        StandardSearchParams: {
          ...standardsState.StandardSearchParameter.StandardSearchParams,
        },
        SortBy: SelectedSortState,
        SelectedPage: 1,
      })
    );
    setSelectedSortBy(SelectedSortState);
    PageChangeRef.current = true;
  }

  // const onSortByCallBack = (selectedSortBy: SortDropDownDTO) => {
  //   dispatch(
  //     setStandardSearchParameter({
  //       ...standardsState.StandardSearchParameter,
  //       StandardSearchParams: {
  //         ...standardsState.StandardSearchParameter.StandardSearchParams,
  //       },
  //       SortBy: selectedSortBy,
  //     })
  //   );
  //   PageChangeRef.current = true;
  // };

  // const onChangeColumnFilterCallBack = (field: string, value: string) => {
  //   if (field === "SearchTotalQuestionCount") {
  //     value = value || null;
  //   }

  //   dispatch(
  //     setStandardSearchParameter({
  //       ...standardsState.StandardSearchParameter,
  //       StandardSearchParams: {
  //         ...standardsState.StandardSearchParameter.StandardSearchParams,
  //         [field]: value,
  //       },
  //     })
  //   );
  //   PageChangeRef.current = true;
  // };

  // const onChangeDateRangeColumnFilterCallBack = (
  //   field: string,
  //   value: string[]
  // ) => {
  //   dispatch(
  //     setStandardSearchParameter({
  //       ...standardsState.StandardSearchParameter,
  //       StandardSearchParams: {
  //         ...standardsState.StandardSearchParameter.StandardSearchParams,
  //       },
  //       FromDate: value[0] ? dayjs(value[0]).format("YYYY-MM-DD") : null,
  //       ToDate: value[1] ? dayjs(value[1]).format("YYYY-MM-DD") : null,
  //     })
  //   );
  //   PageChangeRef.current = true;
  // };

  // const onChangeDropDownColumnFilterCallBack = (
  //   field: string,
  //   columnType: EnumGridColumnType,
  //   value: string[]
  // ) => {
  //   dispatch(
  //     setStandardSearchParameter({
  //       ...standardsState.StandardSearchParameter,
  //       StandardSearchParams: {
  //         ...standardsState.StandardSearchParameter.StandardSearchParams,
  //         [field]:
  //           columnType === EnumGridColumnType.SingleSelectDropDown
  //             ? value[0]?.toString
  //             : value?.map((item) => {
  //                 return item?.toString();
  //               }),
  //       },
  //     })
  //   );
  //   PageChangeRef.current = true;
  // };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={standardsState !== undefined ? standardsState?.Loading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Grid
        container
        columns={12}
        className="global-header-wrap"
        ref={mainHeading}
      > */}
      {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
          <h1>{props.PageTitle}</h1>
        </Grid> */}
      {/* <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vlign-middle status-version-wrap"
        ></Grid>
      </Grid> */}

      {/* Main Backgroud */}

      <div className="content-section-card top-main-padding">
        <Grid container columns={12}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="main-elements-background"
          >
            {/* Serarch */}
            <Grid container className="" ref={subHeading}>
              {/* Global Sub Header with  Buttons*/}

              <div className="flex--container width100-p top-main-border pl-10 pr-10">
                <div className="flex__item--inherit pr-10">
                  <h2>
                    <Button
                      aria-label="back"
                      className="sub-head-back-btn back"
                      onClick={BacktnOnclick}
                      title="Back to home page"
                    >
                      {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                      <ArrowBackIosNewOutlinedIcon className="" />
                      <span className="body-bold secondary-color">Back</span>
                    </Button>
                  </h2>
                </div>
                <div className="global-header-wrap">
                  <h1 className="page_title">{props.PageTitle}</h1>
                </div>
                <div className="flex__item search-bar--small ml-10 mr-10">
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <InputBase
                      value={
                        standardsState.StandardSearchParameter
                          ?.BasicSearchValue ?? ""
                      }
                      onChange={onsearchNameChange}
                      onKeyPress={onsearchNameKeyPress}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Standards"
                      inputProps={{ "aria-label": "Standard" }}
                      type="search"
                    />
                    <IconButton
                      onClick={() => {
                        serachBtnClick(false);
                      }}
                      type="button"
                      aria-label="search"
                      className="m-0"
                      sx={{ p: "10px" }}
                    >
                      {/* <SearchIcon /> */}
                      <SearchNormal1
                        variant="Outline"
                        className="ico-secondary"
                        size={18}
                      />
                    </IconButton>
                  </Paper>
                </div>
                <Button
                  className="primary-btn-small"
                  variant="outlined"
                  startIcon={<AddCircle variant="Outline" />}
                  onClick={AddBtnOnclick}
                >
                  Add
                </Button>
              </div>
              {/* 
              <Grid container columns={12} className="global-subhead-btn-wrap">
                <Grid
                  item
                  lg={10}
                  md={10}
                  sm={12}
                  xs={12}
                  className="global-subhead-wrap"
                > */}
              {/* <IconButton
                    aria-label="back"
                    className="sub-head-back-btn"
                    onClick={BacktnOnclick}
                    title={"Back to home page"}
                  >
                    <ArrowLeft2 size="24" variant="Outline" />
                  </IconButton> */}

              {/* <Grid
                    item
                    xs={8}
                    className="search-bar--small"
                    ref={searchBar}
                  >
                    
                  </Grid> */}
              {/* </Grid> */}

              {/* <Grid
                  item
                  lg={2}
                  md={2}
                  sm={12}
                  xs={12}
                  className="sub-header-btn-wrap"
                >
                  
                </Grid>
              </Grid> */}

              {/* <Grid item xs={12} className="search-bar--small" ref={searchBar}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    value={
                      standardsState.StandardSearchParameter
                        ?.BasicSearchValue ?? ""
                    }
                    onChange={onsearchNameChange}
                    onKeyPress={onsearchNameKeyPress}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Standards"
                    inputProps={{ "aria-label": "Standard" }}
                    type="search"
                  />
                  <IconButton
                    onClick={() => {
                      serachBtnClick(false);
                    }}
                    type="button"
                    aria-label="search"
                    className="m-0"
                    sx={{ p: "10px" }}
                  > */}
              {/* <SearchIcon /> */}
              {/* <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                    />
                  </IconButton>
                </Paper>
              </Grid> */}
            </Grid>
            {/* Serarch End*/}
            <div className="content-inner-wrap pl-10 pr-10">
              <div ref={statusTag} className="pt-10">
                <HPRStatus
                  onClickStatus={searchByStatusOnClick}
                  SelectedStatus={
                    standardsState.StandardSearchParameter.StandardSearchParams
                      .Status as EnumStatus
                  }
                  StatusSummaryItemList={
                    [
                      {
                        Value: EnumStatus.Published,
                        Label: "Published",
                        StatusSummaryCount:
                          standardsState.Data?.StatusSummaryCount?.Published,
                      },
                      // {
                      //   Value: EnumStatus.Revision,
                      //   Label: "Revision",
                      //   StatusSummaryCount:
                      //     standardsState.Data?.StatusSummaryCount?.Revision,
                      // },
                      {
                        Value: EnumStatus.Draft,
                        Label: "Draft",
                        StatusSummaryCount:
                          standardsState.Data?.StatusSummaryCount?.Draft,
                      },
                      {
                        Value: EnumStatus.Deactivated,
                        Label: "Deactivated",
                        StatusSummaryCount:
                          standardsState.Data?.StatusSummaryCount?.Deactivated,
                      },
                      // {
                      //   Value: EnumStatus.Archive,
                      //   Label: "Archive",
                      //   StatusSummaryCount:
                      //     standardsState.Data?.StatusSummaryCount?.Archive,
                      // },
                      {
                        Value: EnumStatus.All,
                        Label: "All",
                        StatusSummaryCount:
                          standardsState.Data?.StatusSummaryCount?.All,
                      },
                    ] as Array<StatusSummaryDTO>
                  }
                  SortByItemList={
                    [
                      {
                        Label: "Name",
                        Value: EnumStandardSortType.Name.toString(),
                        SortType: EnumSortTypes.Ascending,
                      },
                      {
                        Label: "Total questions",
                        Value: EnumStandardSortType.TotalQuestions.toString(),
                        SortType: EnumSortTypes.Ascending,
                      },
                      {
                        Label: "Last modified user",
                        Value: EnumStandardSortType.LastModifiedUser.toString(),
                        SortType: EnumSortTypes.Ascending,
                      },
                      {
                        Label: "Last modified date & time",
                        Value:
                          EnumStandardSortType.LastModifiedDateTime.toString(),
                        SortType: EnumSortTypes.Ascending,
                      },
                      // {
                      //   Label: "Status",
                      //   Value: EnumStandardSortType.Status.toString(),
                      //   SortType: EnumSortTypes.Ascending,
                      // },
                    ] as SortDropDownDTO[]
                  }
                  onChangeSortBy={onChangeSortBy}
                  InitialSortByState={
                    {
                      Label: "Name",
                      Value: EnumStandardSortType.Name.toString(),
                      SortType: EnumSortTypes.Ascending,
                    } as SortDropDownDTO
                  }
                  PerPageItemList={
                    [
                      {
                        Label: "5",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_1.toString(),
                      },
                      {
                        Label: "10",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_2.toString(),
                      },
                      {
                        Label: "15",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_3.toString(),
                      },
                      {
                        Label: "20",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_4.toString(),
                      },
                      {
                        Label: "25",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_5.toString(),
                      },
                    ] as DropDownDTO[]
                  }
                  onChangePerPage={onChangePagination}
                />
              </div>
              {/* Items Found  */}
              <Grid item xs={12} className="mb-15">
                <div className="records-found">
                  {setNoRecordsFoundMsg(
                    standardsState.ListPagination.TotalRecordsCount
                  )}
                </div>
              </Grid>
              {/* Items Found  End */}
              {/* Standradr Card */}
              <div
                className="standard-page-items-wrap"
                id="standardPageGrid"
                // style={{ height: listHeight }}
              >
                <HPRListPagination
                  ItemList={standardsState.ListPagination.ItemList}
                  ItemShowCount={standardsState.ListPagination.ItemShowCount}
                  TotalRecordsCount={
                    standardsState.ListPagination.TotalRecordsCount
                  }
                  PaginationOnClickFunction={onPageChange}
                  ItemOnClickFunction={ItemOnClickFunction}
                  PageType={EnumPageType.Standards}
                  SelectedPage={standardsState.ListPagination.SelectedPage}
                  ListHeight={listHeight}
                />
                {/* <div className="HPRDataGrid-wrapper">
                {columns && columns?.length > 0 && (
                  <HPRDataGrid
                    Columns={columns}
                    Data={standardsState.ListPagination.ItemList}
                    TotalRecordsCount={
                      standardsState.ListPagination.TotalRecordsCount
                    }
                    AutoHeight={false}
                    onPaginationChangeCallBack={onPageChange}
                    SelectedPage={standardsState.ListPagination.SelectedPage}
                    HPRDataGridToolbarProps={
                      {
                        ShowTotalRecordsCount: true,
                      } as HPRDataGridToolbarProps
                    }
                    ShowMenu
                    PageSize={
                      standardsState.ListPagination.ItemShowCount ??
                      EnumGridDataShowRowCount.GridDataShowRowCount
                    }
                    MenuFunctions={{
                      onChangeSortBy: onSortByCallBack,
                      // onChangeColumnFilter: onChangeColumnFilterCallBack,
                      // onChangeDateRangeColumnFilter:
                      //   onChangeDateRangeColumnFilterCallBack,
                      // onChangeDropDownColumnFilter:
                      //   onChangeDropDownColumnFilterCallBack,
                    }}
                  ></HPRDataGrid>
                )}
              </div> */}
              </div>
              {/* Standradr Card End */}
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Main Backgroud End */}
    </>
  );
};

export default StandardsPage;
