import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from "axios";
import { AxiosInstance, AxiosResponse } from "axios";
// import { RootState } from "../store/index";
// import { authReducer } from "../reducers/authReducer";

import { ActionResponseType } from "../../common/types/common.types";
import { EnumLogoutReason } from "../../views/pages/LogoutPage/LogoutPage.types";
import { AuthType } from "../reducers/auth.types";
import { GetAppOptions } from "../../common/functions/common.functions";
// ==============================|| CORE - API - API CLIENT SERVICE ||============================== //

const ROOT_URL = GetAppOptions().APIURL;
const TOKEN_ENDPOINT = `${ROOT_URL}Auth/ReAcquireTokens`;

axios.defaults.baseURL = ROOT_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = false;
// axios.defaults.crossDomain = true;

const getClient = (baseURL: string = "") => {
  // add axios options
  const options = {
    baseURL: baseURL,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };

  let client = axios.create(options);

  let getAccessToken = () => {
    try {
      const store = JSON.parse(
        localStorage.getItem("hpr_token_store") || "null"
      ) as AuthType | null;
      return store.AccessToken as string | null;
    } catch {
      return null;
    }
  };

  let reAcquireToken = async (): Promise<string | any> => {
    const accessToken = getAccessToken();

    return client<string | null>({
      url: TOKEN_ENDPOINT,
      method: "POST",
      data: { AccessToken: accessToken },
    })
      .then((result: AxiosResponse) => {
        const data = result.data as ActionResponseType;

        if (
          data?.Data?.AccessToken &&
          data?.Data.GeneralToken &&
          data?.Data.Stamp
        ) {
          setTokens({
            AccessToken: data?.Data?.AccessToken,
            GeneralToken: data?.Data?.GeneralToken,
            Stamp: data?.Data?.Stamp,
          });
          return data?.Data?.AccessToken as string;
        } else {
          var query = new URLSearchParams();
          query.append(
            EnumLogoutReason.General?.toString(),
            data?.ErrorMessage
          );
          window.location.href = "/logout?" + query?.toString();
        }
        return null;
      })
      .catch((e) => {
        return null;
      });
  };

  let setTokens = (tokens: AuthType) => {
    localStorage.setItem("hpr_token_store", JSON.stringify(tokens));
  };

  // configure request interceptor
  client.interceptors.request.use(
    (requestConfig: any) => {
      let accessToken = getAccessToken();
      if (!requestConfig.headers["Authorization"] && accessToken) {
        requestConfig.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return requestConfig;
    },
    (requestError) => {
      return Promise.reject(requestError);
    }
  );

  // // configure response interceptor
  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error?.config;
      if (error?.response?.status === 401 && !originalRequest?.sent) {
        originalRequest.sent = true;

        const headers: AxiosHeaders = error?.response?.headers;
        if (headers.get("x-access-token-expired") === "true") {
          // console.log(
          //   "Token expired, Retrying " +
          //     originalRequest.baseURL +
          //     originalRequest.url
          // );
          let token = await reAcquireToken();

          if (token) {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
          }
          return client(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  return client;
};

class ApiClient {
  client: AxiosInstance;
  constructor() {
    this.client = getClient(ROOT_URL);
  }
  getGenaric<T = any, R = AxiosResponse<T>>(
    url: string,
    conf?: AxiosRequestConfig
  ): Promise<T> {
    return this.client
      .get<T>(url, conf)
      .then((response: AxiosResponse<T>) => Promise.resolve(response.data))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }
  // Get
  // URL:string
  //
  get<T = never, R = AxiosResponse<T>>(
    url: string,
    conf = {} as AxiosRequestConfig
  ): Promise<T> {
    return this.client
      .get<T>(url, conf)
      .then((response: AxiosResponse<T>) => Promise.resolve(response.data))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }

  delete<T = never, R = AxiosResponse<T>>(
    url: string,
    conf = {} as AxiosRequestConfig
  ): Promise<T> {
    return this.client
      .delete<T>(url, conf)
      .then((response: AxiosResponse<T>) => Promise.resolve(response.data))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }

  head<T = never, R = AxiosResponse<T>>(
    url: string,
    conf = {} as AxiosRequestConfig
  ): Promise<T> {
    return this.client
      .head<T>(url, conf)
      .then((response: AxiosResponse<T>) => Promise.resolve(response.data))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }

  options<T = never, R = AxiosResponse<T>>(
    url: string,
    conf = {} as AxiosRequestConfig
  ): Promise<T> {
    return this.client
      .options<T>(url, conf)
      .then((response: AxiosResponse<T>) => Promise.resolve(response.data))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }

  post<T = never, R = AxiosResponse<T>>(
    url: string,
    data: any,
    conf = {} as AxiosRequestConfig
  ): Promise<T> {
    return this.client
      .post<T>(url, data, conf)
      .then((response: AxiosResponse<T>) => Promise.resolve(response.data))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }

  put<T = never, R = AxiosResponse<T>>(
    url: string,
    data = {} as any,
    conf = {} as AxiosRequestConfig
  ): Promise<T> {
    return this.client
      .put<T>(url, data, conf)
      .then((response: AxiosResponse<T>) => Promise.resolve(response.data))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }

  patch<T = never, R = AxiosResponse<T>>(
    url: string,
    data = {} as any,
    conf = {} as AxiosRequestConfig
  ): Promise<T> {
    return this.client
      .patch<T>(url, data, conf)
      .then((response: AxiosResponse<T>) => Promise.resolve(response.data))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }

  downloadFile(url: string, conf = {} as AxiosRequestConfig): Promise<any> {
    return this.client
      .get(url, conf)
      .then((response: AxiosResponse<any>) => Promise.resolve(response))
      .catch((error: AxiosError<string>) => Promise.reject(error));
  }
}
export { ApiClient };

// export default {
//   // Provide request methods with the default base_url
//   get(url: string, conf = {}) {
//     return getClient()
//       .get(url, conf)
//       .then((response) => Promise.resolve(response))
//       .catch((error) => Promise.reject(error));
//   },

//   delete(url: string, conf = {}) {
//     return getClient()
//       .delete(url, conf)
//       .then((response) => Promise.resolve(response))
//       .catch((error) => Promise.reject(error));
//   },

//   head(url: string, conf = {}) {
//     return getClient()
//       .head(url, conf)
//       .then((response) => Promise.resolve(response))
//       .catch((error) => Promise.reject(error));
//   },

//   options(url: string, conf = {}) {
//     return getClient()
//       .options(url, conf)
//       .then((response) => Promise.resolve(response))
//       .catch((error) => Promise.reject(error));
//   },

//   post(url: string, data = {}, conf = {}) {
//     return getClient()
//       .post(url, data, conf)
//       .then((response) => response)
//       .catch((error) => error);
//   },

//   put(url: string, data = {}, conf = {}) {
//     return getClient()
//       .put(url, data, conf)
//       .then((response) => Promise.resolve(response))
//       .catch((error) => Promise.reject(error));
//   },

//   patch(url: string, data = {}, conf = {}) {
//     return getClient()
//       .patch(url, data, conf)
//       .then((response) => Promise.resolve(response))
//       .catch((error) => Promise.reject(error));
//   },
// };
