import { createSlice } from "@reduxjs/toolkit";
import SimpleReactValidator from "simple-react-validator";
import {
  EnumButtonModeType,
  EnumGridDataShowRowCount,
  EnumPageMode,
  EnumSortTypes,
  EnumStandardSourceSortType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StandardSystemDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { SystemPageType } from "./SystemPage.types";

// ==============================|| VIEWS - PAGES - STANDARDSYSTEM PAGE - REDUCER ||============================== //

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  Data: {
    Data: [] as Array<StandardSystemDTO>,
    PageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
    SelectedPage: 1,
    TotalRecordsCount: 0,
    StatusSummaryCount: {} as StatusSummaryCountDTO,
  } as PaginationDTO,
  StandaradSystem: {
    Id: 0,
    Name: "",
    StandardId: 0,
    Version: 1,
    Status: EnumStatus.Published,
  } as StandardSystemDTO,
  StandardSystemSearchParameter: {
    StandardSystemSearchParams: {
      Name: "",
      StandardId: 0,
    } as StandardSystemDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
    SortBy: {
      Label: "Name",
      Value: EnumStandardSourceSortType.Name.toString(),
      SortType: EnumSortTypes.Ascending,
    } as SortDropDownDTO,
  } as SearchParameterDTO,
  SystemModalOpen: false,
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  ButtonModeType: EnumButtonModeType.Save,
} as SystemPageType;

export const standardSystemSlice = createSlice({
  name: "standardSystemList",
  initialState: initialState,
  reducers: {
    setStandardSystemInitState: (
      state: SystemPageType,
      action: { payload: SystemPageType; type: string }
    ) => {
      return {
        ...state,
        StandaradSystem: {
          ...state.StandaradSystem,
          StandardId: action.payload.StandaradSystem.StandardId,
        },
        StandardSystemSearchParameter: {
          ...state.StandardSystemSearchParameter,
          StandardSystemSearchParams: {
            ...state.StandardSystemSearchParameter.StandardSystemSearchParams,
            StandardId: action.payload.StandaradSystem.StandardId,
            Name: action.payload.StandaradSystem.Name,
          },
        },
        Data: action.payload.Data,
      };
    },
    setStandardSystemList: (
      state: SystemPageType,
      action: { payload: SystemPageType; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        Data: {
          Data: action.payload.Data?.Data,
          TotalRecordsCount: action.payload.Data?.TotalRecordsCount,
          SelectedPage: action.payload.Data?.SelectedPage,
        } as PaginationDTO,
      };
    },
    setStandardSystemSearchParameter: (
      state: SystemPageType,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        StandardSystemSearchParameter: {
          ...state.StandardSystemSearchParameter,
          StandardSystemSearchParams: {
            ...state.StandardSystemSearchParameter.StandardSystemSearchParams,
            StandardId: action.payload.StandardSystemSearchParams.StandardId,
          },
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          SortBy: action.payload.SortBy,
          GridPageSize: action.payload.GridPageSize,
        } as SearchParameterDTO,
      };
    },
    setStandardSystem: (
      state: SystemPageType,
      action: { payload: StandardSystemDTO; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        StandaradSystem: {
          ...state.StandaradSystem,
          Id: action.payload.Id ? action.payload.Id : 0,
          Name: action.payload.Name ? action.payload.Name : "",
        },
      };
    },
    setDeleteStandardSystem: (
      state: SystemPageType,
      action: { payload: StandardSystemDTO; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        deletestandardSystem: action.payload,
      };
    },
    setPageMode: (
      state: SystemPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.Default === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.Default,
            IsDisable: true,
            IsShowAddBtn: true,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowAddBtn: false,
            IsShowEditBtn: true,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: true,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: true,
            IsShowSaveBtn: true,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: true,
            IsShowCloseBtn: false,
          },
        };
      }
    },
    setSystemModalOpen: (
      state: SystemPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        SystemModalOpen: action.payload,
      };
    },
  },
});

export const {
  setStandardSystemInitState,
  setStandardSystemList,
  setStandardSystem,
  setDeleteStandardSystem,
  setPageMode,
  setStandardSystemSearchParameter,
  setSystemModalOpen,
} = standardSystemSlice.actions;
export const standardSystemReducer = standardSystemSlice.reducer;
