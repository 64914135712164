// ==============================|| COMPONENTS - COMMON - HPRCHECKBOX ||============================== //

import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { HPRCheckBoxProps } from "./HPRCheckBox.types";
import "../../../scss/styles.scss";

const HPRCheckBox = (props: HPRCheckBoxProps) => {
  const [value, setValue] = useState(props.checked ? props.checked : false);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
    if (props.onCheckBoxChange)
      props.onCheckBoxChange(event.target.name, event.target.checked);
  };

  const handleClick = (event: any) => {
    if (props.onCheckBoxClick) {
      props.onCheckBoxClick(event);
    }
  };

  return (
    <FormControlLabel
      control={
        <Tooltip title={props.TooltipsTitle ?? ""}>
          <Checkbox
            id={props.Id}
            name={props.Name}
            checked={props.checked}
            indeterminate={props.indeterminateChecked}
            //indeterminateIcon={<Filter variant="Outline" />}
            required={props.Required}
            disabled={props.Disabled ? props.Disabled : false}
            onChange={onChange}
            className={`${
              props.Disabled ? `disabled ${props.ClassName}` : props.ClassName
            }}`} //check prop disable
            sx={{
              color: "#219EBC",
              borderWidth: 1,
              "&.Mui-checked": {
                color: "#219EBC",
              },
            }}
            onClick={handleClick}
          ></Checkbox>
        </Tooltip>
      }
      label={props.Label}
    />
  );
};

export default HPRCheckBox;
