/** @format */

import { Button, Grid } from "@mui/material";
import HPRDropDownSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";

import {
  CardSend,
  CardSlash,
  ClipboardText,
  CloseCircle,
  Edit,
  Bookmark,
  SmsNotification,
  UserOctagon,
  Profile2User,
} from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import {
  DropDownDTO,
  EmailEventCategoryDTO,
  EmailRecipientDTO,
  EmployeeDTO,
  LocationStandardDTO,
  LocationStandardSmeDTO,
  SiteDTO,
} from "../../../common/types/common.dto.types";
import { EnumPageMode, EnumStatus } from "../../../common/enums/common.enums";
import {
  LocationPageParametersType,
  LocationPageType,
} from "./LocationPage.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  changePageMode,
  resetPage,
  setReadOnlyMode,
} from "./LocationPage.reducer";
import { getDropdowns, saveSite } from "./LocationPage.action";
import SimpleReactValidator from "simple-react-validator";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { LocationsPageParametersType } from "../LocationsPage/LocationsPage.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import HPRDropDownMsGraphSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownMsGraphSingleSelecty";
import HPRDropDownMsGraphMultipleSelect from "../../../components/common/HPRDropDownMultipleSelect/HPRDropDownMsGraphMultipleSelect";
import noData from "../../../images/no_data.svg";

import {
  Box,
  FormControl,
  FormGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const LocationPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // initial page state object
  const initialState = {
    Site: {
      Id: 0,
      SiteName: "",
      SiteCode: "",
      SelectedRegionId: 0,
      SelectedCountryId: 0,
      SelectedDirectorId: "0",
      SelectedQADirectorId: "0",
      SelectedEHSDirectorId: "0",
      SelectedEngineeringDirectorId: "0",
      SelectedQCManagementId: "0",
      SelectedMainteneceManagerId: "0",
      Version: 0.1,
      LocationStandards: [] as Array<LocationStandardDTO>,
      EmailEventCategories: [] as Array<EmailEventCategoryDTO>,
      SiteEmployeeDropDownList: [] as Array<DropDownDTO>,
      SelectedEmployeeRecipientList: [] as Array<string>,
      OriginalEmailEventCategories: [] as Array<EmailEventCategoryDTO>,
      CheckAll: false,
      CheckRemediation: false,
      TabIsDisable: false,
      IsFieldRequired: false,
    } as SiteDTO,
    IsLoading: false,
    PageMode: {
      IsDisable: false,
      IsShowSaveBtn: false,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
      PageMode: EnumPageMode.Default,
    } as PageModeType,

    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
        size: "Required :size characters",
      },
    }),
    ShowErrorMsg: false,
    EventSearchValue: "",
    UserSearchValue: "",
  } as LocationPageType;

  // create state for LocationageType with initialState values
  const [locationPageState, setLocationPageState] = useState(initialState);

  // use for page navigation
  const navigate = useNavigate();

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: LocationPageParametersType = location.state;

  // reducer manage fucntions
  const locationState = useAppSelector((state) => state.locationReducer);
  const dispatch = useAppDispatch();

  // check when one time call to get location data from backend
  const dataFetchedRef = useRef(false);

  const [isDirty, setIsDirty] = useState(false);
  const [value, setValue] = React.useState(0);

  //-----standard list heigh ------
  const mainHeading = useRef(null);
  const wrapperCard = useRef(null);
  const tab4Topic = useRef(null);
  const tab4Search = useRef(null);
  //Set Hight

  const [listHeight1, setListHeight1] = useState(0);
  const [listHeight2, setListHeight2] = useState(0);
  const [listHeight3, setListHeight3] = useState(0);
  const [lastListHeight, setLastListHeight] = useState(0);

  useEffect(() => {
    let listHeight1 =
      wrapperCard?.current?.offsetHeight -
      mainHeading?.current?.offsetHeight -
      44;
    setListHeight1(listHeight1);
    let listHeight2 =
      wrapperCard?.current?.offsetHeight -
      mainHeading?.current?.offsetHeight -
      44;
    setListHeight2(listHeight2);
    let listHeight3 =
      wrapperCard?.current?.offsetHeight -
      mainHeading?.current?.offsetHeight -
      44;
    setListHeight3(listHeight3);
    let lastListHeight =
      wrapperCard?.current?.offsetHeight -
      mainHeading?.current?.offsetHeight -
      // (tab4Topic?.current?.offsetHeight ?? 0) -
      // (tab4Search?.current?.offsetHeight ?? 0) -
      44;
    setLastListHeight(lastListHeight);

    function handleResize() {
      let listHeight1 =
        wrapperCard?.current?.offsetHeight -
        mainHeading?.current?.offsetHeight -
        44;
      setListHeight1(listHeight1);
      let listHeight2 =
        wrapperCard?.current?.offsetHeight -
        mainHeading?.current?.offsetHeight -
        44;
      setListHeight2(listHeight2);
      let listHeight3 =
        wrapperCard?.current?.offsetHeight -
        mainHeading?.current?.offsetHeight -
        44;
      setListHeight3(listHeight3);
      let lastListHeight =
        wrapperCard?.current?.offsetHeight -
        mainHeading?.current?.offsetHeight -
        // (tab4Topic?.current?.offsetHeight ?? 0) -
        // (tab4Search?.current?.offsetHeight ?? 0) -
        44;
      setLastListHeight(lastListHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    tab4Topic?.current?.offsetHeight,
    tab4Search?.current?.offsetHeight,
    mainHeading?.current?.offsetHeight,
    wrapperCard?.current?.offsetHeight,
  ]);

  //On back button click
  const BackOnClickFunction = () => {
    if (locationState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        locationPageState.Site.SiteName === "" &&
        locationPageState.Site.SiteCode === "" &&
        locationPageState.Site.SelectedRegionId === 0 &&
        locationPageState.Site.SelectedCountryId === 0 &&
        locationPageState.Site.SelectedDirectorId === "0" &&
        locationPageState.Site.SelectedQADirectorId === "0" &&
        locationPageState.Site.SelectedEHSDirectorId === "0" &&
        locationPageState.Site.SelectedEngineeringDirectorId === "0" &&
        locationPageState.Site.SelectedQCManagementId === "0" &&
        locationPageState.Site.SelectedMainteneceManagerId === "0" &&
        JSON.stringify(locationPageState.Site?.LocationStandards) ===
          JSON.stringify(locationState.Site?.LocationStandards) &&
        JSON.stringify(locationPageState.Site?.EmailEventCategories) ===
          JSON.stringify(locationState.Site?.EmailEventCategories)
      ) {
        dispatch(resetPage());
        navigate("/locations", {
          state: {
            StatusId: parameter?.StatusId,
          } as LocationsPageParametersType,
        });
      } else {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: false,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(resetPage()); // reset page state
              navigate("/locations", {
                state: {
                  StatusId: parameter?.StatusId,
                } as LocationsPageParametersType,
              });
              setIsDirty(false);
            },
            NoBtnClick() {
              setIsDirty(true);
            },
          } as PopupProps)
        );
      }
    } else {
      // *****Conditions checked***** //
      if (
        locationPageState.Site.SiteName !== locationState.Site.SiteName ||
        locationPageState.Site.SiteCode !== locationState.Site.SiteCode ||
        locationPageState.Site.SelectedRegionId !==
          locationState.Site.SelectedRegionId ||
        locationPageState.Site.SelectedCountryId !==
          locationState.Site.SelectedCountryId ||
        locationPageState.Site.SelectedDirectorId !==
          locationState.Site.SelectedDirectorId ||
        locationPageState.Site.SelectedQADirectorId !==
          locationState.Site.SelectedQADirectorId ||
        locationPageState.Site.SelectedEHSDirectorId !==
          locationState.Site.SelectedEHSDirectorId ||
        locationPageState.Site.SelectedEngineeringDirectorId !==
          locationState.Site.SelectedEngineeringDirectorId ||
        locationPageState.Site.SelectedQCManagementId !==
          locationState.Site.SelectedQCManagementId ||
        locationPageState.Site.SelectedMainteneceManagerId !==
          locationState.Site.SelectedMainteneceManagerId ||
        JSON.stringify(locationPageState.Site?.LocationStandards) !==
          JSON.stringify(locationState.Site?.LocationStandards) ||
        JSON.stringify(locationPageState.Site?.EmailEventCategories) !==
          JSON.stringify(locationState.Site?.EmailEventCategories)
      ) {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(resetPage()); // reset page state
              navigate("/locations", {
                state: {
                  StatusId: parameter?.StatusId,
                } as LocationsPageParametersType,
              });
              setIsDirty(false);
            },
            NoBtnClick() {
              setIsDirty(true);
            },
          } as PopupProps)
        );
      } else {
        dispatch(resetPage()); // reset page state
        navigate("/locations", {
          state: {
            StatusId: parameter?.StatusId,
          } as LocationsPageParametersType,
        });
      }
    }
  };

  //on site name  change
  const onSiteNameChange = (name: string, value: any) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SiteName: value.trimStart(),
        },
      };
    });
  };

  //on site code  change
  const onSiteCodeChange = (name: string, value: any) => {
    // Check special characters and spaces
    if (value && !/^[a-zA-Z0-9]+$/.test(value)) {
      // If it contains spaces or special characters, don't update the state
      return;
    }

    //update to uppercase if lowercase entered
    const newValue = value
      .toUpperCase()
      .replace(/[^A-Z]/g, "")
      .slice(0, 3);

    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SiteCode: newValue,
        },
      };
    });
  };

  // load page with parameter and with out
  useEffect(() => {
    if (dataFetchedRef.current) return;
    if (parameter?.LocationId < 0 || parameter?.LocationId === undefined) {
      dataFetchedRef.current = true;
      dispatch(resetPage()); // reset page state
      dispatch(getDropdowns(0));
    } else {
      dataFetchedRef.current = true;
      dispatch(getDropdowns(parameter?.LocationId));
      dispatch(changePageMode(EnumPageMode.ViewMode));
    }
  }, []);

  //when reducer state change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          Id: locationState.Site.Id,
          SiteName: locationState.Site.SiteName,
          SiteCode: locationState.Site.SiteCode,
          Version: locationState.Site.Version,
          StatusName: locationState.Site.StatusName,
          SelectedRegionId: locationState.Site.SelectedRegionId,
          SelectedCountryId: locationState.Site.SelectedCountryId,
          SelectedDirectorId: locationState.Site.SelectedDirectorId,
          SelectedQADirectorId: locationState.Site.SelectedQADirectorId,
          SelectedEHSDirectorId: locationState.Site.SelectedEHSDirectorId,
          SelectedEngineeringDirectorId:
            locationState.Site.SelectedEngineeringDirectorId,
          SelectedQCManagementId: locationState.Site.SelectedQCManagementId,
          SelectedMainteneceManagerId:
            locationState.Site.SelectedMainteneceManagerId,
          LocationStandards: locationState.Site?.LocationStandards,
          EmailEventCategories: locationState.Site?.EmailEventCategories,
          SiteEmployeeDropDownList: locationState.Site?.EmployeeList,
          OriginalEmailEventCategories:
            locationState.Site?.EmailEventCategories,
          CheckAll:
            locationState.Site?.EmailEventCategories?.filter(
              (x) => x.CheckIndividual === true
            ).length === locationState.Site?.EmailEventCategories?.length
              ? true
              : false,
          CheckRemediation:
            locationState.Site?.EmailEventCategories?.filter(
              (x) => x.CheckIndividual === true
            ).length === locationState.Site?.EmailEventCategories?.length
              ? true
              : false,
          TabIsDisable:
            locationState.PageMode.PageMode === EnumPageMode.AddMode
              ? true
              : false,
          IsFieldRequired: false,
        },
      };
    });
  }, [locationState]);

  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          EmployeeList: locationState.Site?.EmployeeList,
        },
      };
    });
  }, [locationState.Site?.EmployeeList]);

  useEffect(() => {
    if (locationPageState.Site?.IsFieldRequired) {
      IsPageValid();
    }
  }, [locationPageState.Site?.IsFieldRequired]);

  useEffect(() => {
    if (locationState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        locationPageState.Site.SiteName === "" &&
        locationPageState.Site.SiteCode === "" &&
        locationPageState.Site.SelectedRegionId === 0 &&
        locationPageState.Site.SelectedCountryId === 0 &&
        (!locationPageState.Site.SelectedDirectorId ||
          locationPageState.Site.SelectedDirectorId === "0") &&
        (!locationPageState.Site.SelectedQADirectorId ||
          locationPageState.Site.SelectedQADirectorId === "0") &&
        (!locationPageState.Site.SelectedEHSDirectorId ||
          locationPageState.Site.SelectedEHSDirectorId === "0") &&
        (!locationPageState.Site.SelectedEngineeringDirectorId ||
          locationPageState.Site.SelectedEngineeringDirectorId === "0") &&
        (!locationPageState.Site.SelectedQCManagementId ||
          locationPageState.Site.SelectedQCManagementId === "0") &&
        (!locationPageState.Site.SelectedMainteneceManagerId ||
          locationPageState.Site.SelectedMainteneceManagerId === "0") &&
        JSON.stringify(locationPageState.Site?.LocationStandards) ===
          JSON.stringify(locationState.Site?.LocationStandards) &&
        JSON.stringify(locationPageState.Site?.EmailEventCategories) ===
          JSON.stringify(locationState.Site?.EmailEventCategories)
      ) {
        setIsDirty(false);
      } else {
        setIsDirty(true);
      }
    } else {
      if (
        locationPageState.Site.SiteName !== locationState.Site.SiteName ||
        locationPageState.Site.SiteCode !== locationState.Site.SiteCode ||
        locationPageState.Site.SelectedRegionId !==
          locationState.Site.SelectedRegionId ||
        locationPageState.Site.SelectedCountryId !==
          locationState.Site.SelectedCountryId ||
        locationPageState.Site.SelectedDirectorId !==
          locationState.Site.SelectedDirectorId ||
        locationPageState.Site.SelectedQADirectorId !==
          locationState.Site.SelectedQADirectorId ||
        locationPageState.Site.SelectedEHSDirectorId !==
          locationState.Site.SelectedEHSDirectorId ||
        locationPageState.Site.SelectedEngineeringDirectorId !==
          locationState.Site.SelectedEngineeringDirectorId ||
        locationPageState.Site.SelectedQCManagementId !==
          locationState.Site.SelectedQCManagementId ||
        locationPageState.Site.SelectedMainteneceManagerId !==
          locationState.Site.SelectedMainteneceManagerId ||
        JSON.stringify(locationPageState.Site?.LocationStandards) !==
          JSON.stringify(locationState.Site?.LocationStandards) ||
        JSON.stringify(locationPageState.Site?.EmailEventCategories) !==
          JSON.stringify(locationState.Site?.EmailEventCategories)
      ) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  }, [locationPageState]);

  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
    YesBtnClick(event) {
      dispatch(resetPage());
    },
  } as UsePromptTypes;
  usePrompt(prompt);

  //On cancel button click
  const onClickCancelBtn = () => {
    const tempisDirty = isDirty;
    // setLocationPageState((values) => {
    //   return {
    //     ...values,
    //     Site: {
    //       ...values.Site,
    //       IsFieldRequired: false,
    //     },
    //   };
    // });
    if (locationState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        locationPageState.Site.SiteName === "" &&
        locationPageState.Site.SiteCode === "" &&
        locationPageState.Site.SelectedRegionId === 0 &&
        locationPageState.Site.SelectedCountryId === 0 &&
        (!locationPageState.Site.SelectedDirectorId ||
          locationPageState.Site.SelectedDirectorId === "0") &&
        (!locationPageState.Site.SelectedQADirectorId ||
          locationPageState.Site.SelectedQADirectorId === "0") &&
        (!locationPageState.Site.SelectedEHSDirectorId ||
          locationPageState.Site.SelectedEHSDirectorId === "0") &&
        (!locationPageState.Site.SelectedEngineeringDirectorId ||
          locationPageState.Site.SelectedEngineeringDirectorId === "0") &&
        (!locationPageState.Site.SelectedQCManagementId ||
          locationPageState.Site.SelectedQCManagementId === "0") &&
        (!locationPageState.Site.SelectedMainteneceManagerId ||
          locationPageState.Site.SelectedMainteneceManagerId === "0") &&
        JSON.stringify(locationPageState.Site?.LocationStandards) ===
          JSON.stringify(locationState.Site?.LocationStandards) &&
        JSON.stringify(locationPageState.Site?.EmailEventCategories) ===
          JSON.stringify(locationState.Site?.EmailEventCategories)
      ) {
        navigate("/locations", {
          state: {
            StatusId: parameter?.StatusId,
          } as LocationsPageParametersType,
        });
      } else {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(resetPage()); // reset page state
              navigate("/locations", {
                state: {
                  StatusId: parameter?.StatusId,
                } as LocationsPageParametersType,
              });
              setIsDirty(false);
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      }
    } else {
      // *****Conditions checked***** //
      if (
        locationPageState.Site.SiteName !== locationState.Site.SiteName ||
        locationPageState.Site.SiteCode !== locationState.Site.SiteCode ||
        locationPageState.Site.SelectedRegionId !==
          locationState.Site.SelectedRegionId ||
        locationPageState.Site.SelectedCountryId !==
          locationState.Site.SelectedCountryId ||
        locationPageState.Site.SelectedDirectorId !==
          locationState.Site.SelectedDirectorId ||
        locationPageState.Site.SelectedQADirectorId !==
          locationState.Site.SelectedQADirectorId ||
        locationPageState.Site.SelectedEHSDirectorId !==
          locationState.Site.SelectedEHSDirectorId ||
        locationPageState.Site.SelectedEngineeringDirectorId !==
          locationState.Site.SelectedEngineeringDirectorId ||
        locationPageState.Site.SelectedQCManagementId !==
          locationState.Site.SelectedQCManagementId ||
        locationPageState.Site.SelectedMainteneceManagerId !==
          locationState.Site.SelectedMainteneceManagerId ||
        JSON.stringify(locationPageState.Site?.LocationStandards) !==
          JSON.stringify(locationState.Site?.LocationStandards) ||
        JSON.stringify(locationPageState.Site?.EmailEventCategories) !==
          JSON.stringify(locationState.Site?.EmailEventCategories)
      ) {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setLocationPageState((values) => {
                return {
                  ...values,
                  Site: {
                    ...values.Site,
                    Id: locationState.Site.Id,
                    SiteName: locationState.Site.SiteName,
                    SiteCode: locationState.Site.SiteCode,
                    Version: locationState.Site.Version,
                    StatusName: locationState.Site.StatusName,
                    SelectedRegionId: locationState.Site.SelectedRegionId,
                    SelectedCountryId: locationState.Site.SelectedCountryId,
                    SelectedDirectorId: locationState.Site.SelectedDirectorId,
                    SelectedQADirectorId:
                      locationState.Site.SelectedQADirectorId,
                    SelectedEHSDirectorId:
                      locationState.Site.SelectedEHSDirectorId,
                    SelectedEngineeringDirectorId:
                      locationState.Site.SelectedEngineeringDirectorId,
                    SelectedQCManagementId:
                      locationState.Site.SelectedQCManagementId,
                    SelectedMainteneceManagerId:
                      locationState.Site.SelectedMainteneceManagerId,
                  },
                };
              });
              dispatch(changePageMode(EnumPageMode.ViewMode));
              dispatch(setReadOnlyMode(true));
              //setIsDirty(false);
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      } else {
        dispatch(changePageMode(EnumPageMode.ViewMode));
        dispatch(setReadOnlyMode(true));
      }
    }
  };
  //On close button click
  const onClickCloseBtn = () => {
    if (locationState.PageMode.PageMode === EnumPageMode.ViewMode) {
      navigate("/locations", {
        state: {
          StatusId: parameter?.StatusId,
        } as LocationsPageParametersType,
      });
    }
  };

  //On edit button click
  const onClickEditBtn = () => {
    dispatch(changePageMode(EnumPageMode.EditMode));
    dispatch(setReadOnlyMode(false));
  };

  //On deactivate button click
  const onClickDeactivateBtn = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to deactivate?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(saveSite(locationPageState.Site, EnumStatus.Deactivated));
        },
      } as PopupProps)
    );
  };

  //On draft button click
  const onClickDraftBtn = () => {
    //check form valid
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          IsFieldRequired: false,
        },
      };
    });
    if (
      locationPageState?.Site?.SiteName == null ||
      locationPageState?.Site?.SiteName === "" ||
      locationPageState?.Site?.SelectedRegionId === 0 ||
      locationPageState?.Site?.SelectedCountryId === 0 ||
      locationPageState?.Site?.SiteCode == null ||
      locationPageState?.Site?.SiteCode === "" ||
      locationPageState?.Site?.SiteCode?.length !== 3
    ) {
      setValue(0);
      IsPageValid();
    } else {
      dispatch(saveSite(locationPageState.Site, EnumStatus.Draft));
    }
  };

  //On publish button click
  const onClickPublishBtn = () => {
    //check form valid
    if (
      locationPageState?.Site?.SiteName == null ||
      locationPageState?.Site?.SiteName === "" ||
      locationPageState?.Site?.SelectedRegionId === 0 ||
      locationPageState?.Site?.SelectedCountryId === 0 ||
      locationPageState?.Site?.SiteCode == null ||
      locationPageState?.Site?.SiteCode === "" ||
      locationPageState?.Site?.SiteCode?.length !== 3
    ) {
      setValue(0);
      IsPageValid();
    } else if (
      locationPageState?.Site?.SelectedDirectorId == null ||
      locationPageState?.Site?.SelectedDirectorId === "0" ||
      locationPageState?.Site?.SelectedDirectorId === "" ||
      locationPageState?.Site?.SelectedQADirectorId == null ||
      locationPageState?.Site?.SelectedQADirectorId === "0" ||
      locationPageState?.Site?.SelectedQADirectorId === "" ||
      locationPageState?.Site?.SelectedEngineeringDirectorId == null ||
      locationPageState?.Site?.SelectedEngineeringDirectorId === "0" ||
      locationPageState?.Site?.SelectedEngineeringDirectorId === ""
    ) {
      setLocationPageState((values) => {
        return {
          ...values,
          Site: {
            ...values.Site,
            IsFieldRequired: true,
          },
        };
      });

      IsPageValid();
      setValue(1);
    } else {
      if (IsPageValid()) {
        dispatch(saveSite(locationPageState.Site, EnumStatus.Published));
      }
    }
  };

  //Scroll To Top
  const onClickScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const IsPageValid = (): boolean => {
    //check form valid
    let isValid = locationPageState.Validator.allValid();
    if (!isValid) {
      setLocationPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !locationPageState.ShowErrorMsg,
        };
      });

      //show validation message
      locationPageState.Validator.showMessages();
    } else {
      //hide validation message
      locationPageState.Validator.hideMessages();
    }

    return isValid;
  };

  const onDropDownChangeRegion = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedRegionId:
            selectedValue && selectedValue !== "" ? parseInt(selectedValue) : 0,
        },
      };
    });
  };

  const onDropDownChangeCountry = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedCountryId:
            selectedValue && selectedValue !== "" ? parseInt(selectedValue) : 0,
        },
      };
    });
  };

  const onDropDownChangeDirector = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedDirectorId:
            selectedValue && selectedValue !== ""
              ? selectedValue?.toString()
              : "",
          EmployeeList: selectOption,
        },
      };
    });
  };

  const onDropDownChangeQADirector = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedQADirectorId:
            selectedValue && selectedValue !== ""
              ? selectedValue?.toString()
              : "",
          EmployeeList: selectOption,
        },
      };
    });
  };

  const onDropDownChangeEHSDirector = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedEHSDirectorId:
            selectedValue && selectedValue !== ""
              ? selectedValue?.toString()
              : "0",
          EmployeeList: selectOption,
        },
      };
    });
  };

  const onDropDownChangeEngineeringDirector = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedEngineeringDirectorId:
            selectedValue && selectedValue !== ""
              ? selectedValue?.toString()
              : "",
          EmployeeList: selectOption,
        },
      };
    });
  };

  const onDropDownChangeQCManagement = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedQCManagementId:
            selectedValue && selectedValue !== ""
              ? selectedValue?.toString()
              : "0",
          EmployeeList: selectOption,
        },
      };
    });
  };

  const onDropDownChangeMainteneceManager = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedMainteneceManagerId:
            selectedValue && selectedValue !== ""
              ? selectedValue?.toString()
              : "0",
          EmployeeList: selectOption,
        },
      };
    });
  };

  const handleLocationStandardDropDownListChange = (
    selectOptions: Array<DropDownDTO>,
    selectedValues: Array<string>,
    locationStandard: LocationStandardDTO
  ) => {
    const selectedLocationStandard: Array<LocationStandardDTO> =
      locationPageState.Site.LocationStandards?.map((item) => {
        if (item.Id === locationStandard.Id) {
          return {
            ...item,
            LocationStandardSmes: selectedValues.map((sme) => {
              return {
                Sme: { Id: 0, Uuid: sme?.toString() } as EmployeeDTO,
              } as LocationStandardSmeDTO;
            }),
          };
        }

        return item;
      });

    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          LocationStandards: selectedLocationStandard,
          EmployeeList: selectOptions,
        },
      };
    });
  };

  const handleEmailRecipientMainDropDownListChange = (
    selectOptions: Array<DropDownDTO>,
    selectedValues: Array<string>
  ) => {
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          SelectedEmployeeRecipientList: selectedValues,
          SiteEmployeeDropDownList: selectOptions,
        },
      };
    });
  };

  const handleEmailEventCategoryDropDownListChange = (
    selectOptions: Array<DropDownDTO>,
    selectedValues: Array<string>,
    EventCategoryIDVal: number
  ) => {
    const selectedEmailEventCategory: Array<EmailEventCategoryDTO> =
      locationPageState.Site.EmailEventCategories?.map((item) => {
        if (item.Id === EventCategoryIDVal) {
          return {
            ...item,
            CheckIndividual: selectedValues.length > 0 ? true : false,
            EmailRecipients: selectedValues.map((emp) => {
              return {
                Employee: { Id: 0, Uuid: emp?.toString() } as EmployeeDTO,
              } as EmailRecipientDTO;
            }),
          };
        }

        return item;
      });

    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          EmailEventCategories: selectedEmailEventCategory,
          SiteEmployeeDropDownList: selectOptions,
        },
      };
    });
  };

  //On add email receipient button click
  const onClickAddEmailReceipientBtn = () => {
    const selectedEmailEventCategory: Array<EmailEventCategoryDTO> =
      locationPageState.Site.EmailEventCategories?.map((item) => {
        let emailReceipient = [];
        if (item.CheckIndividual) {
          if (item.EmailRecipients.length > 0) {
            for (let p = 0; p < item.EmailRecipients.length; p++) {
              emailReceipient.push(item.EmailRecipients[p]);
            }

            for (
              let q = 0;
              q < locationPageState.Site.SelectedEmployeeRecipientList.length;
              q++
            ) {
              let newReceipient = {
                EmailEventCategoryId: item.Id,
                Employee: {
                  Id: 0,
                  Uuid: locationPageState.Site.SelectedEmployeeRecipientList[
                    q
                  ]?.toString(),
                } as EmployeeDTO,
              };
              const found = emailReceipient.some(
                (el) =>
                  el.Employee.Uuid ===
                  locationPageState.Site.SelectedEmployeeRecipientList[
                    q
                  ]?.toString()
              );

              if (!found) {
                emailReceipient.push(newReceipient);
              }
            }
            return {
              ...item,
              EmailRecipients: emailReceipient,
            };
          } else {
            for (
              let q = 0;
              q < locationPageState.Site.SelectedEmployeeRecipientList.length;
              q++
            ) {
              let newReceipient = {
                EmailEventCategoryId: item.Id,
                Employee: {
                  Id: 0,
                  Uuid: locationPageState.Site.SelectedEmployeeRecipientList[
                    q
                  ]?.toString(),
                } as EmployeeDTO,
              };
              const found = emailReceipient.some(
                (el) =>
                  el.Employee.Uuid ===
                  locationPageState.Site.SelectedEmployeeRecipientList[
                    q
                  ]?.toString()
              );

              if (!found) {
                emailReceipient.push(newReceipient);
              }
            }
            return {
              ...item,
              EmailRecipients: emailReceipient,
            };
          }
        }

        return item;
      });

    let isChecked = locationPageState.Site.EmailEventCategories?.filter(
      (x) => x.CheckIndividual === true
    ).length;
    if (locationPageState?.Site?.CheckAll || isChecked > 0) {
      setLocationPageState((values) => {
        return {
          ...values,
          Site: {
            ...values.Site,
            EmailEventCategories: selectedEmailEventCategory,
            SelectedEmployeeRecipientList: [],
          },
        };
      });
    } else {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Select Notification Event to add Email Recipient(s)",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }
  };

  //on Check All Selected checkbox clicked
  const handleCheckAllSelected = (name: string, check: any) => {
    const selectedEmailEventCategory: Array<EmailEventCategoryDTO> =
      locationPageState.Site.EmailEventCategories?.map((item) => {
        return {
          ...item,
          CheckIndividual: check,
        };

        return item;
      });
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          CheckAll: check,
          CheckRemediation: check,
          EmailEventCategories: selectedEmailEventCategory,
        },
      };
    });
  };

  const handleCheckRemediationSelected = (name: string, check: any) => {
    const selectedEmailEventCategory: Array<EmailEventCategoryDTO> =
      locationPageState.Site.EmailEventCategories?.map((item) => {
        return {
          ...item,
          CheckIndividual: check,
        };

        return item;
      });
    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          CheckAll: check,
          CheckRemediation: check,
          EmailEventCategories: selectedEmailEventCategory,
        },
      };
    });
  };

  const handleIndividualSelected = (name: string, check: any) => {
    let index = name.split("_");
    if (!check && locationPageState.Site.CheckAll) {
      const selectedEmailEventCategory: Array<EmailEventCategoryDTO> =
        locationPageState.Site.EmailEventCategories?.map((item) => {
          if (parseInt(index[2]) === item.Id) {
            return {
              ...item,
              CheckIndividual: check,
            };
          }

          return item;
        });
      setLocationPageState((values) => {
        return {
          ...values,
          Site: {
            ...values.Site,
            CheckAll: check,
            CheckRemediation: check,
            EmailEventCategories: selectedEmailEventCategory,
          },
        };
      });
    } else {
      const selectedEmailEventCategory: Array<EmailEventCategoryDTO> =
        locationPageState.Site.EmailEventCategories?.map((item) => {
          if (parseInt(index[2]) === item.Id) {
            return {
              ...item,
              CheckIndividual: check,
            };
          }
          return item;
        });

      let allCheckedVal = false;
      let isCheckedCount = selectedEmailEventCategory?.filter(
        (x) => x.CheckIndividual === true
      ).length;

      if (
        isCheckedCount === locationPageState.Site.EmailEventCategories.length
      ) {
        allCheckedVal = true;
      }

      setLocationPageState((values) => {
        return {
          ...values,
          Site: {
            ...values.Site,
            CheckAll: allCheckedVal,
            CheckRemediation: allCheckedVal,
            EmailEventCategories: selectedEmailEventCategory,
          },
        };
      });
    }
  };

  const onsearchEventChange = (event: any) => {
    event.stopPropagation();
    let inputVal = event.target.value.toUpperCase();
    setLocationPageState((values) => {
      return {
        ...values,
        EventSearchValue: inputVal,
      };
    });
    if (!event.target.value) {
      setLocationPageState((values) => {
        return {
          ...values,
          Site: {
            ...values.Site,
            EmailEventCategories:
              locationPageState.Site.OriginalEmailEventCategories,
          },
        };
      });
    }
  };
  const onsearchEventNameKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      let inputVal = event.target.value.toUpperCase();

      const selectedEmailEventCategory = [];

      let selectedData =
        locationPageState.Site.OriginalEmailEventCategories?.map((item) => {
          if (
            item.EventCategory.toUpperCase().includes(inputVal.toUpperCase())
          ) {
            return {
              ...item,
            };
          }
        }).filter((val) => val);

      selectedEmailEventCategory.push(selectedData);

      const uniqueNames = selectedEmailEventCategory[0].filter(
        (val, id, array) => {
          return array.indexOf(val) === id;
        }
      );

      setLocationPageState((values) => {
        return {
          ...values,
          Site: {
            ...values.Site,
            EmailEventCategories: uniqueNames,
          },
        };
      });
    }
  };
  const eventSearchBtnClick = () => {
    const selectedEmailEventCategory = [];

    let selectedData = locationPageState.Site.OriginalEmailEventCategories?.map(
      (item) => {
        if (
          item.EventCategory.toUpperCase().includes(
            locationPageState.EventSearchValue.toUpperCase()
          )
        ) {
          return {
            ...item,
          };
        }
      }
    ).filter((val) => val);

    selectedEmailEventCategory.push(selectedData);

    const uniqueNames = selectedEmailEventCategory[0].filter(
      (val, id, array) => {
        return array.indexOf(val) === id;
      }
    );

    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          EmailEventCategories: uniqueNames,
        },
      };
    });
  };

  const onsearchUserChange = (event: any) => {
    event.stopPropagation();

    let inputVal = event.target.value.toUpperCase();
    setLocationPageState((values) => {
      return {
        ...values,
        UserSearchValue: inputVal,
      };
    });
    if (!event.target.value) {
      setLocationPageState((values) => {
        return {
          ...values,
          Site: {
            ...values.Site,
            EmailEventCategories:
              locationPageState.Site.OriginalEmailEventCategories,
          },
        };
      });
    }
  };
  const onsearchUserNameKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      let inputVal = event.target.value.toUpperCase();

      const selectedEmailUserNames = [];
      let selectedData = locationPageState?.Site?.SiteEmployeeDropDownList?.map(
        (item) => {
          if (item.Label.toUpperCase().includes(inputVal.toUpperCase())) {
            return {
              ...item,
            };
          }
        }
      ).filter((val) => val);

      selectedEmailUserNames.push(selectedData);

      const userData = [];
      let data = locationPageState.Site.EmailEventCategories?.map((item) => {
        return {
          ...item,
          EmailRecipients: item.EmailRecipients.map((emp) => {
            selectedEmailUserNames[0].forEach((element) => {
              if (item.CheckIndividual && element.Value === emp.Employee.Uuid) {
                userData.push(item);
              }
            });
          }),
        };
      });

      let fromOriginal =
        locationPageState.Site.OriginalEmailEventCategories?.map((item) => {
          return {
            ...item,
            EmailRecipients: item.EmailRecipients.map((emp) => {
              selectedEmailUserNames[0].forEach((element) => {
                if (
                  item.CheckIndividual &&
                  element.Value === emp.Employee.Uuid
                ) {
                  userData.push(item);
                }
              });
            }),
          };
        });

      const uniqueNames = userData.filter((val, id, array) => {
        return array.indexOf(val) === id;
      });

      setLocationPageState((values) => {
        return {
          ...values,
          Site: {
            ...values.Site,
            EmailEventCategories: uniqueNames,
          },
        };
      });
    }
  };

  const userSearchBtnClick = () => {
    let inputVal = locationPageState.UserSearchValue;

    const selectedEmailUserNames = [];
    let selectedData = locationPageState?.Site?.SiteEmployeeDropDownList?.map(
      (item) => {
        if (item.Label.toUpperCase().includes(inputVal.toUpperCase())) {
          return {
            ...item,
          };
        }
      }
    ).filter((val) => val);

    selectedEmailUserNames.push(selectedData);

    const userData = [];
    let data = locationPageState.Site.EmailEventCategories?.map((item) => {
      return {
        ...item,
        EmailRecipients: item.EmailRecipients.map((emp) => {
          selectedEmailUserNames[0].forEach((element) => {
            if (item.CheckIndividual && element.Value === emp.Employee.Uuid) {
              userData.push(item);
            }
          });
        }),
      };
    });

    let fromOriginal = locationPageState.Site.OriginalEmailEventCategories?.map(
      (item) => {
        return {
          ...item,
          EmailRecipients: item.EmailRecipients.map((emp) => {
            selectedEmailUserNames[0].forEach((element) => {
              if (item.CheckIndividual && element.Value === emp.Employee.Uuid) {
                userData.push(item);
              }
            });
          }),
        };
      }
    );

    const uniqueNames = userData.filter((val, id, array) => {
      return array.indexOf(val) === id;
    });

    setLocationPageState((values) => {
      return {
        ...values,
        Site: {
          ...values.Site,
          EmailEventCategories: uniqueNames,
        },
      };
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const setNoRecordsFoundMsg = (TotalRecordsCount: number) => {
    let msg = "";
    if (TotalRecordsCount === 0) {
      msg = "No records found";
    }
    // else if (TotalRecordsCount === 1) {
    //   msg = "1 record found";
    // } else if (TotalRecordsCount > 1) {
    //   msg = `${TotalRecordsCount} records found`;
    // }

    return msg;
  };

  return (
    <>
      {/* Global Header with Status & Version */}
      {/* <Grid container columns={12} className="global-header-wrap"> */}
      {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
          <h1>Location</h1>
        </Grid> */}

      {/* <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vlign-middle status-version-wrap"
        >
          <div className="global-version-top mb-5">
            <span>Version:</span>
            {locationState?.Site != null &&
            locationState?.Site.Version != undefined
              ? locationState?.Site?.Version.toFixed(1)
              : ""}
          </div>
          <div className="standart-status-change mb-5 d-flex">
            <div className="standards-status">
              <span>Status:</span>
              <span
                className={
                  locationState.Site?.Status === EnumStatus.Published
                    ? "status-color green"
                    : locationState.Site?.Status === EnumStatus.Draft
                    ? "status-color yellow"
                    : locationState.Site?.Status === EnumStatus.Deactivated
                    ? "status-color gray"
                    : "status-color"
                }
              >
                {locationState.Site?.StatusName}
              </span>
            </div>
          </div>
        </Grid> */}
      {/* </Grid> */}

      <div
        className="content-section-card overflow-hidden top-main-padding"
        ref={wrapperCard}
      >
        <Grid container columns={12}>
          {/* <Grid
            container
            columns={12}
            className="global-subhead-btn-wrap"
            ref={mainHeading}
          >  */}
          <div
            className="flex--container width100-p top-main-border-inner"
            ref={mainHeading}
          >
            <div className="flex__item fx">
              <h2 className="pr-10">
                {locationState.PageMode.PageMode === EnumPageMode.ViewMode ? (
                  <Button
                    aria-label="back"
                    className="sub-head-back-btn"
                    onClick={BackOnClickFunction}
                    title="Back to home page"
                  >
                    {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                    <ArrowBackIosNewOutlinedIcon className="" />
                    <span className="body-bold secondary-color">Back</span>
                  </Button>
                ) : (
                  <Button
                    aria-label="back"
                    className="sub-head-back-btn"
                    onClick={onClickCancelBtn}
                    title="Back to home page"
                  >
                    {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                    <ArrowBackIosNewOutlinedIcon className="" />
                    <span className="body-bold secondary-color">Back</span>
                  </Button>
                )}
              </h2>

              <div className="mb-0">
                <h1>Location</h1>
              </div>
              <div className="status-version-wrap">
                <div className="standart-status-change mb-5 d-flex">
                  <div className="standards-status">
                    <span>Status:</span>
                    <span
                      className={
                        locationState.Site?.Status === EnumStatus.Published
                          ? "status-color green"
                          : locationState.Site?.Status === EnumStatus.Draft
                          ? "status-color yellow"
                          : locationState.Site?.Status ===
                            EnumStatus.Deactivated
                          ? "status-color gray"
                          : "status-color"
                      }
                    >
                      {locationState.Site?.StatusName}
                    </span>
                  </div>
                </div>
                <div className="global-version-top mb-5">
                  <span>Version:</span>
                  {locationState?.Site != null &&
                  locationState?.Site.Version !== undefined
                    ? locationState?.Site?.Version.toFixed(1)
                    : ""}
                </div>
              </div>
            </div>
            <div className="flex__item--inherit">
              {locationState.PageMode.IsShowCloseBtn ? (
                <>
                  <Button
                    className="secondary-btn-small"
                    size="small"
                    variant="outlined"
                    disableElevation
                    startIcon={<CloseCircle variant="Outline" />}
                    onClick={onClickCloseBtn}
                  >
                    Close
                  </Button>
                </>
              ) : (
                <></>
              )}
              {locationState.PageMode.IsShowCancelBtn ? (
                <>
                  <Button
                    className="secondary-btn-small"
                    size="small"
                    variant="outlined"
                    disableElevation
                    startIcon={<CloseCircle variant="Outline" />}
                    onClick={onClickCancelBtn}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <></>
              )}

              {locationState.PageMode.IsShowDeactivateBtn &&
              locationState.Site.Status !== EnumStatus.Deactivated &&
              locationState.Site.Id > 0 &&
              locationState.PageMode.PageMode === EnumPageMode.EditMode ? (
                <>
                  <Button
                    className="primary-btn-small"
                    size="small"
                    variant="outlined"
                    disableElevation
                    startIcon={<CardSlash variant="Outline" />}
                    onClick={onClickDeactivateBtn}
                  >
                    Deactivate
                  </Button>
                </>
              ) : (
                <></>
              )}

              {locationState.PageMode.IsShowDraftBtn &&
              locationState.Site.Status !== EnumStatus.Deactivated &&
              locationState.Site.Status !== EnumStatus.Published ? (
                <>
                  <Button
                    className="primary-btn-small"
                    variant="outlined"
                    disableElevation
                    onClick={onClickDraftBtn}
                    startIcon={<ClipboardText variant="Outline" />}
                    size="small"
                  >
                    Draft
                  </Button>
                </>
              ) : (
                <></>
              )}

              {locationState.PageMode.IsShowEditBtn &&
              locationState.Site.Status !== EnumStatus.Deactivated ? (
                <>
                  <Button
                    className="primary-btn-small"
                    size="small"
                    variant="outlined"
                    disableElevation
                    onClick={onClickEditBtn}
                    startIcon={<Edit variant="Outline" />}
                  >
                    Edit
                  </Button>
                </>
              ) : (
                <></>
              )}

              {locationState.PageMode.IsShowPublishedBtn &&
              locationState.Site.Status !== EnumStatus.Deactivated ? (
                <>
                  <Button
                    className="primary-btn-small"
                    size="small"
                    variant="outlined"
                    disableElevation
                    onClick={onClickPublishBtn}
                    startIcon={<CardSend variant="Outline" />}
                  >
                    Publish
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className="global-subhead-wrap"
          >
            {/* <IconButton
                aria-label="back"
                className="sub-head-back-btn"
                onClick={BackOnClickFunction}
                title="Back to locations"
              >
                <ArrowLeft2 size="24
                " variant="Outline" />
              </IconButton> */}

            <div className="vl"></div>

            <h2></h2>
            {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
              <h1>Location</h1>
            </Grid> */}
          </Grid>

          {/* </Grid> */}
          {/* Start Tab View */}
          <Grid container columns={12} className="">
            <Grid item lg={12} md={12} sm={12} xs={12} className="">
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                  // height: 450,
                }}
              >
                <Tabs
                  className="vertical-tab"
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  <Tab
                    label="Main"
                    icon={<Bookmark />}
                    className="tabs-typo"
                    sx={{ color: "#161a2c" }}
                    {...a11yProps(0)}
                  />

                  <Tab
                    label="Executive(s)"
                    icon={<Profile2User />}
                    className="tabs-typo"
                    sx={{ color: "#161a2c" }}
                    disabled={locationPageState.Site.TabIsDisable}
                    {...a11yProps(1)}
                  />

                  <Tab
                    label="Site Primary SME(s)"
                    icon={<UserOctagon />}
                    className="tabs-typo"
                    sx={{ color: "#161a2c" }}
                    disabled={locationPageState.Site.TabIsDisable}
                    {...a11yProps(2)}
                  />

                  <Tab
                    label="Email Recipient(s)"
                    icon={<SmsNotification variant="TwoTone" />}
                    className="tabs-typo"
                    sx={{ color: "#161a2c" }}
                    disabled={locationPageState.Site.TabIsDisable}
                    {...a11yProps(3)}
                  />
                </Tabs>
                <TabPanel
                  value={value}
                  index={0}
                  className="width100-p overflow-auto tab-view-tabpanel-assigninspection"
                  style={{ maxHeight: listHeight1 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2} lg={2} md={2} xl={2}></Grid>
                    <Grid item xs={12} lg={8} md={12} xl={8}>
                      <div
                        className="form-group mb-0"
                        // style={{ marginBottom: "10px" }}
                      >
                        <HPRTextBox
                          Id="location_name"
                          Label="Name"
                          Name="Name"
                          Value={
                            locationPageState.Site.SiteName
                              ? locationPageState.Site.SiteName
                              : ""
                          }
                          Type={EnumTextBoxType.Text}
                          Disabled={locationState.PageMode.IsDisable}
                          onTextBoxChange={onSiteNameChange}
                          Validator={locationPageState.Validator}
                          Rules={"required"}
                          IsEnableValidator={true}
                          Required={true}
                          ShowTextCounter
                          TextLength={75}
                          InputProps={{ maxLength: 75 }}
                          onTextBoxClick={onClickScrollToTop}
                        ></HPRTextBox>
                      </div>
                      <div className="form-group">
                        <HPRDropDownSingleSelect
                          Label={"Region"}
                          Id="location_region"
                          Name="Region"
                          Items={locationState.Site?.RegionList}
                          onDropDownChange={onDropDownChangeRegion}
                          Select={locationPageState.Site.SelectedRegionId}
                          Disabled={locationState.PageMode.IsDisable}
                          Validator={locationPageState.Validator}
                          Rules={"required"}
                          IsEnableValidator={true}
                          Required={true}
                          IsSearchable={true}
                        />
                      </div>
                      <div className="form-group">
                        <HPRDropDownSingleSelect
                          Items={locationState.Site?.CountryList}
                          Label={"Country"}
                          Id="location_country"
                          Name="Country"
                          onDropDownChange={onDropDownChangeCountry}
                          Select={locationPageState.Site.SelectedCountryId}
                          Disabled={locationState.PageMode.IsDisable}
                          Validator={locationPageState.Validator}
                          Rules={"required"}
                          IsEnableValidator={true}
                          Required={true}
                          IsSearchable={true}
                        />
                      </div>
                      <div className="form-group">
                        <HPRTextBox
                          Id="location_code"
                          Label="Code"
                          Name="Code"
                          Value={
                            locationPageState.Site.SiteCode
                              ? locationPageState.Site.SiteCode
                              : ""
                          }
                          Type={EnumTextBoxType.Text}
                          Disabled={locationState.PageMode.IsDisable}
                          onTextBoxChange={onSiteCodeChange}
                          Validator={locationPageState.Validator}
                          Rules={"required|size:3"}
                          IsEnableValidator={true}
                          Required={true}
                          InputProps={{ maxLength: 3 }}
                          TextLength={3}
                          ShowTextCounter={true}
                          onTextBoxClick={onClickScrollToTop}
                        ></HPRTextBox>
                      </div>
                    </Grid>
                    <Grid item xs={2} lg={2} md={2} xl={2}></Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}
                  className="width100-p overflow-auto tab-view-tabpanel-assigninspection"
                  style={{ maxHeight: listHeight2 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2} lg={2} md={2} xl={2}></Grid>
                    <Grid item xs={12} lg={8} md={12} xl={8}>
                      <div className="form-group">
                        <HPRDropDownMsGraphSingleSelect
                          Items={locationPageState.Site?.EmployeeList}
                          Label={"Director"}
                          Id="location_director"
                          Name="Director"
                          Url={
                            locationPageState.Site?.EmployeeList &&
                            locationPageState.Site?.EmployeeList?.length > 0
                              ? "Location/GetEmployeesListForDropDown"
                              : ""
                          }
                          onDropDownChange={onDropDownChangeDirector}
                          SelectedValue={
                            locationPageState.Site.SelectedDirectorId
                          }
                          Disabled={locationState.PageMode.IsDisable}
                          IsSearchable={true}
                          Validator={locationPageState.Validator}
                          Rules={
                            locationPageState.Site.IsFieldRequired
                              ? "required|not_in:0"
                              : ""
                          }
                          IsEnableValidator={
                            locationPageState.Site.IsFieldRequired
                          }
                          Required={locationPageState.Site.IsFieldRequired}
                        />
                      </div>
                      <div className="form-group">
                        <HPRDropDownMsGraphSingleSelect
                          Items={locationPageState.Site?.EmployeeList}
                          Label={"QA Director"}
                          Id="location_qaDirector"
                          Name="QADirector"
                          Url={
                            locationPageState.Site?.EmployeeList &&
                            locationPageState.Site?.EmployeeList?.length > 0
                              ? "Location/GetEmployeesListForDropDown"
                              : ""
                          }
                          onDropDownChange={onDropDownChangeQADirector}
                          SelectedValue={
                            locationPageState.Site.SelectedQADirectorId
                          }
                          Disabled={locationState.PageMode.IsDisable}
                          IsSearchable={true}
                          Validator={
                            locationPageState.Site.IsFieldRequired
                              ? locationPageState.Validator
                              : new SimpleReactValidator({
                                  messages: {
                                    default: "This field is required",
                                  },
                                })
                          }
                          Rules={
                            locationPageState.Site.IsFieldRequired
                              ? "required|not_in:0"
                              : ""
                          }
                          IsEnableValidator={
                            locationPageState.Site.IsFieldRequired
                              ? true
                              : false
                          }
                          Required={
                            locationPageState.Site.IsFieldRequired
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="form-group">
                        <HPRDropDownMsGraphSingleSelect
                          Items={locationPageState.Site?.EmployeeList}
                          Label={"Engineering Director"}
                          Id="location_engineeringDirector"
                          Name="EngineeringDirector"
                          Url={
                            locationPageState.Site?.EmployeeList &&
                            locationPageState.Site?.EmployeeList?.length > 0
                              ? "Location/GetEmployeesListForDropDown"
                              : ""
                          }
                          onDropDownChange={onDropDownChangeEngineeringDirector}
                          SelectedValue={
                            locationPageState.Site.SelectedEngineeringDirectorId
                          }
                          Disabled={locationState.PageMode.IsDisable}
                          IsSearchable={true}
                          Validator={
                            locationPageState.Site.IsFieldRequired
                              ? locationPageState.Validator
                              : new SimpleReactValidator({
                                  messages: {
                                    default: "This field is required",
                                  },
                                })
                          }
                          Rules={
                            locationPageState.Site.IsFieldRequired
                              ? "required|not_in:0"
                              : ""
                          }
                          IsEnableValidator={
                            locationPageState.Site.IsFieldRequired ?? false
                          }
                          Required={
                            locationPageState.Site.IsFieldRequired ?? false
                          }
                        />
                      </div>
                      <div className="form-group">
                        <HPRDropDownMsGraphSingleSelect
                          Items={locationPageState.Site?.EmployeeList}
                          Label={"EHS Director"}
                          Id="location_ehsDirector"
                          Name="EHSDirector"
                          Url={
                            locationPageState.Site?.EmployeeList &&
                            locationPageState.Site?.EmployeeList?.length > 0
                              ? "Location/GetEmployeesListForDropDown"
                              : ""
                          }
                          onDropDownChange={onDropDownChangeEHSDirector}
                          SelectedValue={
                            locationPageState.Site.SelectedEHSDirectorId
                          }
                          Disabled={locationState.PageMode.IsDisable}
                          IsSearchable={true}
                        />
                      </div>
                      <div className="form-group">
                        <HPRDropDownMsGraphSingleSelect
                          Items={locationPageState.Site?.EmployeeList}
                          Label={"QC Manager"}
                          Id="location_qcManager"
                          Name="QCManager"
                          Url={
                            locationPageState.Site?.EmployeeList &&
                            locationPageState.Site?.EmployeeList?.length > 0
                              ? "Location/GetEmployeesListForDropDown"
                              : ""
                          }
                          onDropDownChange={onDropDownChangeQCManagement}
                          SelectedValue={
                            locationPageState.Site.SelectedQCManagementId
                          }
                          Disabled={locationState.PageMode.IsDisable}
                          IsSearchable={true}
                        />
                      </div>
                      <div className="form-group">
                        <HPRDropDownMsGraphSingleSelect
                          Items={locationPageState.Site?.EmployeeList}
                          Label={"Maintenance Manager"}
                          Id="location_maintenanceManager"
                          Name="MaintenanceManager"
                          Url={
                            locationPageState.Site?.EmployeeList &&
                            locationPageState.Site?.EmployeeList?.length > 0
                              ? "Location/GetEmployeesListForDropDown"
                              : ""
                          }
                          onDropDownChange={onDropDownChangeMainteneceManager}
                          SelectedValue={
                            locationPageState.Site.SelectedMainteneceManagerId
                          }
                          Disabled={locationState.PageMode.IsDisable}
                          IsSearchable={true}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2} lg={2} md={2} xl={2}></Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={2}
                  className="width100-p overflow-auto tab-view-tabpanel-assigninspection"
                  style={{ height: listHeight3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2} lg={2} md={2} xl={2}></Grid>
                    <Grid item xs={12} lg={8} md={12} xl={8} className="pl-0">
                      <div className="Grid pb-20">
                        {/* Grid Header */}

                        <div className="quetion-grid-head-assigninspection mb-20 tab-view-grid-head-assigninspection">
                          <Grid container>
                            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                              <div>Standard</div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
                              <div>Site Primary SME(s)</div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>

                      <div className="form-group mt-20 pt-10">
                        {locationPageState.Site.LocationStandards?.map(
                          (item, index) => (
                            <Grid key={index} container>
                              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                <div className="inline-element pl-10 pt-10">
                                  <span>{item.Standard.Name}</span>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
                                <div className={"form-group"}>
                                  <div className="inline-element multi-scroll pr-10">
                                    <HPRDropDownMsGraphMultipleSelect
                                      Id={`locationStandardSME_${item.Standard.Id}`}
                                      Label="Select SME(s) of the standard"
                                      Name="locationStandardSME"
                                      Select={item.LocationStandardSmes?.map(
                                        (sme) => sme?.Sme?.Uuid
                                      )}
                                      Items={
                                        locationPageState.Site?.EmployeeList
                                      }
                                      Url={
                                        locationPageState.Site?.EmployeeList &&
                                        locationPageState.Site?.EmployeeList
                                          ?.length > 0
                                          ? "Location/GetEmployeesListForDropDown"
                                          : ""
                                      }
                                      onMultipleSelectDropDownChange={(
                                        selectOptions: Array<DropDownDTO>,
                                        selectedValues: Array<string>
                                      ) =>
                                        handleLocationStandardDropDownListChange(
                                          selectOptions,
                                          selectedValues,
                                          item
                                        )
                                      }
                                      Disabled={
                                        locationState.PageMode.IsDisable
                                      }
                                      IsSearchable
                                    ></HPRDropDownMsGraphMultipleSelect>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={2} lg={2} md={2} xl={2}></Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={3}
                  className="width100-p tab-view-tabpanel-assigninspection"
                  style={{ height: lastListHeight, overflow: "auto" }}
                >
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {/* 1st row */}
                        <Grid container spacing={2} className="fx-align-center">
                          {/* Start Add Section */}
                          {/* <Grid
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                            xl={2}
                          ></Grid> */}
                          {/* <Grid
                            item
                            xs={12}
                            sm={12}
                            md={10}
                            lg={10}
                            style={{ marginBottom: "-25px" }}
                            ref={tab4Topic}
                          >
                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid
                                  container
                                  spacing={2}
                                  className="fx-right"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      <h4 className="p-b-30 bold m-left0">
                                        Search user(s) to add to the event
                                      </h4>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                    md={7}
                                    lg={7}
                                    xl={7}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      <FormGroup>
                                        <FormControl>
                                          <div className="form-group selectGroup multi-scroll">
                                            <HPRDropDownMsGraphMultipleSelect
                                              Id={`Email Recipient(s)`}
                                              Label="Email Recipient(s)"
                                              Name="Email Recipient(s)"
                                              Select={
                                                locationPageState?.Site
                                                  .SelectedEmployeeRecipientList
                                              }
                                              Items={
                                                locationPageState?.Site
                                                  ?.SiteEmployeeDropDownList
                                              }
                                              Url={
                                                locationPageState?.Site
                                                  ?.SiteEmployeeDropDownList &&
                                                locationPageState?.Site
                                                  ?.SiteEmployeeDropDownList
                                                  ?.length > 0
                                                  ? "Location/GetEmployeesListForDropDown"
                                                  : ""
                                              }
                                              onMultipleSelectDropDownChange={(
                                                selectOptions: Array<DropDownDTO>,
                                                selectedValues: Array<string>
                                              ) =>
                                                handleEmailRecipientMainDropDownListChange(
                                                  selectOptions,
                                                  selectedValues
                                                )
                                              }
                                              Disabled={
                                                locationState.PageMode.IsDisable
                                              }
                                              IsSearchable={true}
                                            ></HPRDropDownMsGraphMultipleSelect>
                                          </div>
                                        </FormControl>
                                      </FormGroup>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                  >
                                    <div className="fx p-b-30 ">
                                      <Button
                                        style={{
                                          margin: 2,
                                          // padding: "10px 20px",
                                        }}
                                        className="primary-btn-small"
                                        disableElevation
                                        size="small"
                                        variant="contained"
                                        startIcon={
                                          <CardAdd variant="Outline" />
                                        }
                                        onClick={onClickAddEmailReceipientBtn}
                                        disabled={
                                          locationState.PageMode.IsDisable
                                        }
                                      >
                                        Add
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid> */}

                          {/* End Add Section */}

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                {/* <Grid
                                  container
                                  spacing={2}
                                  className="fx"
                                  ref={tab4Search}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    className="pl-10 pt-0"
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="search-bar--small mb-10 tab-view-loc-srch-resize"
                                    >
                                      <Paper
                                        component="form"
                                        sx={{
                                          p: "2px 4px",
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <InputBase
                                          style={{ width: "unset" }}
                                          sx={{ ml: 1, flex: 1 }}
                                          placeholder={
                                            "Search Notification Event"
                                          }
                                          inputProps={{
                                            "aria-label": "Locations",
                                          }}
                                          type="search"
                                          onKeyPress={onsearchEventNameKeyPress}
                                          onChange={onsearchEventChange}
                                        />
                                        <IconButton
                                          type="button"
                                          className="m-0"
                                          sx={{ p: "10px" }}
                                          aria-label="search"
                                          onClick={() => {
                                            eventSearchBtnClick();
                                          }}
                                        >
                                          <SearchNormal1
                                            variant="Outline"
                                            className="ico-secondary"
                                            size={18}
                                          />
                                        </IconButton>
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={0.5}
                                    lg={0.5}
                                    xl={0.5}
                                    className="pt-0"
                                  ></Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="fx-right pr-20 pt-0"
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="search-bar--small mb-10"
                                    >
                                      <Paper
                                        component="form"
                                        sx={{
                                          p: "2px 4px",
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <InputBase
                                          sx={{ ml: 1, flex: 1 }}
                                          placeholder={
                                            "Search Email Recipient(s)"
                                          }
                                          inputProps={{
                                            "aria-label": "Locations",
                                          }}
                                          type="search"
                                          onKeyPress={onsearchUserNameKeyPress}
                                          onChange={onsearchUserChange}
                                        />
                                        <IconButton
                                          type="button"
                                          className="m-0"
                                          sx={{ p: "10px" }}
                                          aria-label="search"
                                          onClick={() => {
                                            userSearchBtnClick();
                                          }}
                                        >
                                          <SearchNormal1
                                            variant="Outline"
                                            className="ico-secondary"
                                            size={18}
                                          />
                                        </IconButton>
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                </Grid> */}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            className="p-l-10"
                            // style={{
                            //   maxHeight: lastListHeight,
                            //   overflow: "auto",
                            // }}
                          >
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <h4 className="bold">Module</h4>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <FormGroup className="">
                                  <HPRCheckBox
                                    Id={"Chk_All"}
                                    Name={"Chk_All"}
                                    Label={"Select all"}
                                    onCheckBoxChange={handleCheckAllSelected}
                                    ClassName={""}
                                    checked={locationPageState.Site.CheckAll}
                                    Disabled={locationState.PageMode.IsDisable}
                                  />
                                </FormGroup>
                                <FormGroup className="">
                                  <HPRCheckBox
                                    Id={"Remediation"}
                                    Name={"Remediation"}
                                    Label={"Remediation"}
                                    ClassName={""}
                                    onCheckBoxChange={
                                      handleCheckRemediationSelected
                                    }
                                    checked={
                                      locationPageState.Site.CheckRemediation
                                    }
                                    Disabled={locationState.PageMode.IsDisable}
                                  />
                                </FormGroup>
                                {/* <h4 className="p-b-5">Observation</h4> */}
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} lg={10}>
                              <Grid container>
                                {/* 1st row */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="fx-align-center"
                                  >
                                    <Grid
                                      item
                                      xs={4}
                                      sm={4}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                    >
                                      <h4 className="bold location-er-m-b">
                                        Notification Event
                                      </h4>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={8}
                                      sm={8}
                                      md={7}
                                      lg={7}
                                      xl={7}
                                    >
                                      <h4 className="bold location-er-m-b">
                                        Email Recipient(s)
                                      </h4>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {/* 2nd row */}
                                {locationPageState?.Site
                                  ?.EmailEventCategories != undefined &&
                                locationPageState?.Site?.EmailEventCategories
                                  ?.length > 0 ? (
                                  <>
                                    {locationPageState.Site.EmailEventCategories?.map(
                                      (val, index) => (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                        >
                                          <Grid
                                            container
                                            spacing={2}
                                            className="fx-align-center"
                                          >
                                            <Grid
                                              item
                                              xs={4}
                                              sm={4}
                                              md={3}
                                              lg={3}
                                              xl={3}
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <FormGroup className="p-b-10 ">
                                                  <HPRCheckBox
                                                    Id={
                                                      "Email_Category_" + val.Id
                                                    }
                                                    Name={
                                                      "Email_Category_" + val.Id
                                                    }
                                                    Label={val.EventCategory}
                                                    ClassName={""}
                                                    onCheckBoxChange={
                                                      handleIndividualSelected
                                                    }
                                                    checked={
                                                      val.CheckIndividual
                                                    }
                                                    Disabled={
                                                      locationState.PageMode
                                                        .IsDisable
                                                    }
                                                  />
                                                </FormGroup>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={8}
                                              sm={8}
                                              md={7}
                                              lg={7}
                                              xl={7}
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <FormGroup>
                                                  <FormControl>
                                                    <div className="form-group selectGroup multi-scroll ml-5">
                                                      <HPRDropDownMsGraphMultipleSelect
                                                        Id={
                                                          "Email_Category_" +
                                                          val.Id
                                                        }
                                                        Label=""
                                                        Name="Email Recipient(s)"
                                                        Select={val.EmailRecipients?.map(
                                                          (emp) =>
                                                            emp?.Employee?.Uuid
                                                        )}
                                                        Items={
                                                          locationPageState
                                                            ?.Site
                                                            ?.SiteEmployeeDropDownList
                                                        }
                                                        Url={
                                                          locationPageState
                                                            ?.Site
                                                            ?.SiteEmployeeDropDownList &&
                                                          locationPageState
                                                            ?.Site
                                                            ?.SiteEmployeeDropDownList
                                                            ?.length > 0
                                                            ? "Location/GetEmployeesListForDropDown"
                                                            : ""
                                                        }
                                                        onMultipleSelectDropDownChange={(
                                                          selectOptions: Array<DropDownDTO>,
                                                          selectedValues: Array<string>
                                                        ) =>
                                                          handleEmailEventCategoryDropDownListChange(
                                                            selectOptions,
                                                            selectedValues,
                                                            val.Id
                                                          )
                                                        }
                                                        Disabled={
                                                          locationState.PageMode
                                                            .IsDisable
                                                        }
                                                        IsSearchable={true}
                                                      ></HPRDropDownMsGraphMultipleSelect>
                                                    </div>
                                                  </FormControl>
                                                </FormGroup>
                                              </Grid>
                                            </Grid>
                                            {/* <Grid
                                          item
                                          xs={12}
                                          sm={2}
                                          md={2}
                                          lg={2}
                                          xl={2}
                                        >
                                          <div className="fx p-b-30">
                                            <Tooltip title={"Edit"}>
                                              <IconButton className="ml-5 cursor-pointer mt-5 fx">
                                                <Edit
                                                  id={
                                                    "Email_Category_" + val.Id
                                                  }
                                                  className="cursor-pointer"
                                                  size="21"
                                                  color="#0277bd"
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Save"}>
                                              <IconButton className="ml-5 cursor-pointer mt-5 fx">
                                                <DirectboxReceive
                                                  id={
                                                    "Email_Category_" + val.Id
                                                  }
                                                  className="cursor-pointer"
                                                  size="21"
                                                  color="#0277bd"
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </Grid> */}
                                          </Grid>
                                        </Grid>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* Items Found  */}
                                    {/* <Grid item xs={12} className="mb-15"> */}
                                    {/* <div className="records-found">
                                      {setNoRecordsFoundMsg(
                                        locationPageState?.Site?.EmailEventCategories?.filter(
                                          (x) =>
                                            x.EmailRecipients != null &&
                                            x.EmailRecipients.filter(
                                              (k) => k.Id
                                            ).length
                                        ).length
                                      )}
                                    </div> */}
                                    {/* </Grid> */}
                                    {/* Items Found  End */}
                                    {/* no data start */}
                                    <div className="no-data width100-p flex--container">
                                      <img
                                        className=""
                                        src={noData}
                                        style={{}}
                                      ></img>
                                      <div className="gray-text body2 flex__item--vcenter nodata-text">
                                        No records found
                                      </div>
                                    </div>
                                    {/* no data end */}
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <h4 className="p-b-10">Action</h4>
              </Grid> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
              </Box>
            </Grid>
          </Grid>

          {/* End Tab View */}
        </Grid>
      </div>
    </>
  );
};

export default LocationPage;
