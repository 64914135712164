import { ChangeEvent, useEffect, useRef, useState } from "react";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import { PageModeType } from "../../../common/types/common.page.type";
import {
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StandardSubSystemDTO,
} from "../../../common/types/common.dto.types";
import {
  EnumButtonModeType,
  EnumGridDataShowRowCount,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  StandardSubSystemPageType,
  SubSystemPageParametersType,
} from "./StandardSubSystemPage.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { Box, Button, Grid, IconButton, InputBase, Paper } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  AddCircle,
  ArrowLeft2,
  ArrowRight2,
  CardSlash,
  CloseCircle,
  Edit,
  SearchNormal1,
} from "iconsax-react";
import {
  deleteStandardSubSystem,
  getStandardSubSystemList,
  saveStandardSubSystem,
  updateStandardSubSystem,
} from "./StandardSubSystemPage.action";
import {
  setPageMode,
  setStandardSubSystem,
  setStandardSubSystemInitState,
  setStandardSubSystemSearchParameter,
  setSubSystemModalOpen,
} from "./StandardSubSystemPage.reducer";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import HPRDataGrid from "../../../components/common/HPRDataGrid/HRPDataGrid";
import { StandardPageParametersType } from "../StandardPage/StandardPage.types";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import HPRSplitButton from "../../../components/common/HPRSplitButton/HPRSplitButton";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import SimpleReactValidator from "simple-react-validator";
import dayjs from "dayjs";
import {
  HPRDataGridToolbarProps,
  HPRGridColDef,
} from "../../../components/common/HPRDataGrid/HPRDataGrid.types";

const initialState = {
  PageMode: {
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  Data: {} as PaginationDTO,
  StandardSubSystem: {
    Id: 0,
    Name: "",
    StandardId: 0,
    Version: 1,
    Status: EnumStatus.Draft,
  } as StandardSubSystemDTO,
  StandardSubSystemSearchParameter: {
    StandardSubSystemSearchParams: {
      Name: "",
      StandardId: 0,
    } as StandardSubSystemDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as SearchParameterDTO,
  SubSystemModalOpen: false,
  Validator: new SimpleReactValidator({
    messages: {
      default: "This field is required",
    },
  }),
  ShowErrorMsg: false,
  ButtonModeType: EnumButtonModeType.Save,
} as StandardSubSystemPageType;

const StandardSubSystemPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  const [standardSubSystemPageState, setStandardSubSystemPageState] =
    useState(initialState);

  // pass parameters to page

  const location = useLocation();
  const parameter: SubSystemPageParametersType = location.state;

  // use for page navigation
  const navigate = useNavigate();

  const dataFetchedRef = useRef(false);

  const dispatch = useAppDispatch();
  const standardSubSystemState = useAppSelector(
    (state) => state.standardSubSystemReducer
  );

  //=========================================================

  function onClickViewEditBtn() {
    dispatch(setPageMode(EnumPageMode.ViewMode));
    handleOpen();
  }

  const columns: HPRGridColDef[] = [
    {
      field: "Id",
      headerName: "Id",
      hideColumn: true,
      hideable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      field: "Name-1",
      headerName: "Sub-system",
      flex: 14,
      valueGetter: (params) => params.row?.Name,
      value: "",
    },
    {
      field: "ModifiedBy-3",
      headerName: "Last modified by",
      flex: 6,
      valueGetter: (params) => params.row?.ModifiedBy,
      value: "",
    },
    {
      field: "ModifiedDate-4",
      headerName: "Last modified date",
      flex: 6,
      valueGetter: (params) =>
        dayjs(params.row?.ModifiedDate).format("DD-MMM-YYYY HH:mm"),
      value: "",
    },
    {
      field: " ",
      flex: 1,
      sortable: false,
      filterable: false,
      hide: parameter?.StatusId === EnumStatus.Deactivated,
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking

          dispatch(
            setStandardSubSystem({
              Id: params.row?.Id,
              Name: params.row?.Name,
              StandardId: parameter?.StandardId,
            } as StandardSubSystemDTO)
          );
          setStandardSubSystemPageState((values) => {
            return {
              ...values,
              StandardSubSystem: {
                ...values.StandardSubSystem,
                Name: params.row?.Name,
                Id: params.row?.Id,
              },
            };
          });
          onClickViewEditBtn();
        };
        return (
          <>
            <Button
              className="standards-icon-forward d-flex flex-valign-middle cursor-pointer grid-icon-btn"
              onClick={onClick}
            >
              <ArrowRight2 size="32" variant="Outline" />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (parameter?.StandardId > 0) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      dispatch(
        setStandardSubSystemInitState({
          ...standardSubSystemState,
          StandardSubSystem: {
            ...standardSubSystemState.StandardSubSystem,
            StandardId: parameter.StandardId,
          },
          StandardSubSystemSearchParameter: {
            ...standardSubSystemState.StandardSubSystemSearchParameter,
            StandardSubSystemSearchParams: {
              ...standardSubSystemState.StandardSubSystemSearchParameter
                .StandardSubSystemSearchParams,
              StandardId: parameter.StandardId,
              Name: standardSubSystemPageState.StandardSubSystemSearchParameter
                .StandardSubSystemSearchParams.Name,
            },
          },
        })
      );

      dispatch(
        getStandardSubSystemList({
          ...standardSubSystemPageState.StandardSubSystemSearchParameter,
          StandardSubSystemSearchParams: {
            ...standardSubSystemPageState.StandardSubSystemSearchParameter
              .StandardSubSystemSearchParams,
            StandardId: parameter.StandardId,
          },
        })
      );

      setStandardSubSystemPageState((values) => {
        return {
          ...values,
          StandardSubSystem: {
            ...values.StandardSubSystem,
            StandardId: parameter.StandardId,
          },
          StandardSubSystemSearchParameter: {
            ...values.StandardSubSystemSearchParameter,
            StandardSubSystemSearchParams: {
              ...values.StandardSubSystemSearchParameter
                .StandardSubSystemSearchParams,
              StandardId: parameter.StandardId,
            },
          },
        };
      });
    }
  }, []);

  //when reducer state change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setStandardSubSystemPageState((values) => {
      return {
        ...values,
        Data: {
          ...values.Data,
          Data: standardSubSystemState.Data.Data,
          TotalRecordsCount: standardSubSystemState.Data.TotalRecordsCount,
          SelectedPage: standardSubSystemState.Data.SelectedPage,
        },
        StandardSubSystem: {
          Id: standardSubSystemState.StandardSubSystem.Id,
          Name: standardSubSystemState.StandardSubSystem.Name,
          StandardId: parameter.StandardId,
          Version: standardSubSystemState.StandardSubSystem.Version,
          Status: standardSubSystemState.StandardSubSystem.Status,
        } as StandardSubSystemDTO,
        StandardSubSystemSearchParameter: {
          ...values.StandardSubSystemSearchParameter,
          SelectedPage: standardSubSystemState.Data.SelectedPage,
          SortBy:
            standardSubSystemState.StandardSubSystemSearchParameter.SortBy,
          GridPageSize:
            standardSubSystemState.StandardSubSystemSearchParameter
              .GridPageSize,
        },
        SubSystemModalOpen: standardSubSystemState.SubSystemModalOpen,
        PageMode: standardSubSystemState.PageMode,
      };
    });
  }, [standardSubSystemState]);

  const onKeyPressSearchName = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();

      if (
        standardSubSystemPageState.StandardSubSystemSearchParameter
          .StandardSubSystemSearchParams.Name !==
        standardSubSystemState.StandardSubSystemSearchParameter
          .StandardSubSystemSearchParams.Name
      ) {
        onChangeStandardSubSystemSearchParameter(
          standardSubSystemPageState?.StandardSubSystemSearchParameter
            ?.IsAdvanceSearch,
          1
        );
      }
    }
  };

  const onClickDeleteBtn = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to deactivate?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          if (standardSubSystemPageState.StandardSubSystem?.Id > 0) {
            dispatch(deleteStandardSubSystem(standardSubSystemPageState));

            //hide validation message
            standardSubSystemPageState.Validator.hideMessages();
          }
        },
      } as PopupProps)
    );
  };

  const searchBtnClick = (IsAdvanceSearch: boolean) => {
    onChangeStandardSubSystemSearchParameter(IsAdvanceSearch, 1);
  };

  //================================ Search ============================================================

  const onChangeStandardSubSystemSearchParameter = (
    isAdvanceSearch?: boolean,
    selectedPage?: number
  ) => {
    dispatch(
      setStandardSubSystemSearchParameter({
        ...standardSubSystemPageState.StandardSubSystemSearchParameter,
        IsAdvanceSearch: isAdvanceSearch
          ? isAdvanceSearch
          : standardSubSystemPageState.StandardSubSystemSearchParameter
              .IsAdvanceSearch,
        SelectedPage: selectedPage
          ? selectedPage
          : standardSubSystemPageState.StandardSubSystemSearchParameter
              .SelectedPage,
      })
    );

    dispatch(
      getStandardSubSystemList({
        ...standardSubSystemPageState.StandardSubSystemSearchParameter,
        IsAdvanceSearch: isAdvanceSearch
          ? isAdvanceSearch
          : standardSubSystemPageState.StandardSubSystemSearchParameter
              .IsAdvanceSearch,
        SelectedPage: selectedPage
          ? selectedPage
          : standardSubSystemPageState.StandardSubSystemSearchParameter
              .SelectedPage,
      })
    );
  };

  const onChangeSearchName = (event: ChangeEvent<HTMLInputElement>) => {
    setStandardSubSystemPageState((values) => {
      return {
        ...values,
        StandardSubSystemSearchParameter: {
          ...values.StandardSubSystemSearchParameter,
          StandardSubSystemSearchParams: {
            ...values.StandardSubSystemSearchParameter
              .StandardSubSystemSearchParams,
            Name: event.target.value,
          },
        },
      };
    });

    if (!event.target.value) {
      dispatch(
        getStandardSubSystemList({
          ...standardSubSystemPageState.StandardSubSystemSearchParameter,
          StandardSubSystemSearchParams: {
            ...standardSubSystemPageState.StandardSubSystemSearchParameter
              .StandardSubSystemSearchParams,
            Name: "",
          },
        })
      );
    }
  };

  const onPaginationCallBack = (newPage: number) => {
    if (
      standardSubSystemPageState.StandardSubSystemSearchParameter
        .SelectedPage !== newPage
    ) {
      onChangeStandardSubSystemSearchParameter(undefined, newPage);
    }
  };

  const SaveSubSystem = (buttonModeType: EnumButtonModeType) => {
    if (IsPageValid()) {
      dispatch(
        saveStandardSubSystem({
          ...standardSubSystemPageState,
          ButtonModeType: buttonModeType,
        })
      );
    }
  };

  const onSortByCallBack = (selectedSortBy: SortDropDownDTO) => {
    dispatch(
      setStandardSubSystemSearchParameter({
        ...standardSubSystemPageState.StandardSubSystemSearchParameter,
        StandardReferenceSearchParams: {
          ...standardSubSystemPageState.StandardSubSystemSearchParameter
            .StandardReferenceSearchParams,
        },
        SortBy: selectedSortBy,
        SelectedPage: 1,
      })
    );

    dispatch(
      getStandardSubSystemList({
        ...standardSubSystemPageState.StandardSubSystemSearchParameter,
        StandardReferenceSearchParams: {
          ...standardSubSystemPageState.StandardSubSystemSearchParameter
            .StandardReferenceSearchParams,
        },
        SortBy: selectedSortBy,
        SelectedPage: 1,
      })
    );
  };

  //-----Button functions-----//
  const SaveButtonClick = () => {
    SaveSubSystem(EnumButtonModeType.Save);
  };
  const onClickSaveAndCloseBtn = () => {
    SaveSubSystem(EnumButtonModeType.SaveAndClose);
  };

  const onClickSaveAndContinueBtn = () => {
    SaveSubSystem(EnumButtonModeType.SaveAndContinue);
  };

  const onClickEditBtn = () => {
    dispatch(setPageMode(EnumPageMode.EditMode));
  };

  const onClickUpdateAndCloseBtn = () => {
    UpdateSubSystem(EnumButtonModeType.UpdateAndClose);
  };
  const onClickUpdateBtn = () => {
    UpdateSubSystem(EnumButtonModeType.Update);
  };

  const OnSubSystemTextChange = (name: string, value: any) => {
    setStandardSubSystemPageState((values) => {
      return {
        ...values,
        StandardSubSystem: {
          ...values.StandardSubSystem,
          Name: value,
        },
      };
    });
  };

  const onClickCancelBtn = () => {
    //hide validation message

    standardSubSystemPageState.Validator.hideMessages();

    if (standardSubSystemPageState.PageMode.IsShowEditBtn) {
      dispatch(
        setStandardSubSystem({
          Id: 0,
          Name: "",
          StandardId: parameter?.StandardId,
          Version: 1,
          Status: EnumStatus.Published,
        } as StandardSubSystemDTO)
      );

      dispatch(setPageMode(EnumPageMode.Default));
      handleClose();
    } else if (standardSubSystemPageState.PageMode.IsShowSaveBtn) {
      if (
        standardSubSystemPageState.StandardSubSystem.Name === null ||
        standardSubSystemPageState.StandardSubSystem.Name.match(/^ *$/) !== null
      ) {
        dispatch(
          setStandardSubSystem({
            Id: 0,
            Name: "",
            StandardId: parameter?.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardSubSystemDTO)
        );
        dispatch(setPageMode(EnumPageMode.Default));
        handleClose();
      } else {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(
                setStandardSubSystem({
                  Id: 0,
                  Name: "",
                  StandardId: parameter?.StandardId,
                  Version: 1,
                  Status: EnumStatus.Published,
                } as StandardSubSystemDTO)
              );
              dispatch(setPageMode(EnumPageMode.Default));
              handleClose();
            },
          } as PopupProps)
        );
      }
    } else {
      //Check original data changed
      if (
        standardSubSystemPageState.StandardSubSystem.Name !==
        standardSubSystemState.StandardSubSystem.Name
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setStandardSubSystemPageState((values) => {
                return {
                  ...values,
                  StandardSubSystem: {
                    Id: standardSubSystemState.StandardSubSystem.Id,
                    Name: standardSubSystemState.StandardSubSystem.Name,
                    StandardId: parameter?.StandardId,
                    Version: 1,
                    Status: standardSubSystemState.StandardSubSystem.Status,
                  } as StandardSubSystemDTO,
                };
              });

              dispatch(setPageMode(EnumPageMode.ViewMode));
            },
          } as PopupProps)
        );
      } else {
        dispatch(setPageMode(EnumPageMode.ViewMode));
      }
    }
  };

  const CloseButtonClick = () => {
    dispatch(
      setStandardSubSystem({
        Id: 0,
        Name: "",
        StandardId: parameter?.StandardId,
        Version: 1,
        Status: EnumStatus.Published,
      } as StandardSubSystemDTO)
    );
    dispatch(setPageMode(EnumPageMode.Default));
    handleClose();
  };

  const onClickAddBtn = () => {
    dispatch(setPageMode(EnumPageMode.AddMode));
    handleOpen();
  };

  //Update SubSystem
  const UpdateSubSystem = (buttonModeType: EnumButtonModeType) => {
    if (IsPageValid()) {
      //dataFetchedRef.current = false;

      dispatch(
        updateStandardSubSystem({
          ...standardSubSystemPageState,
          ButtonModeType: buttonModeType,
        })
      );
    }
  };

  const IsPageValid = (): boolean => {
    let isValid = standardSubSystemPageState.Validator.allValid();
    if (!isValid) {
      setStandardSubSystemPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !standardSubSystemPageState.ShowErrorMsg,
        };
      });

      //show validation message
      standardSubSystemPageState.Validator.showMessages();
    } else {
      //hide validation message
      standardSubSystemPageState.Validator.hideMessages();
    }

    return isValid;
  };

  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleOpen = () => {
    dispatch(setSubSystemModalOpen(true));
  };
  const handleClose = () => {
    dispatch(setSubSystemModalOpen(false));
  };

  return (
    <>
      {/* <Grid container columns={12} className="global-header-wrap"> */}
      {/* <Grid item lg={12} md={12} sm={12} xs={12} className="">
          <h1>{props.PageTitle + "s"}</h1>
        </Grid> */}
      {/* </Grid> */}

      <div className="content-section-card">
        <Grid container>
          {/* Button bar */}
          <Grid container>
            <div className="flex--container width100-p">
              <div className="flex__item--inherit pr-10">
                <Button
                  aria-label="back"
                  className="sub-head-back-btn back"
                  onClick={() => {
                    navigate("/standard", {
                      state: {
                        StandardId: parameter?.StandardId,
                      } as StandardPageParametersType,
                    });
                  }}
                  title="Back to home page"
                >
                  {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                  <ArrowBackIosNewOutlinedIcon className="" />
                  <span className="body-bold secondary-color">Back</span>
                </Button>{" "}
              </div>
              <div className="global-header-wrap">
                <h1 className="page_title">{props.PageTitle + "s"}</h1>
              </div>
              <div className="flex__item search-bar--small ml-10 mr-10">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    value={
                      standardSubSystemPageState
                        .StandardSubSystemSearchParameter
                        .StandardSubSystemSearchParams?.Name
                    }
                    onChange={onChangeSearchName}
                    onKeyPress={onKeyPressSearchName}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by Sub-system"
                    inputProps={{ "aria-label": "Sub-system" }}
                    type="search"
                  />
                  <IconButton
                    onClick={() => {
                      searchBtnClick(false);
                    }}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    className="m-0"
                  >
                    <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                      size={18}
                    />
                  </IconButton>
                  {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  // onClick={() => {
                  //   serachBtnClick(true);
                  // }}
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                >
                  <Filter variant="Outline" className="ico-secondary" />
                </IconButton> */}
                  {/* <IconButton
                  onClick={() => {
                    searchBtnClick(false);
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  onClick={() => {
                    searchBtnClick(true);
                  }}
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                >
                  <FilterAltOutlinedIcon />
                </IconButton> */}
                </Paper>
              </div>
              <Button
                className="primary-btn-small"
                size="small"
                disableElevation
                variant="outlined"
                startIcon={<AddCircle variant="Outline" />}
                onClick={onClickAddBtn}
                style={{
                  visibility:
                    standardSubSystemPageState?.PageMode?.IsShowAddBtn &&
                    parameter?.StatusId !== EnumStatus.Deactivated
                      ? "visible"
                      : "hidden",
                }}
              >
                Add
              </Button>
            </div>
          </Grid>

          <Grid container className="mb-15">
            {/* <Grid item xs={12} className="search-bar--small">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  value={
                    standardSubSystemPageState.StandardSubSystemSearchParameter
                      .StandardSubSystemSearchParams?.Name
                  }
                  onChange={onChangeSearchName}
                  onKeyPress={onKeyPressSearchName}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by Sub-system"
                  inputProps={{ "aria-label": "Sub-system" }}
                  type="search"
                />
                <IconButton
                  onClick={() => {
                    searchBtnClick(false);
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="m-0"
                >
                  <SearchNormal1 variant="Outline" className="ico-secondary" />
                </IconButton> */}
            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  // onClick={() => {
                  //   serachBtnClick(true);
                  // }}
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                >
                  <Filter variant="Outline" className="ico-secondary" />
                </IconButton> */}
            {/* <IconButton
                  onClick={() => {
                    searchBtnClick(false);
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  onClick={() => {
                    searchBtnClick(true);
                  }}
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                >
                  <FilterAltOutlinedIcon />
                </IconButton> */}
            {/* </Paper>
            </Grid> */}

            {/* <Grid item xs={12} className="mb-15">
              <div className="records-found">
                {standardSubSystemPageState.Data.TotalRecordsCount ===
                  undefined ||
                standardSubSystemPageState.Data.TotalRecordsCount === 0
                  ? "No records found"
                  : standardSubSystemPageState.Data.TotalRecordsCount === 1
                  ? "1 record found"
                  : standardSubSystemPageState.Data.TotalRecordsCount +
                    " records found"}
              </div>
            </Grid> */}
          </Grid>
          <Modal
            className="modal-outter sub-form-section"
            open={standardSubSystemPageState.SubSystemModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="modal-header">
                <h1>
                  <span>Sub-system</span>
                  <span>
                    {standardSubSystemPageState.PageMode.IsShowSaveBtn ? (
                      <>
                        <HPRSplitButton
                          Name="SystemSave"
                          ButtonOptions={[
                            {
                              ButtonId: "0",
                              ButtonName: "Save",
                              onButtonClickCallBack: () => {
                                SaveButtonClick();
                              },
                            },
                            {
                              ButtonId: "1",
                              ButtonName: "Save & Close",
                              onButtonClickCallBack: () => {
                                onClickSaveAndCloseBtn();
                              },
                            },
                            {
                              ButtonId: "2",
                              ButtonName: "Save & Continue",
                              onButtonClickCallBack: () => {
                                onClickSaveAndContinueBtn();
                              },
                            },
                          ]}
                        ></HPRSplitButton>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardSubSystemPageState.PageMode.IsShowUpdateBtn ? (
                      <>
                        <HPRSplitButton
                          Name="SystemUpdateBtn"
                          ButtonOptions={[
                            {
                              ButtonId: "0",
                              ButtonName: "Update & Close",
                              onButtonClickCallBack: () => {
                                onClickUpdateAndCloseBtn();
                              },
                            },
                            {
                              ButtonId: "1",
                              ButtonName: "Update",
                              onButtonClickCallBack: () => {
                                onClickUpdateBtn();
                              },
                            },
                          ]}
                        ></HPRSplitButton>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardSubSystemPageState.PageMode.IsShowEditBtn ? (
                      <>
                        <Button
                          className="primary-btn-small"
                          size="small"
                          variant="outlined"
                          disableElevation
                          onClick={onClickEditBtn}
                          startIcon={<Edit variant="Outline" />}
                        >
                          Edit
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardSubSystemPageState.PageMode.IsShowDeleteBtn ? (
                      <>
                        <Button
                          className="primary-btn-small"
                          size="small"
                          variant="outlined"
                          disableElevation
                          startIcon={<CardSlash variant="Outline" />}
                          onClick={onClickDeleteBtn}
                        >
                          Deactivate
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {standardSubSystemPageState.PageMode.IsShowCancelBtn && (
                      <Button
                        className="secondary-btn-small"
                        size="small"
                        variant="outlined"
                        disableElevation
                        startIcon={<CloseCircle variant="Outline" />}
                        onClick={onClickCancelBtn}
                      >
                        Cancel
                      </Button>
                    )}
                    {standardSubSystemPageState.PageMode.IsShowCloseBtn && (
                      <Button
                        className="secondary-btn-small"
                        size="small"
                        variant="outlined"
                        disableElevation
                        onClick={CloseButtonClick}
                        startIcon={<CloseCircle variant="Outline" />}
                      >
                        Close
                      </Button>
                    )}
                  </span>
                </h1>
              </div>
              <div className="modal-content">
                <Grid container>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={8}
                    sm={12}
                    xs={12}
                    className="m-auto"
                  >
                    <div className="form-group">
                      <HPRTextBox
                        Id=""
                        Label="Sub-system"
                        Name="subSystem"
                        Value={
                          standardSubSystemPageState?.StandardSubSystem.Name
                        }
                        Type={EnumTextBoxType.Text}
                        Disabled={standardSubSystemPageState.PageMode.IsDisable}
                        onTextBoxChange={OnSubSystemTextChange}
                        Validator={standardSubSystemPageState.Validator}
                        Rules={"required"}
                        IsEnableValidator={true}
                        AutoFocus={
                          !standardSubSystemPageState.PageMode.IsDisable
                        }
                        Required={true}
                      ></HPRTextBox>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Modal>

          <div className="HPRDataGrid-wrapper grid-border-b-0">
            <HPRDataGrid
              Columns={columns}
              Data={standardSubSystemPageState?.Data?.Data}
              TotalRecordsCount={
                standardSubSystemPageState.Data?.TotalRecordsCount
              }
              AutoHeight={true}
              onPaginationChangeCallBack={onPaginationCallBack}
              SelectedPage={standardSubSystemPageState.Data?.SelectedPage}
              HPRDataGridToolbarProps={
                {
                  ShowTotalRecordsCount: true,
                } as HPRDataGridToolbarProps
              }
              PageSize={
                standardSubSystemPageState?.StandardSubSystemSearchParameter
                  ?.GridPageSize ??
                EnumGridDataShowRowCount.GridDataShowRowCount
              }
              // MenuFunctions={{
              //   onChangeSortBy: onSortByCallBack,
              // }}
              ShowCustomePagination
            ></HPRDataGrid>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default StandardSubSystemPage;
