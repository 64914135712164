// ==============================|| COMPONENTS - COMMON - HPRTEXTBOX ||============================== //

import {
  styled,
  TextField,
  createTheme,
  FormControl,
  InputLabel,
  TextareaAutosize,
} from "@mui/material";
import React, { useCallback } from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { EnumTextBoxType } from "./HPRTextBox.enum";
import { HPRTextBoxProps } from "./HPRTextBox.types";

const HPRTextBoxAutoResize = (props: HPRTextBoxProps) => {
  // State for keep text
  const [textValue, setName] = React.useState("");

  // On text change event
  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setName(event.target.value.trimStart());

    if (props.onTextBoxChange) {
      props.onTextBoxChange(event.target.name, event.target.value.trimStart());
    }
  };

  // On focusout event
  const onBlur = () => {
    if (props.onBlurChange) {
      props.onBlurChange(props.Name, textValue);
    }
  };

  // On click event
  const onClick = () => {
    if (props.onTextBoxClick) {
      props.onTextBoxClick(props.Name, textValue);
    }
  };

  // const CssTextField = styled(TextField)({
  //   "label + &": {
  //     color: "#023047",
  //   },
  //   "& label.Mui-focused": {
  //     color: "#purple",
  //   },
  //   "& .MuiInput-underline:after": {
  //     borderBottomColor: "#8ECAE6",
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "& fieldset": {
  //       borderColor: "#8ECAE6",
  //     },
  //     "&:hover fieldset": {
  //       borderColor: "#219EBC",
  //     },
  //     "&.Mui-focused fieldset": {
  //       borderColor: "#219EBC",
  //     },
  //   },
  //   MuiInputLabel: {
  //     styleOverrides: {
  //       outlined: {
  //         "&.MuiInputLabel-shrink": {
  //           fontSize: "18px",
  //         },
  //       },
  //     },
  //   },
  // });

  //Auto Focus Text Field
  //The React useCallback Hook returns a memoized callback function.
  //Think of memoization as caching a value so that it does not need to be recalculated.

  const callbackRefAutoFocus = useCallback(
    (inputElement) => {
      if (inputElement && props.AutoFocus) {
        inputElement.focus();
      }
    },
    [props.AutoFocus]
  );

  return (
    <>
      <FormControl
        // className="width100-p autotextarea"
        className={
          props.Disabled
            ? "width100-p autotextarea-dis"
            : "width100-p autotextarea"
        }
      >
        <InputLabel
          id="demo-simple-select-helper-label"
          className="input-label"
          shrink
        >
          {props.Label}
          {props.Required ? <span className="asterisk-red">*</span> : null}
        </InputLabel>
        <TextareaAutosize
          // className="hpr-autotextarea"
          className={
            props.Disabled ? "hpr-autotextarea-dis" : "hpr-autotextarea"
          }
          style={{ resize: "none" }}
          aria-label="autotext"
          id={props.Id}
          name={props.Name}
          value={props.Value}
          disabled={props.Disabled ? props.Disabled : false}
          onChange={onChange}
          onBlur={onBlur}
          required={props.Required}
          title={props.Title ?? ""}
          maxLength={props.TextLength}
        />
      </FormControl>

      <div className="flex--container mb-10 v-align">
        <div className="validate-msg flex__item" style={{}}>
          {props.IsEnableValidator ? (
            props.Validator.message(
              props.Name,
              props.Value ? props.Value : "",
              props.Rules ? props.Rules : ""
            )
          ) : (
            <></>
          )}
        </div>
        {props.ShowTextCounter && (
          <div
            className={`${
              props.Disabled
                ? "validate-hint-dis"
                : "validate-hint flex__item--inherit"
            } ${
              props.TextLength == 0
                ? "validate-hint-dis no-hint"
                : "validate-hint flex__item--inherit"
            }
          `}
          >
            {props.ShowTextCounter
              ? `${props.Value?.replaceAll(/\r/g, "")?.length}/${
                  props.TextLength
                }`
              : // ${t("characters")}` //characters
                null}
          </div>
        )}
        {/* <div
          className={
            props.Disabled && props.TextLength != 0
              ? "validate-hint-dis"
              : "validate-hint flex__item--inherit"
          }
        >
          {props.ShowTextCounter
            ? `${props.Value?.replaceAll(/\r/g, "")?.length}/${props.TextLength}
          characters`
            : ""}
        </div> */}
      </div>
    </>
  );
};

export default HPRTextBoxAutoResize;
