import {
  QuestionnaireDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// client object for axios calls to backend
const client = new ApiClient();

const renameFile = (originalFile, newName): File => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

export const getSiteInspectionQuestiontData = async (
  questionnaire: QuestionnaireDTO
): Promise<ActionResponseType> => {
  try {
    let saveQuestionnaire: QuestionnaireDTO = { ...questionnaire };
    const URL: string = `Questionnaire/GetInspectionStandardQuestions`;

    if (!saveQuestionnaire.InspectionStandardStatus)
      saveQuestionnaire.InspectionStandardStatus = 10;

    saveQuestionnaire.UploadedFileList = UploadFileListBeforeUpload(
      saveQuestionnaire.UploadedFileList
    );
    saveQuestionnaire.UploadedFileList = ClearUploadFileListFile(
      saveQuestionnaire.UploadedFileList
    );

    let siteInspectionQuestion = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, saveQuestionnaire)
      .then((response: ActionResponseType) => {
        siteInspectionQuestion = response;
      })
      .catch((error) => {
        // console.log(error);
        siteInspectionQuestion = {} as ActionResponseType;
      });
    return siteInspectionQuestion;
  } catch (error) {
    throw error;
  }
};

export const saveInspectionQuestionAnswer = async (
  siteInspectionQuestionParam: QuestionnaireDTO
): Promise<ActionResponseType> => {
  try {
    let saveQuestionnaire: QuestionnaireDTO = {
      ...siteInspectionQuestionParam,
    };

    const URL: string = `Questionnaire/SaveInspectionQuestionAnswer`;
    let siteInspectionQuestion = {} as ActionResponseType;

    saveQuestionnaire.UploadedFileList = UploadFileListBeforeUpload(
      saveQuestionnaire.UploadedFileList
    );

    saveQuestionnaire.UploadedFileList = ClearUploadFileListFile(
      saveQuestionnaire.UploadedFileList
    );
    await client
      .post<ActionResponseType>(URL, saveQuestionnaire)
      .then((response) => {
        siteInspectionQuestion = response;
      })
      .catch((error) => {
        siteInspectionQuestion = {} as ActionResponseType;
        return error;
      });

    return siteInspectionQuestion;
  } catch (error) {
    throw error;
  }
};

export const saveInspectionStandardStatus = async (
  siteInspectionStandardParam: QuestionnaireDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Questionnaire/SaveInspectionStandardStatus`;
    let status = {} as ActionResponseType;

    siteInspectionStandardParam.UploadedFileList = UploadFileListBeforeUpload(
      siteInspectionStandardParam.UploadedFileList
    );

    siteInspectionStandardParam.UploadedFileList = ClearUploadFileListFile(
      siteInspectionStandardParam.UploadedFileList
    );

    await client
      .post<ActionResponseType>(URL, siteInspectionStandardParam)
      .then((response) => {
        status = response;
      })
      .catch((error) => {
        status = {} as ActionResponseType;
        return error;
      });
    return status;
  } catch (error) {
    throw error;
  }
};

export const inspectionFileUpload = async (uploadFileDTO: UploadFileDTO) => {
  try {
    const URL: string = `File/UploadFile`;
    let status: ActionResponseType;

    uploadFileDTO = UploadFileListBeforeUpload([
      uploadFileDTO,
    ] as Array<UploadFileDTO>)[0];
    status = await toBase64(uploadFileDTO.File).then((result) => {
      uploadFileDTO.FileBase64 = result?.toString().split(",")[1];
      uploadFileDTO.File = null;
      return client
        .post<ActionResponseType>(URL, uploadFileDTO)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          status = {} as ActionResponseType;
          return error;
        });
    });
    // status = await response;
    if (status) return status;
  } catch (error) {
    throw error;
  }
};

const toBase64 = async (file) =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

const UploadFileListBeforeUpload = (
  UploadedFileList: Array<UploadFileDTO>
): Array<UploadFileDTO> => {
  if (UploadedFileList && UploadedFileList.length > 0) {
    UploadedFileList = [...UploadedFileList].map(
      (uploadFile, index: number) => ({
        ...uploadFile,
        FilePreview: "",
        File:
          uploadFile.File != null
            ? renameFile(
                uploadFile.File,
                (index < 10 ? `00${index}` : index < 100 ? `0${index}` : "") +
                  uploadFile.FileName
              )
            : null,
        FileData: null,
        // FileBase64: convertFileToBase64(uploadFile.File),
      })
    );
  }

  return UploadedFileList;
};

const ClearUploadFileListFile = (
  UploadedFileList: Array<UploadFileDTO>
): Array<UploadFileDTO> => {
  if (UploadedFileList && UploadedFileList.length > 0) {
    UploadedFileList = [...UploadedFileList].map(
      (uploadFile, index: number) => ({
        ...uploadFile,
        FilePreview: "",
        File: null,
        FileData: null,
      })
    );
  }

  return UploadedFileList;
};
