import { Button, FormControl, FormGroup, Grid } from "@mui/material";
import { ArrowLeft2, CloseCircle, DirectboxReceive, Edit } from "iconsax-react";
import { EnumTextBoxType } from "../../../../components/common/HPRTextBox/HPRTextBox.enum";
import HPRTextBox from "../../../../components/common/HPRTextBox/HPRTextBox";
import HPRDropDownSingleSelect from "../../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";
import HPRCheckBox from "../../../../components/common/HPRCheckBox/HPRCheckBox";
import { PageRegistrationProperties } from "../../../../core/reducers/pageRegistrationReducer.types";
import usePageRegistration from "../../../../core/hooks/usePageRegistration";
import {
  Functionality,
  Pages,
  UserRoleManagementScreensPageType,
} from "./UserRoleManagementScreens.types";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../core/hooks";
import { useEffect, useRef, useState } from "react";
import {
  DropDownDTO,
  SearchParameterDTO,
  SystemGroupDTO,
  SystemGroupFunctionDTO,
  SystemGroupSaveDTO,
  SystemGroupScreenDTO,
  SystemScreenDTO,
  SystemScreenFunctionDTO,
} from "../../../../common/types/common.dto.types";
import dayjs from "dayjs";
import {
  fetchSystemGroupById,
  getAddUserRoleScreenData,
  saveUserRoleScreenData,
} from "./UserRoleManagementScreens.action";
import {
  EnumListShowRowCountList,
  EnumPageMode,
  EnumPageType,
  EnumStatus,
} from "../../../../common/enums/common.enums";
import { resetScreen, setPageMode } from "./UserRoleManagementScreens.reducer";
import {
  EnumSystemFunctions,
  EnumSystemFunctionTypes,
} from "../../../../core/reducers/functionRights.types";
import SimpleReactValidator from "simple-react-validator";
import { usePrompt } from "../../../../core/hooks/use-prompt/use-prompt";
import { UsePromptTypes } from "../../../../core/hooks/use-prompt/use-prompt.types";
import { CheckFunctionRights } from "../../../../common/functions/common.functions";
import { openPopup } from "../../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../../components/Popups/PopupProps.types";
import { ActionResponseType } from "../../../../common/types/common.types";
import { getSystemGroupFunctionsAndScreensById } from "./UserRoleManagementScreens.api";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const UserRoleManagementScreen = (props: PageRegistrationProperties) => {
  usePageRegistration(props);

  const initialState: UserRoleManagementScreensPageType = {
    GroupId: 0,
    SuperGroupName: "",
    Scopes: [],
    CurrentGroup: {
      Id: 0,
      ModifiedById: 0,
      ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
      GroupName: "",
      AzureGroupName: "",
      Status: 0,
      IsActive: false,
      ScopeId: 0,
      Scope: null,
      StatusNavigation: null,
      CreatedByNavigation: null,
      CreatedById: 0,
      CreatedDate: dayjs(new Date()).format("YYYY-MM-DD"),
      GroupFunctions: null,
      GroupScreens: null,
    } as SystemGroupDTO,
    PageMode: 0,
    GroupScreens: [] as Pages[],
    Functionalities: [] as Functionality[],
    SelectAllPages: false,
    SelectAllFunctionality: false,
    GroupNameValidator: new SimpleReactValidator({
      messages: {
        default: "Group Name is required",
      },
    }),
    ScopeValidator: new SimpleReactValidator({
      messages: {
        default: "Scope is required", //`Function/Area field is required`
      },
    }),
  };

  const location = useLocation();
  const dispatch = useAppDispatch();
  // use for page navigation
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);
  const fetchSystemGroupByRef = useRef(false);

  const [userRoleManagementScreenState, setUserRoleManagementScreenState] =
    useState<UserRoleManagementScreensPageType>(initialState);

  useEffect(() => {
    dispatch(
      getAddUserRoleScreenData({
        CheckCompleted: false,
        IsAdvanceSearch: false,
        ShowDeactivated: false,
        SelectedPage: 1,
        ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
        SystemGroupSearchParams: {
          Id: 1,
          ModifiedById: 0,
          ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
          Status: 0,
        } as SystemGroupDTO,
      } as SearchParameterDTO)
    );
  }, [dispatch]);

  const authState = useAppSelector((state) => state.authReducer);
  const userRoleManagementScreenReduxState: UserRoleManagementScreensPageType =
    useAppSelector((state) => state.UserRoleManagementScreenReducer);

  const [superAdmin, setSuperAdmin] = useState("");

  useEffect(() => {
    if (
      userRoleManagementScreenReduxState.PageMode !== EnumPageMode.AddMode &&
      location?.state?.Id
    ) {
      if (!fetchSystemGroupByRef.current) {
        fetchSystemGroupByRef.current = true;
        dispatch(fetchSystemGroupById(location.state.Id));
      }
      fetchSystemGroupScreensandFunctionsById();
    }
  }, [userRoleManagementScreenReduxState.GroupScreens, location.state]);

  const fetchSystemGroupScreensandFunctionsById = async () => {
    const response: ActionResponseType =
      await getSystemGroupFunctionsAndScreensById(
        userRoleManagementScreenState.CurrentGroup.Id
          ? userRoleManagementScreenState.CurrentGroup.Id
          : location.state.Id
      );

    setUserRoleManagementScreenState((prevState) => {
      return {
        ...prevState,
        GroupScreens: userRoleManagementScreenReduxState.GroupScreens.map(
          (groupScreens) => {
            return {
              ...groupScreens,
              IsChecked: response.Data?.SystemGroupScreens?.includes(
                groupScreens.Screen.Id
              ),
              Functionalities: groupScreens?.Functionalities.map(
                (functionality) => {
                  return {
                    ...functionality,
                    IsChecked: response.Data?.SystemGroupFunctions?.includes(
                      functionality.Functionality.SystemFunctionId
                    ),
                  };
                }
              ),
            };
          }
        ),
      };
    });
  };

  useEffect(() => {
    if (!location.state?.Id) {
      dispatch(setPageMode(EnumPageMode.AddMode));
    } else if (
      userRoleManagementScreenReduxState.PageMode === 0 &&
      location.state?.Id
    ) {
      dispatch(setPageMode(EnumPageMode.ViewMode));
    }
  }, []);

  useEffect(() => {
    setSuperAdmin(userRoleManagementScreenReduxState.SuperGroupName);
  }, [userRoleManagementScreenReduxState.SuperGroupName]);

  useEffect(() => {
    setSuperAdmin(userRoleManagementScreenReduxState.SuperGroupName);
  }, [userRoleManagementScreenReduxState.SuperGroupName]);

  useEffect(() => {
    if (userRoleManagementScreenReduxState.PageMode === EnumPageMode.AddMode) {
      setUserRoleManagementScreenState((prevState) => {
        return {
          ...prevState,
          GroupScreens: userRoleManagementScreenReduxState.GroupScreens,
          Functionalities: userRoleManagementScreenReduxState.Functionalities,
        };
      });
    }
  }, [userRoleManagementScreenReduxState.GroupScreens, location.state]);

  const IsPageValid = (): boolean => {
    let isValid = true;

    if (!userRoleManagementScreenState.GroupNameValidator.allValid()) {
      isValid = false;
    }
    if (!userRoleManagementScreenState.ScopeValidator.allValid()) {
      isValid = false;
    }

    if (!isValid) {
      //show validation message
      userRoleManagementScreenState.GroupNameValidator.showMessages();
      userRoleManagementScreenState.ScopeValidator.showMessages();

      return isValid;
    } else {
      userRoleManagementScreenState.GroupNameValidator.hideMessages();
      userRoleManagementScreenState.ScopeValidator.hideMessages();
    }

    return isValid;
  };

  useEffect(() => {
    setUserRoleManagementScreenState((prevState) => {
      return {
        ...prevState,
        GroupScreens: prevState.GroupScreens?.map(
          (groupScreens, groupScreensIndex) => {
            return {
              ...groupScreens,
              IsCheckFromFunctionalities: groupScreens.Functionalities?.some(
                (functionality) => functionality.IsChecked
              ),
            };
          }
        ),
      };
    });
  }, [
    userRoleManagementScreenState.GroupScreens?.map(
      (group) => group.Functionalities
    ),
  ]);

  const [screenId, setScreenId] = useState(-1);

  useEffect(() => {
    setUserRoleManagementScreenState((prevState) => {
      return {
        ...prevState,
        GroupScreens: prevState.GroupScreens?.map(
          (groupScreens, groupScreensIndex) => {
            if (groupScreensIndex === screenId) {
              const selectAllFunc = groupScreens.Functionalities?.every(
                (functionality) => functionality.IsChecked
              );
              return {
                ...groupScreens,
                SelectAllFunctionalities: selectAllFunc,
              };
            } else {
              return groupScreens;
            }
          }
        ),
      };
    });
  }, [
    userRoleManagementScreenState.GroupScreens?.map(
      (group) => group.Functionalities
    ),
  ]);

  useEffect(() => {
    if (userRoleManagementScreenReduxState.PageMode != EnumPageMode.AddMode) {
      setUserRoleManagementScreenState((prevState) => {
        return {
          ...prevState,
          CurrentGroup: {
            Id: userRoleManagementScreenReduxState.CurrentGroup.Id,
            ModifiedById: 0,
            ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
            GroupName:
              userRoleManagementScreenReduxState.CurrentGroup?.GroupName ??
              location.state?.GroupName ??
              "",
            Status: 0,
            AzureGroupName:
              userRoleManagementScreenReduxState.CurrentGroup.AzureGroupName,
            IsActive: false,
            ScopeId: userRoleManagementScreenReduxState.CurrentGroup?.ScopeId,
            Scope: userRoleManagementScreenReduxState.CurrentGroup.Scope,
            ModifiedByNavigation: null,
            StatusNavigation: null,
            CreatedById: 0,
            CreatedByNavigation: null,
            CreatedDate: dayjs(new Date()).format("YYYY-MM-DD"),
            GroupFunctions: location.state?.GroupFunctions,
            GroupScreens: location.state?.GroupScreens,
          } as SystemGroupDTO,
          PageMode: userRoleManagementScreenReduxState.PageMode,
          //pageMode: location.state.azureGroupName === superAdmin ? EnumPageMode.ViewMode: EnumPageMode.EditMode,
        };
      });
    }
  }, [dispatch, location.state, userRoleManagementScreenReduxState]);

  const [scope, setScope] = useState<DropDownDTO[]>([]);

  useEffect(() => {
    setScope(userRoleManagementScreenReduxState.Scopes);
  }, [userRoleManagementScreenReduxState.Scopes]);

  // useEffect(() => {
  //   setUserRoleManagementScreenState((prevState) => {
  //     const updatedGroupScreens = prevState.GroupScreens?.map(
  //       (groupScreen, groupScreensIndex) => {
  //         if (
  //           groupScreensIndex === 2 &&
  //           (prevState.GroupScreens[3]?.IsChecked ||
  //             prevState.GroupScreens[4]?.IsChecked ||
  //             prevState.GroupScreens[5]?.IsChecked ||
  //             prevState.GroupScreens[6]?.IsChecked ||
  //             prevState.GroupScreens[7]?.IsChecked)
  //         ) {
  //           // If groupScreensIndex 2 and groupScreensIndex 3 is checked, set isChecked to true for groupScreensIndex 2
  //           return {
  //             ...groupScreen,
  //             IsChecked: true,
  //           };
  //         }
  //         if (
  //           groupScreensIndex === 3 &&
  //           (prevState.GroupScreens[4]?.IsChecked ||
  //             prevState.GroupScreens[5]?.IsChecked ||
  //             prevState.GroupScreens[6]?.IsChecked ||
  //             prevState.GroupScreens[7]?.IsChecked)
  //         ) {
  //           return {
  //             ...groupScreen,
  //             IsChecked: true,
  //           };
  //         }
  //         // For other indices, return the existing groupScreen
  //         return groupScreen;
  //       }
  //     );

  //     return {
  //       ...prevState,
  //       GroupScreens: updatedGroupScreens,
  //     };
  //   });
  // }, [userRoleManagementScreenState]);

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.UserRoleManagementScreens,
      functionId,
      functionTypeId
    );
  };

  const onScopeChanged = (
    selectOption: DropDownDTO[],
    selectedValue: string,
    name?: string | undefined
  ) => {
    setIsDirty(true);
    setUserRoleManagementScreenState((prevState) => {
      return {
        ...prevState,
        CurrentGroup: {
          ...prevState.CurrentGroup,
          ScopeId: parseInt(selectedValue),
        } as SystemGroupDTO,
      };
    });
    setScope(selectOption);
  };

  const onADGroupNameChange = (name: string, value: any) => {
    setUserRoleManagementScreenState((prevState) => {
      return {
        ...prevState,
        CurrentGroup: {
          ...prevState.CurrentGroup,
          AzureGroupName: value,
        } as SystemGroupDTO,
      };
    });
  };

  const onGroupNameChange = (name: string, value: any) => {
    setIsDirty(true);
    setUserRoleManagementScreenState((prevState) => {
      return {
        ...prevState,
        CurrentGroup: {
          ...prevState.CurrentGroup,
          GroupName: value,
        } as SystemGroupDTO,
      };
    });
  };

  const saveSystemGroupState = () => {
    if (IsPageValid()) {
      const group: SystemGroupDTO = {
        Id: userRoleManagementScreenState.CurrentGroup.Id,
        ModifiedById: 0,
        ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
        GroupName: userRoleManagementScreenState.CurrentGroup.GroupName,
        Status: 0,
        AzureGroupName:
          userRoleManagementScreenState.CurrentGroup.AzureGroupName,
        IsActive: false,
        ScopeId: userRoleManagementScreenState.CurrentGroup.ScopeId,
        Scope: null,
        ModifiedByNavigation: null,
        StatusNavigation: null,
        CreatedByNavigation: null,
        CreatedById: 0,
        CreatedDate: dayjs(new Date()).format("YYYY-MM-DD"),
        GroupFunctions: null,
        GroupScreens: null,
      } as SystemGroupDTO;

      const screens: SystemScreenDTO[] =
        userRoleManagementScreenState.GroupScreens?.filter(
          (groupScreens) =>
            groupScreens.IsChecked || groupScreens.IsCheckFromFunctionalities
        )?.map((groupScreens) => groupScreens.Screen);

      const functions = userRoleManagementScreenState.GroupScreens?.filter(
        (groupScreens) =>
          groupScreens.IsChecked || groupScreens.IsCheckFromFunctionalities
      )?.map((groupScreens) =>
        groupScreens.Functionalities?.filter(
          (functionality) => functionality.IsChecked
        )?.map((functionality) => functionality.Functionality)
      );

      const groupScreenFunction: SystemScreenFunctionDTO[] = [];
      for (var i = 0; i < screens.length; i++) {
        const _functions = functions[i]?.map((functionality) => functionality);
        groupScreenFunction.push(..._functions);
      }

      const groupScreens: SystemGroupScreenDTO[] = [];
      for (var i = 0; i < screens.length; i++) {
        const groupScreen: SystemGroupScreenDTO = {
          Id: 0,
          SystemGroupId: group.Id,
          SystemScreenId: screens[i].Id,
          StatusId: EnumStatus.Published,
          ModifiedById: 0,
          ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
        } as SystemGroupScreenDTO;
        groupScreens.push(groupScreen);
      }

      const groupFunctions: SystemGroupFunctionDTO[] = [];
      for (var i = 0; i < groupScreenFunction.length; i++) {
        const groupFunction: SystemGroupFunctionDTO = {
          Id: 0,
          SystemGroupId: group.Id,
          SystemFunctionId: groupScreenFunction[i].SystemFunctionId,
          Status: EnumStatus.Published,
          ModifiedById: 0,
          ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
        } as SystemGroupFunctionDTO;
        groupFunctions.push(groupFunction);
      }

      const groupSave: SystemGroupSaveDTO = {
        CurrentGroup: group,
        GroupScreens: groupScreens,
        Functionalities: groupFunctions,
      } as SystemGroupSaveDTO;

      dispatch(saveUserRoleScreenData(groupSave));
      setIsDirty(false);
      setUserRoleManagementScreenState((prevState) => {
        return {
          ...prevState,
          CurrentGroup: group,
        };
      });
    }
  };

  const displayMapDiscardConfirmation = (): boolean => {
    if (isDirty) {
      return true;
    } else {
      return false;
    }
  };

  const prompt = {
    Message: "Are you sure you want to discard the changes?",
    When: displayMapDiscardConfirmation(),
    YesBtnClick() {
      dispatch(resetScreen());
      setIsDirty(false);
    },
    NoBtnClick() {
      setIsDirty(true);
    },
  } as UsePromptTypes;
  usePrompt(prompt);

  const cancelBtnClick = () => {
    const isDirtyTemp = isDirty;
    if (userRoleManagementScreenReduxState.PageMode === EnumPageMode.AddMode) {
      if (!isDirtyTemp) {
        navigate("/userRoleManagement");
      } else {
        setIsDirty(false);

        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              navigate("/userRoleManagement");
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      }
    } else {
      if (isDirtyTemp) {
        setIsDirty(false);

        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              userRoleManagementScreenState.GroupNameValidator.hideMessages();
              userRoleManagementScreenState.ScopeValidator.hideMessages();
              fetchSystemGroupScreensandFunctionsById();
              dispatch(
                fetchSystemGroupById(
                  userRoleManagementScreenState.CurrentGroup.Id
                )
              );
              dispatch(setPageMode(EnumPageMode.ViewMode));
            },
            NoBtnClick() {
              setIsDirty(isDirtyTemp);
            },
          } as PopupProps)
        );
      } else {
        userRoleManagementScreenState.GroupNameValidator.hideMessages();
        userRoleManagementScreenState.ScopeValidator.hideMessages();
        fetchSystemGroupScreensandFunctionsById();
        dispatch(
          fetchSystemGroupById(userRoleManagementScreenState.CurrentGroup.Id)
        );
        dispatch(setPageMode(EnumPageMode.ViewMode));
      }
    }
  };

  const onFunctionalityCheckBoxClick = (
    selectedFunctionality: Functionality,
    checked: boolean
  ) => {
    setIsDirty(true);

    const groupScreens = [
      ...userRoleManagementScreenState.GroupScreens?.map(
        (groupScreens, groupScreensIndex) => {
          if (groupScreensIndex === screenId) {
            return {
              ...groupScreens,
              Functionalities: groupScreens.Functionalities?.map(
                (functionality) => {
                  if (
                    functionality?.Functionality?.Id ===
                    selectedFunctionality?.Functionality?.Id
                  ) {
                    return {
                      ...functionality,
                      IsChecked: checked,
                    };
                  } else {
                    return functionality;
                  }
                }
              ),
            };
          } else {
            return groupScreens;
          }
        }
      ),
    ];

    setUserRoleManagementScreenState((prevState) => {
      return {
        ...prevState,
        GroupScreens: [
          ...groupScreens.map((screen) => {
            return {
              ...screen,
              IsChecked: screen.Functionalities?.some((some) => some.IsChecked),
            };
          }),
        ],
      };
    });
  };

  return (
    <>
      {/* <Grid container columns={12} className="global-header-wrap">
        <Grid item xs={12} className="">
          <h1>{props.PageTitle + "s"}</h1>
          <h1>User Role Management</h1>
        </Grid>
      </Grid> */}
      <div className="content-section-card">
        <Grid container>
          {/* Button bar */}
          <Grid container>
            {/* <Grid item xl={10} lg={9} md={8} sm={12} xs={12}> */}
            <div className="flex--container width100-p">
              <div className="flex--item--inherit pr-10">
                <Button
                  aria-label="back"
                  className="sub-head-back-btn back"
                  title="Back to home page"
                  onClick={() => {
                    dispatch(resetScreen());
                    navigate("/userRoleManagement");
                  }}
                >
                  {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                  <ArrowBackIosNewOutlinedIcon className="" />
                  <span className="body-bold secondary-color">Back</span>
                </Button>
              </div>
              <div className="global-header-wrap flex__item">
                <h1>
                  User Role Management Screen
                  {userRoleManagementScreenReduxState.PageMode !==
                    EnumPageMode.AddMode &&
                  userRoleManagementScreenReduxState.CurrentGroup?.GroupName
                    ? ": " +
                      userRoleManagementScreenReduxState.CurrentGroup?.GroupName
                    : ""}
                </h1>
              </div>
              <div className="flex__item--inherit">
                {userRoleManagementScreenState.PageMode ===
                EnumPageMode.ViewMode ? (
                  <Button
                    className="secondary-btn-small"
                    size="small"
                    variant="outlined"
                    startIcon={<CloseCircle variant="Outline" />}
                    onClick={() => {
                      dispatch(resetScreen());
                      navigate("/userRoleManagement");
                    }}
                  >
                    Close
                  </Button>
                ) : null}
                {userRoleManagementScreenState.PageMode !==
                EnumPageMode.ViewMode ? (
                  <Button
                    className="secondary-btn-small"
                    size="small"
                    variant="outlined"
                    startIcon={<CloseCircle variant="Outline" />}
                    onClick={cancelBtnClick}
                  >
                    Cancel
                  </Button>
                ) : null}

                {userRoleManagementScreenState.PageMode !==
                  EnumPageMode.ViewMode && (
                  <Button
                    className="primary-btn-small"
                    size="small"
                    variant="outlined"
                    startIcon={<DirectboxReceive variant="Outline" />}
                    onClick={() => {
                      saveSystemGroupState();
                    }}
                  >
                    Save
                  </Button>
                )}

                {checkSystemFunctionRight(
                  EnumSystemFunctions.UserRoleManagementPage_Edit_User_Role,
                  EnumSystemFunctionTypes.ButtonAccess
                ) &&
                  userRoleManagementScreenState.PageMode ===
                    EnumPageMode.ViewMode &&
                  location.state?.AzureGroupName != superAdmin && (
                    <Button
                      className="primary-btn-small"
                      size="small"
                      variant="outlined"
                      startIcon={<Edit variant="Outline" />}
                      onClick={() => {
                        dispatch(setPageMode(EnumPageMode.EditMode));
                      }}
                    >
                      Edit
                    </Button>
                  )}
              </div>
            </div>

            {/* </Grid> */}
          </Grid>
          <Grid container>
            <Grid container spacing={2} className="mb-15">
              <Grid
                container
                item
                xs={12}
                lg={12}
                md={12}
                xl={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                spacing={0}
                className="userrole"
              >
                {/* Start Tree View */}
                <Grid container className="mt-20">
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {/* 1st row */}
                    <Grid container spacing={2} className="fx-align-center">
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <FormGroup>
                          <FormControl>
                            <div className="form-group">
                              <HPRTextBox
                                Id={"GroupName"}
                                Label={"Group Name"}
                                Name={"GroupName"}
                                ClassName={""}
                                Value={
                                  userRoleManagementScreenState.CurrentGroup
                                    ?.GroupName ?? ""
                                }
                                onTextBoxChange={onGroupNameChange}
                                TextLength={50}
                                InputProps={{ maxLength: 50 }}
                                Rules={"required"}
                                Required={true}
                                Disabled={
                                  userRoleManagementScreenState.PageMode ===
                                  EnumPageMode.ViewMode
                                }
                                Validator={
                                  userRoleManagementScreenState.GroupNameValidator
                                }
                                IsEnableValidator={true}
                                Type={EnumTextBoxType.Text}
                                ShowTextCounter
                              ></HPRTextBox>
                            </div>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <FormGroup>
                          <FormControl>
                            <div className="form-group">
                              <HPRDropDownSingleSelect
                                Label={"Scope"}
                                Name="Scope"
                                onDropDownChange={onScopeChanged}
                                IsSearchable={true}
                                //SelectedValue={selectedScope}
                                Items={scope}
                                IsSearchableWithAdd={false}
                                Rules={"required|numeric|min:1,num"}
                                IsEnableValidator={true}
                                Required={true}
                                Disabled={
                                  userRoleManagementScreenState.PageMode ===
                                  EnumPageMode.ViewMode
                                }
                                Select={
                                  userRoleManagementScreenState?.CurrentGroup
                                    ?.ScopeId ?? 0
                                }
                                Validator={
                                  userRoleManagementScreenState.ScopeValidator
                                }
                              />
                            </div>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormGroup>
                          <FormControl>
                            {/* <div className="form-group">
                              <HPRTextBox
                                Id={"ADGroupName"}
                                Label={"AD Group Name"}
                                Name={"ADGroupName"}
                                Value={
                                  userRoleManagementScreenState?.CurrentGroup
                                    .AzureGroupName
                                }
                                onTextBoxChange={onADGroupNameChange}
                                ClassName={""}
                                TextLength={250}
                                InputProps={{ maxLength: 250 }}
                                Rules={"required"}
                                Required={true}
                                Disabled={
                                  userRoleManagementScreenState.PageMode ===
                                  EnumPageMode.ViewMode
                                }
                                // IsEnableValidator={true}
                                Type={EnumTextBoxType.Text}
                              ></HPRTextBox>
                            </div> */}
                          </FormControl>
                        </FormGroup>
                      </Grid>
                      <Grid container className="content-section-card-userrole">
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <Grid container>
                            {/* 1st Column */}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Grid
                                container
                                spacing={2}
                                className="fx-align-center"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <h3 className="p-b-20 bold">Pages</h3>
                                  {/* Serarch Start*/}
                                  {/* <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    className="search-bar mb-10"
                                  >
                                    <Paper
                                      component="form"
                                      sx={{
                                        p: "2px 4px",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder={"Search By page name"}
                                        inputProps={{
                                          "aria-label": "Locations",
                                        }}
                                        type="search"
                                      />
                                      <IconButton
                                        type="button"
                                        className="m-0"
                                        sx={{ p: "10px" }}
                                        aria-label="search"
                                      >
                                        <SearchNormal1
                                          variant="Outline"
                                          className="ico-secondary"
                                          size={18}
                                        />
                                      </IconButton>
                                    </Paper>
                                  </Grid> */}
                                  {/* Serarch End*/}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <FormGroup className="x">
                                      <HPRCheckBox
                                        Id={"SelectAll_DeselectAll_Pages"}
                                        Name={"SelectAll_DeselectAll_Pages"}
                                        Label={
                                          "Select all / Deselect all Pages"
                                        }
                                        Disabled={
                                          userRoleManagementScreenState.PageMode ===
                                          EnumPageMode.ViewMode
                                        }
                                        checked={
                                          userRoleManagementScreenState.GroupScreens &&
                                          userRoleManagementScreenState
                                            .GroupScreens?.length > 0 &&
                                          (userRoleManagementScreenState.GroupScreens?.every(
                                            (screen) => screen.IsChecked
                                          ) ||
                                            location.state?.AzureGroupName ===
                                              superAdmin)
                                        }
                                        indeterminateChecked={
                                          !userRoleManagementScreenState.GroupScreens?.every(
                                            (screen) => screen.IsChecked
                                          ) &&
                                          userRoleManagementScreenState.GroupScreens?.some(
                                            (screen) => screen.IsChecked
                                          ) &&
                                          location.state?.AzureGroupName !==
                                            superAdmin
                                        }
                                        onCheckBoxChange={(name, checked) => {
                                          setIsDirty(true);
                                          setUserRoleManagementScreenState(
                                            (prevState) => {
                                              return {
                                                ...prevState,
                                                SelectAllPages: checked,
                                                GroupScreens:
                                                  prevState.GroupScreens?.map(
                                                    (groupScreens) => {
                                                      return {
                                                        ...groupScreens,
                                                        IsChecked: checked,
                                                      };
                                                    }
                                                  ),
                                              };
                                            }
                                          );
                                        }}
                                      />
                                    </FormGroup>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  {/* <h4 className="p-b-10 bold">Master Data</h4> */}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    {userRoleManagementScreenState.GroupScreens?.map(
                                      (screen, index) => (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                          xl={12}
                                          className="fx mt-10"
                                          key={index}
                                        >
                                          <FormGroup className="mt-5 mr-0">
                                            <HPRCheckBox
                                              Id={`PagesListCheckbox_${index}`}
                                              Name={`PagesListCheckbox_${index}`}
                                              Disabled={
                                                userRoleManagementScreenState.PageMode ===
                                                EnumPageMode.ViewMode
                                              }
                                              checked={
                                                screen.IsChecked ||
                                                screen.IsCheckFromFunctionalities ||
                                                location.state
                                                  ?.AzureGroupName ===
                                                  superAdmin
                                              }
                                              onCheckBoxChange={(
                                                name,
                                                checked
                                              ) => {
                                                setIsDirty(true);
                                                setUserRoleManagementScreenState(
                                                  (prevState) => {
                                                    return {
                                                      ...prevState,
                                                      GroupScreens:
                                                        prevState.GroupScreens?.map(
                                                          (
                                                            groupScreens,
                                                            groupScreensIndex
                                                          ) => {
                                                            if (
                                                              groupScreensIndex ===
                                                              index
                                                            ) {
                                                              const isCheckedInFunctionalities =
                                                                userRoleManagementScreenState.GroupScreens[
                                                                  index
                                                                ].Functionalities.some(
                                                                  (func) =>
                                                                    func.IsChecked
                                                                );
                                                              if (
                                                                isCheckedInFunctionalities
                                                              ) {
                                                                dispatch(
                                                                  openPopup({
                                                                    Open: true,
                                                                    BodyText:
                                                                      "Please remove functional rights from this page before removing page permission.",
                                                                    HeaderText:
                                                                      "Warning",
                                                                    okBtnText:
                                                                      "OK",
                                                                    PopupType:
                                                                      EnumPopupType.WarningPopup,
                                                                  } as PopupProps)
                                                                );
                                                              }
                                                              return {
                                                                ...groupScreens,
                                                                IsChecked:
                                                                  checked,
                                                              };
                                                            } else {
                                                              return groupScreens;
                                                            }
                                                          }
                                                        ),
                                                    };
                                                  }
                                                );
                                              }}
                                            />
                                          </FormGroup>
                                          <Button
                                            variant="outlined"
                                            className={
                                              screenId === index &&
                                              userRoleManagementScreenState.PageMode !==
                                                EnumPageMode.ViewMode
                                                ? "tab-button-contained"
                                                : screenId === index &&
                                                  userRoleManagementScreenState.PageMode ===
                                                    EnumPageMode.ViewMode
                                                ? "tab-button-deactivate"
                                                : "tab-button-outlined"
                                            }
                                            onClick={() => setScreenId(index)}
                                          >
                                            {screen.Screen.Description}{" "}
                                          </Button>
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                  {/* <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="fx"
                              >
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={"Location"}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={"Buildings"}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={"Email Recipient(s)"}
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={"GPS Cordinates"}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={"Categorization"}
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={7} lg={7}>
                          <Grid container>
                            {/* 2nd Column */}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Grid
                                container
                                spacing={2}
                                className="fx-align-center"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <h3 className="p-b-20 bold">Functionality</h3>
                                  {/* Serarch Start*/}
                                  {/* <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    className="search-bar mb-10"
                                  >
                                    <Paper
                                      component="form"
                                      sx={{
                                        p: "2px 4px",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder={"Search By Functionality"}
                                        inputProps={{
                                          "aria-label": "Locations",
                                        }}
                                        type="search"
                                      />
                                      <IconButton
                                        type="button"
                                        className="m-0"
                                        sx={{ p: "10px" }}
                                        aria-label="search"
                                      >
                                        <SearchNormal1
                                          variant="Outline"
                                          className="ico-secondary"
                                          size={18}
                                        />
                                      </IconButton>
                                    </Paper>
                                  </Grid> */}
                                  {/* Serarch End*/}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <FormGroup className="">
                                      <HPRCheckBox
                                        Id={
                                          "SelectAll_DeselectAll_Pages_Functionality"
                                        }
                                        Name={
                                          "SelectAll_DeselectAll_Pages_Functionality"
                                        }
                                        Label={
                                          "Select all / Deselect all Functions"
                                        }
                                        Disabled={
                                          userRoleManagementScreenState.PageMode ===
                                          EnumPageMode.ViewMode
                                        }
                                        checked={
                                          userRoleManagementScreenState.GroupScreens &&
                                          userRoleManagementScreenState
                                            .GroupScreens[screenId]
                                            ?.Functionalities?.length > 0 &&
                                          (userRoleManagementScreenState.GroupScreens[
                                            screenId
                                          ]?.Functionalities?.every(
                                            (screen) => screen.IsChecked
                                          ) ||
                                            location.state?.AzureGroupName ===
                                              superAdmin)
                                        }
                                        indeterminateChecked={
                                          userRoleManagementScreenState.GroupScreens &&
                                          userRoleManagementScreenState
                                            .GroupScreens[screenId]
                                            ?.Functionalities?.length > 0 &&
                                          !userRoleManagementScreenState.GroupScreens[
                                            screenId
                                          ]?.Functionalities?.every(
                                            (screen) => screen.IsChecked
                                          ) &&
                                          userRoleManagementScreenState.GroupScreens[
                                            screenId
                                          ]?.Functionalities?.some(
                                            (screen) => screen.IsChecked
                                          ) &&
                                          location.state?.AzureGroupName !==
                                            superAdmin
                                        }
                                        onCheckBoxChange={(name, checked) => {
                                          setIsDirty(true);
                                          setUserRoleManagementScreenState(
                                            (prevState) => {
                                              return {
                                                ...prevState,
                                                // selectAllFunctionality:
                                                //   e.target.checked,
                                                GroupScreens:
                                                  prevState.GroupScreens?.map(
                                                    (
                                                      groupScreens,
                                                      groupScreensIndex
                                                    ) => {
                                                      if (
                                                        groupScreensIndex ===
                                                        screenId
                                                      ) {
                                                        return {
                                                          ...groupScreens,
                                                          IsChecked: checked,
                                                          SelectAllFunctionalities:
                                                            checked,
                                                          Functionalities:
                                                            groupScreens.Functionalities?.map(
                                                              (
                                                                functionality
                                                              ) => {
                                                                return {
                                                                  ...functionality,
                                                                  IsChecked:
                                                                    checked,
                                                                };
                                                              }
                                                            ),
                                                        };
                                                      } else {
                                                        return groupScreens;
                                                      }
                                                    }
                                                  ),
                                              };
                                            }
                                          );
                                        }}
                                      />
                                    </FormGroup>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  {/* <h4 className="p-b-10 bold">Master Data</h4> */}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="fx flex-wrap"
                                  >
                                    {screenId > -1 &&
                                      userRoleManagementScreenState.GroupScreens[
                                        screenId
                                      ]?.Screen?.SystemScreenCategories?.map(
                                        (ScreenCategory) => {
                                          return (
                                            <div
                                              className="x"
                                              key={`screenCategory_${ScreenCategory.Id}`}
                                            >
                                              {ScreenCategory?.Description && (
                                                <div className="function-head mb-20 body1 bold">
                                                  {ScreenCategory.Description}
                                                </div>
                                              )}

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="functionality-h pb-20"
                                              >
                                                {screenId > -1
                                                  ? userRoleManagementScreenState.GroupScreens[
                                                      screenId
                                                    ].Functionalities?.filter(
                                                      (filter) =>
                                                        filter.Functionality
                                                          .SystemScreenCategoryId ===
                                                        ScreenCategory.Id
                                                    )?.map(
                                                      (
                                                        functionality,
                                                        index
                                                      ) => {
                                                        return (
                                                          <FormGroup
                                                            key={`${
                                                              ScreenCategory.Description ??
                                                              "category"
                                                            }_${index}`}
                                                          >
                                                            <HPRCheckBox
                                                              Id={`functionalityListCHK_${index}`}
                                                              Name={`functionalityListCHK_${index}`}
                                                              Disabled={
                                                                userRoleManagementScreenState.PageMode ===
                                                                EnumPageMode.ViewMode
                                                              }
                                                              checked={
                                                                functionality.IsChecked ||
                                                                location.state
                                                                  ?.AzureGroupName ===
                                                                  superAdmin
                                                              }
                                                              onCheckBoxChange={(
                                                                name,
                                                                checked
                                                              ) => {
                                                                onFunctionalityCheckBoxClick(
                                                                  functionality,
                                                                  checked
                                                                );
                                                              }}
                                                              Label={
                                                                functionality
                                                                  .Functionality
                                                                  .SystemFunction
                                                                  ?.Description
                                                              }
                                                            />
                                                          </FormGroup>
                                                        );
                                                      }
                                                    )
                                                  : null}
                                              </Grid>
                                            </div>
                                          );
                                        }
                                      )}

                                    {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={"Edit Location"}
                                    />
                                  </FormGroup>
                                </Grid> */}
                                  </Grid>
                                  {/* <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="fx mt-20"
                              >
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={"Save Observation"}
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={"Edit Observation"}
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* End Tree View */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default UserRoleManagementScreen;
