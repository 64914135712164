// redux
import { createSlice } from "@reduxjs/toolkit";
import { AuthType } from "./auth.types";
import { FunctionRightDTO, SystemFunctionDTO } from "./functionRights.types";
import { PublicClientApplication } from "@azure/msal-browser";

// ==============================|| REDUCERS - AUTH REDUCERS ||============================== //

const initialState = {
  AccessToken: "",
  GeneralToken: "",
  Stamp: 0,
  Authenticated: false,
  SystemFunctions: [] as Array<SystemFunctionDTO>,
  FunctionRights: [] as Array<FunctionRightDTO>,
} as AuthType;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: AuthType, action: { payload: AuthType }) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    removeAuth: (state: AuthType) => {
      return { ...initialState };
    },
    setMSALInstance: (
      state: AuthType,
      action: { payload: PublicClientApplication }
    ) => {
      return {
        ...state,
        MSALlInstance: action.payload,
      };
    },
  },
});

export const authReducer = authSlice.reducer;
export const authReducerActions = authSlice.actions;
