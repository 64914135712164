import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ArrowLeft2,
  SearchNormal1,
  TickCircle,
  CloseCircle,
  Filter,
  BrushBig,
  Slash,
} from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  BulkApproveRemediationTrackerTaskDTO,
  BulkRejectRemediationTrackerTaskDTO,
  ColumnFiltertypeDTO,
  DropDownDTO,
  InitialSortStateDTO,
  PaginationDTO,
  RemediationTrackerTaskCommentDTO,
  RemediationTrackerTaskDTO,
  RemediationTrackerTaskQuestionDTO,
  RemediationTrackerTaskStatusDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import {
  EnumListShowRowCountList,
  EnumPageMode,
  EnumPageType,
  EnumRemediationTrackerTaskColumnType,
  EnumRemediationTrackerTaskStatus,
  EnumSortTypes,
} from "../../../common/enums/common.enums";
import {
  RemediationTrackerTasksPageParametersType,
  RemediationTrackerTasksPageType,
  TaskFilteredSummaryType,
} from "./RemediationTrackerTasksPage.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { ChangeEvent, forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  bulkApproveRemediationTrackerTask,
  bulkRejectRemediationTrackerTask,
  deleteRemediationTrackerTask,
  getRemediationTrackerTaskDetails,
  getRemediationTrackerTaskInitialData,
  getRemediationTrackerTaskList,
  getRemediationTrackerTasksColumnWiseFilter,
} from "./RemediationTrackerTasksPage.action";
import { remediationTrackerTasksReducerActions } from "./RemediationTrackerTasksPage.reducer";
import { RemediationTrackerTaskPageParametersType } from "../RemediationTrackerTaskPage/RemediationTrackerTaskPage.types";
import dayjs from "dayjs";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { useLocation } from "react-router-dom";
import HPRDropDownMultipleSelect from "../../../components/common/HPRDropDownMultipleSelect/HPRDropDownMultipleSelect";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import HPRDatePicker from "../../../components/common/HPRDatePicker/HPRDatePicker";
import { EnumDateType } from "../../../components/common/HPRDatePicker/HPRDatePicker.enum";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import {
  ListColumn,
  ViewRemediationTrackerTaskListPagination,
  ViewRemediationTrackerTaskListToolBarProps,
} from "../../components/ViewRemediationTrackerTaskList/ViewRemediationTrackerTaskList.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import HPRDropDownMsGraphMultipleSelect from "../../../components/common/HPRDropDownMultipleSelect/HPRDropDownMsGraphMultipleSelect";
import ViewRemediationTrackerTaskTable from "../../components/ViewRemediationTrackerTaskList/ViewRemediationTrackerTaskTable";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import {
  openPopup,
  updatePopupProps,
} from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { Height } from "@mui/icons-material";
import { PopupProps } from "../../../components/Popups/PopupProps.types";

// ==============================|| VIEWS - PAGES - REMEDIATION TRACKER TASKS ||============================== //
// modal popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const initialState = {
  Data: {
    Data: [] as Array<RemediationTrackerTaskDTO>,
    PageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    TotalRecordsCount: 0,
    StatusSummaryCount: {} as StatusSummaryCountDTO,
  } as PaginationDTO,
  RemediationTrackerTaskSearchParameter: {
    RemediationTrackerTaskSearchParams: {
      TaskName: "",
      Status: EnumRemediationTrackerTaskStatus.New,
      SelectedActionOwnerIdList: [] as Array<string>,
      SelectedLocationIdList: [] as Array<number>,
      SelectedStandardIdList: [] as Array<number>,
      SelectedPriorityIdList: [] as Array<number>,
      SelectedStatusIdList: [] as Array<number>,
      SelectedInspectionIdList: [] as Array<number>,
      SelectedApproverIdList: [] as Array<number>,
      SelectedPendingApproverIdList: [] as Array<string>,
      SelectedIsCapexRequiredIdList: [] as Array<number>,
      SelectedCapexStatusIdList: [] as Array<number>,
      SelectedAreaOwnerTypeIdList: [] as Array<number>,
      InspectionName: "",
      Q_No: "",
      ActionOwner: "",
      FromDueDate: null,
      ToDueDate: null,
      CapexamountRequired: null,
      CapexnumberAndName: "",
      RemediationTrackerTaskSortBy: {
        Label: "TaskId",
        Value: EnumRemediationTrackerTaskColumnType.TaskId.toString(),
        SortType: EnumSortTypes.Ascending,
      } as SortDropDownDTO,
      ShowTasksAssignedToMe: false,
      ShowTasksPendingMyApproval: false,
      ShowCompletedTasks: false,
      ShowCancelledTasks: false,
      Ernumber: "",
      StatusSummaryFilter: 0,
    } as RemediationTrackerTaskDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
  } as SearchParameterDTO,
  SelectedRemediationTrackerTask: [] as Array<RemediationTrackerTaskDTO>,
  DropDownListFilter: {
    LocationDropDownList: [] as Array<DropDownDTO>,
    InspectionDropDownList: [] as Array<DropDownDTO>,
    StandardDropDownList: [] as Array<DropDownDTO>,
    ActionOwnerDropDownList: [] as Array<DropDownDTO>,
    PriorityDropDownList: [] as Array<DropDownDTO>,
    StatusDropDownList: [] as Array<DropDownDTO>,
    IsCapexRequiredDropDownList: [] as Array<DropDownDTO>,
    CapexStatusDropDownList: [] as Array<DropDownDTO>,
    AreaOwnerTypeDropDownList: [] as Array<DropDownDTO>,
    PendingApproverDropDownList: [] as Array<DropDownDTO>,
  } as RemediationTrackerTaskDTO,
  ShowFilters: false,
  TaskStatusSummaryCount: [] as Array<RemediationTrackerTaskStatusDTO>,
  TaskFilteredSummary: [] as Array<TaskFilteredSummaryType>,
  DataLoading: false,
  MergeTaskBetweenMultipleLocationsAndInspection: false,
  SelectedApproveRejectRemediationTrackerTaskIdList: [] as Array<number>,
  IsFullScreen: false,
  IsEnableCreateMergeTask: false,
  ClearExpandedRows: false,
  ColumnOptions: [] as Array<ListColumn>,
} as RemediationTrackerTasksPageType;

//Numeric field
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowedDecimalSeparators={["%"]}
        decimalScale={2}
        thousandSeparator
      />
    );
  }
);
//End

const RemediationTrackerTasksPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // create state for RemediationTrackerTasksPageType with initialState values
  const [
    remediationTrackerTasksPageState,
    setRemediationTrackerTasksPageState,
  ] = useState<RemediationTrackerTasksPageType>(initialState);

  //-----standard list heigh ------
  const mainHeading = useRef(null);
  const wrapperCard = useRef(null);
  const searchRef = useRef(null);
  //const checkboxFilter = useRef(null);
  const statusFilter = useRef(null);
  const advanceFilter = useRef(null);
  //Set Hight

  const [gridHeight, setGridHeight] = useState(0);
  const [advanceFilterHeight, setAdvanceFilterHeight] = useState<number>(0);

  useEffect(() => {
    let advanceFilterHeight = advanceFilter?.current
      ? advanceFilter?.current?.offsetHeight + 15
      : 0;

    let gridHeight =
      wrapperCard?.current?.offsetHeight -
      searchRef?.current?.offsetHeight -
      //checkboxFilter?.current?.offsetHeight -
      statusFilter?.current?.offsetHeight -
      advanceFilterHeight -
      45;
    setGridHeight(gridHeight);
    setAdvanceFilterHeight(advanceFilterHeight);
    function handleResize() {
      let gridHeight =
        wrapperCard?.current?.offsetHeight -
        searchRef?.current?.offsetHeight -
        //checkboxFilter?.current?.offsetHeight -
        statusFilter?.current?.offsetHeight -
        advanceFilterHeight -
        45;
      setGridHeight(gridHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let advanceFilterHeight = advanceFilter?.current
      ? advanceFilter?.current?.offsetHeight + 15
      : 0;
    // debugger;
    let gridHeight =
      wrapperCard?.current?.offsetHeight -
      searchRef?.current?.offsetHeight -
      //checkboxFilter?.current?.offsetHeight -
      statusFilter?.current?.offsetHeight -
      advanceFilterHeight -
      45;
    setAdvanceFilterHeight(advanceFilterHeight);
    setGridHeight(gridHeight);

    function handleResize() {
      let gridHeight =
        wrapperCard?.current?.offsetHeight -
        searchRef?.current?.offsetHeight -
        // checkboxFilter?.current?.offsetHeight -
        statusFilter?.current?.offsetHeight -
        (advanceFilter?.current ? advanceFilter?.current?.offsetHeight : 0) -
        45;
      setGridHeight(gridHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [remediationTrackerTasksPageState?.TaskFilteredSummary]);

  const [initialSortState, setInitialSortState] = useState<
    Array<InitialSortStateDTO>
  >([{} as InitialSortStateDTO]);

  //Advance Search Modal
  const [openAdvanceSearchModel, setOpenAdvanceSearchModel] = useState(false);

  // use for page navigation
  const navigate = useNavigate();

  const dataFetchedRef = useRef(false);

  const dispatch = useAppDispatch();
  const remediationTrackerTasksState = useAppSelector(
    (state) => state.remediationTrackerTasksReducer
  );
  const authState = useAppSelector((state) => state.authReducer);

  const searchTimeout = useRef<NodeJS.Timeout | null>(null);

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: RemediationTrackerTasksPageParametersType = location.state;

  // Set Search Clear Icon
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.RemediationTrackerTasks,
      functionId,
      functionTypeId
    );
  };

  const listOfColumns: Array<ListColumn> = [
    // { ColumnName: "TaskName", ColumnDisplayName: "Task", ColumnHide: false },

    {
      ColumnId: "Identifier-16",
      ColumnName: "Identifier",
      ColumnDisplayName: "Identifier",
      ColumnHide: false,
      DisabledColumnHide: true,
    },
    {
      ColumnId: "LocationName-1",
      ColumnName: "LocationName",
      ColumnDisplayName: "Location",
      ColumnHide: false,
    },
    {
      ColumnId: "InspectionName-2",
      ColumnName: "InspectionName",
      ColumnDisplayName: "Inspection",
      ColumnHide: false,
      // DisabledColumnHide: false,
    },
    {
      ColumnId: "InspectionStandardName-3",
      ColumnName: "InspectionStandardName",
      ColumnDisplayName: "Standard",
      ColumnHide: false,
    },
    {
      ColumnId: "Q_No-4",
      ColumnName: "Q_No",
      ColumnDisplayName: "Q.No",
      ColumnHide: false,
      // DisabledColumnHide: false,
    },
    {
      ColumnId: "Piority-8",
      ColumnName: "Piority",
      ColumnDisplayName: "Priority",
      ColumnHide: false,
    },
    {
      ColumnId: "DueDate-9",
      ColumnName: "DueDate",
      ColumnDisplayName: "Due Date",
      ColumnHide: false,
    },
    {
      ColumnId: "Status-6",
      ColumnName: "Status",
      ColumnDisplayName: "Status",
      ColumnHide: false,
    },
    {
      ColumnId: "IsCapexRequired-11",
      ColumnName: "IsCapexRequired",
      ColumnDisplayName: "Is CAPEX Required",
      ColumnHide: true,
    },
    {
      ColumnId: "CapexAmountRequired-12",
      ColumnName: "CapexAmountRequired",
      ColumnDisplayName: "CAPEX Amount Required (M$)",
      ColumnHide: true,
    },
    {
      ColumnId: "Category-15",
      ColumnName: "Category",
      ColumnDisplayName: "Category",
      ColumnHide: true,
    },
    {
      ColumnId: "noOfTask-17",
      ColumnName: "noOfTask",
      ColumnDisplayName: "No.Of Task",
      ColumnHide: true,
    },
    {
      ColumnId: "pendingApprovers-18",
      ColumnName: "pendingApprovers",
      ColumnDisplayName: "Pending Approver(s)",
      ColumnHide: true,
    },
    // {
    //   ColumnId: "approver-10",
    //   ColumnName: "approver",
    //   ColumnDisplayName: "Approver",
    //   ColumnHide: true,
    // },
  ] as Array<ListColumn>;

  const getStatusColorClass = (
    status: EnumRemediationTrackerTaskStatus
  ): string => {
    let colorClass = "";

    if (status === EnumRemediationTrackerTaskStatus.New) {
      colorClass = "blue";
    } else if (status === EnumRemediationTrackerTaskStatus.PendingReviewSME) {
      colorClass = "purple";
    } else if (status === EnumRemediationTrackerTaskStatus.PendingReviewSite) {
      colorClass = "site";
    }
    // else if (status === EnumRemediationTrackerTaskStatus.ReviewCompleted) {
    //   colorClass = "not-started";
    // }
    else if (status === EnumRemediationTrackerTaskStatus.ActionInProgress) {
      colorClass = "yellow";
    } else if (
      status === EnumRemediationTrackerTaskStatus.PendingClosureApproval
    ) {
      colorClass = "pendding-closure";
    } else if (status === EnumRemediationTrackerTaskStatus.Closed) {
      colorClass = "green";
    } else if (status === EnumRemediationTrackerTaskStatus.Overdue) {
      colorClass = "red";
    } else if (status === EnumRemediationTrackerTaskStatus.AutoClosed) {
      colorClass = "auto-closed";
    } else if (status === EnumRemediationTrackerTaskStatus.ClosedAsOverdue) {
      colorClass = "overdue-closed";
    } else if (status === EnumRemediationTrackerTaskStatus.Cancelled) {
      colorClass = "remediation-cancelled";
    }

    return colorClass;
  };

  // Page onload
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    setRemediationTrackerTasksPageState(initialState);
    dispatch(
      remediationTrackerTasksReducerActions.resetRemediationTrackerTaskList()
    );

    // set onload page state

    const remediationTrackerTaskSearchParameter =
      //   parameter?.TaskSearchParameter;
      JSON.parse(localStorage.getItem("remediationTrackerTaskSearchParameter"));

    const remediationTrackerTaskColumnOptions: Array<ListColumn> = JSON.parse(
      localStorage.getItem("remediationTrackerTaskColumnOptions")
    );

    const initialStateWithSearchParameter = {
      ...initialState,
      Data: {
        ...initialState.Data,
        PageSize: remediationTrackerTaskSearchParameter
          ? remediationTrackerTaskSearchParameter?.ListPageSize
          : initialState.Data?.PageSize,
        SelectedPage: remediationTrackerTaskSearchParameter
          ? remediationTrackerTaskSearchParameter?.SelectedPage
          : initialState.Data?.SelectedPage,
      } as PaginationDTO,
      RemediationTrackerTaskSearchParameter:
        remediationTrackerTaskSearchParameter ?? {
          ...initialState.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...initialState.RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,

            Status:
              remediationTrackerTaskSearchParameter
                ?.RemediationTrackerTaskSearchParams?.Status ??
              EnumRemediationTrackerTaskStatus.New,
          },
        },
      ColumnOptions: remediationTrackerTaskColumnOptions ?? listOfColumns,
    } as RemediationTrackerTasksPageType;

    setRemediationTrackerTasksPageState({
      ...initialStateWithSearchParameter,
      RemediationTrackerTaskSearchParameter: {
        ...initialStateWithSearchParameter.RemediationTrackerTaskSearchParameter,
        FilterColumnWise: false,
      },
      IsFullScreen:
        parameter?.IsFullScreen ??
        remediationTrackerTasksPageState.IsFullScreen ??
        false,
      ColumnOptions: remediationTrackerTaskColumnOptions ?? listOfColumns,
    });

    // set column option
    dispatch(
      remediationTrackerTasksReducerActions.setRemediationTrackerTaskColumnOptions(
        remediationTrackerTaskColumnOptions ?? listOfColumns
      )
    );

    // dispatch(
    //   remediationTrackerTasksReducerActions.setRemediationTrackerTaskSearchParameter(
    //     initialStateWithSearchParameter?.RemediationTrackerTaskSearchParameter
    //   )
    // );

    dispatch(getRemediationTrackerTaskInitialData());

    dispatch(
      getRemediationTrackerTaskList({
        ...initialStateWithSearchParameter?.RemediationTrackerTaskSearchParameter,
        FilterColumnWise: false,
      })
    );

    //clear parameter
    navigate(location.pathname, { replace: true });
  }, []);

  //when reducer state change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        Data: {
          ...values.Data,
          Data: remediationTrackerTasksState?.Data?.Data,
          TotalRecordsCount:
            remediationTrackerTasksState?.Data?.TotalRecordsCount,
          SelectedPage: remediationTrackerTasksState?.Data?.SelectedPage,
          PageSize: remediationTrackerTasksState?.Data?.PageSize,
          StatusSummaryCount:
            remediationTrackerTasksState?.Data?.StatusSummaryCount,
        },
        RemediationTrackerTaskSearchParameter: {
          ...values?.RemediationTrackerTaskSearchParameter,
          IsAdvanceSearch:
            remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
              ?.IsAdvanceSearch,
          SelectedPage: remediationTrackerTasksState?.Data?.SelectedPage,
          ListPageSize: remediationTrackerTasksState?.Data?.PageSize,
        },
        TaskStatusSummaryCount:
          remediationTrackerTasksState?.TaskStatusSummaryCount,
        TaskFilteredSummary: remediationTrackerTasksState?.TaskFilteredSummary,
      };
    });
  }, [remediationTrackerTasksState]);

  // when selected task reducer state change enabled or disabled merge task between different Locations And Inspection
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    dispatch(
      remediationTrackerTasksReducerActions.setEnabledCreateMergeTaskButtonState()
    );
  }, [remediationTrackerTasksState.SelectedRemediationTrackerTask]);

  // add to local storage if serachparamter change
  useEffect(() => {
    localStorage.setItem(
      "remediationTrackerTaskSearchParameter",
      JSON.stringify(
        remediationTrackerTasksState.RemediationTrackerTaskSearchParameter
      )
    );
  }, [remediationTrackerTasksState.RemediationTrackerTaskSearchParameter]);

  // add to local storage if Column Options change
  useEffect(() => {
    localStorage.setItem(
      "remediationTrackerTaskColumnOptions",
      JSON.stringify(remediationTrackerTasksState.ColumnOptions)
    );
  }, [remediationTrackerTasksState.ColumnOptions]);

  //================================ Search ============================================================

  const onChangeSearchName = (searchValue: string) => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          BasicSearchValue: searchValue,
        },
      };
    });

    if (
      !searchValue &&
      !remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter
        ?.IsAdvanceSearch
    ) {
      setRemediationTrackerTasksPageState((values) => {
        return {
          ...values,
          RemediationTrackerTaskSearchParameter: {
            ...values.RemediationTrackerTaskSearchParameter,
            RemediationTrackerTaskSearchParams: {
              ...values.RemediationTrackerTaskSearchParameter
                .RemediationTrackerTaskSearchParams,
              SelectedLocationIdList: [] as Array<number>,
              SelectedStandardIdList: [] as Array<number>,
              SelectedPriorityIdList: [] as Array<number>,
              SelectedStatusIdList: [] as Array<number>,
              SelectedInspectionIdList: [] as Array<number>,
              SelectedActionOwnerIdList: [] as Array<string>,
              SelectedApproverIdList: [] as Array<number>,
              SelectedPendingApproverIdList: [] as Array<string>,
              SelectedIsCapexRequiredIdList: [] as Array<number>,
              SelectedCapexStatusIdList: [] as Array<number>,
              SelectedAreaOwnerTypeIdList: [] as Array<number>,
              TaskName: "",
              InspectionName: "",
              Q_No: "",
              ActionOwner: "",
              FromDueDate: null,
              ToDueDate: null,
              CapexamountRequired: null,
              CapexnumberAndName: "",
              Ernumber: "",
              StatusSummaryFilter: 0,
            },
            BasicSearchValue: "",
            IsAdvanceSearch: false,
          },
        };
      });

      dispatch(
        remediationTrackerTasksReducerActions.resetSelectedRemediationTrackerTask()
      );

      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...remediationTrackerTasksPageState
              .RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            SelectedLocationIdList: [] as Array<number>,
            SelectedStandardIdList: [] as Array<number>,
            SelectedPriorityIdList: [] as Array<number>,
            SelectedStatusIdList: [] as Array<number>,
            SelectedInspectionIdList: [] as Array<number>,
            SelectedActionOwnerIdList: [] as Array<string>,
            SelectedApproverIdList: [] as Array<number>,
            SelectedPendingApproverIdList: [] as Array<string>,
            SelectedIsCapexRequiredIdList: [] as Array<number>,
            SelectedCapexStatusIdList: [] as Array<number>,
            SelectedAreaOwnerTypeIdList: [] as Array<number>,
            TaskName: "",
            InspectionName: "",
            Q_No: "",
            ActionOwner: "",
            FromDueDate: null,
            ToDueDate: null,
            CapexamountRequired: null,
            CapexnumberAndName: "",
            Ernumber: "",
            StatusSummaryFilter: 0,
          },
          BasicSearchValue: "",
          IsAdvanceSearch: false,
          SelectedPage: 1,
          FilterColumnWise: false,
        })
      );
    }
  };

  const onChangeRemediationTrackerTaskSearchParameter = (
    isAdvanceSearch: boolean,
    selectedPage: number,
    pageSize: number,
    resetStatusSummarySelection: boolean
  ) => {
    if (isAdvanceSearch) {
      dispatch(
        remediationTrackerTasksReducerActions.setRemediationTrackerTaskSearchParameter(
          {
            ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
            IsAdvanceSearch: isAdvanceSearch,
            SelectedPage: selectedPage,
            ListPageSize: pageSize,
            FilterColumnWise: false,
          }
        )
      );

      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          IsAdvanceSearch: isAdvanceSearch,
          SelectedPage: selectedPage,
          ListPageSize: pageSize,
          FilterColumnWise: false,
        })
      );
    } else {
      dispatch(
        remediationTrackerTasksReducerActions.setRemediationTrackerTaskSearchParameter(
          {
            ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
            RemediationTrackerTaskSearchParams: {
              ...remediationTrackerTasksPageState
                .RemediationTrackerTaskSearchParameter
                .RemediationTrackerTaskSearchParams,
              SelectedLocationIdList: [] as Array<number>,
              SelectedStandardIdList: [] as Array<number>,
              SelectedPriorityIdList: [] as Array<number>,
              SelectedStatusIdList: [] as Array<number>,
              SelectedInspectionIdList: [] as Array<number>,
              SelectedActionOwnerIdList: [] as Array<string>,
              SelectedApproverIdList: [] as Array<number>,
              SelectedPendingApproverIdList: [] as Array<string>,
              SelectedIsCapexRequiredIdList: [] as Array<number>,
              SelectedCapexStatusIdList: [] as Array<number>,
              SelectedAreaOwnerTypeIdList: [] as Array<number>,
              TaskName: "",
              InspectionName: "",
              Q_No: "",
              ActionOwner: "",
              FromDueDate: null,
              ToDueDate: null,
              CapexamountRequired: null,
              CapexnumberAndName: "",
              Ernumber: "",
              StatusSummaryFilter: resetStatusSummarySelection
                ? 0
                : remediationTrackerTasksPageState
                    .RemediationTrackerTaskSearchParameter
                    .RemediationTrackerTaskSearchParams.StatusSummaryFilter,
            },
            IsAdvanceSearch: isAdvanceSearch,
            SelectedPage: selectedPage,
          }
        )
      );

      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...remediationTrackerTasksPageState
              .RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            SelectedLocationIdList: [] as Array<number>,
            SelectedStandardIdList: [] as Array<number>,
            SelectedPriorityIdList: [] as Array<number>,
            SelectedStatusIdList: [] as Array<number>,
            SelectedInspectionIdList: [] as Array<number>,
            SelectedActionOwnerIdList: [] as Array<string>,
            SelectedApproverIdList: [] as Array<number>,
            SelectedPendingApproverIdList: [] as Array<string>,
            SelectedIsCapexRequiredIdList: [] as Array<number>,
            SelectedCapexStatusIdList: [] as Array<number>,
            SelectedAreaOwnerTypeIdList: [] as Array<number>,
            TaskName: "",
            InspectionName: "",
            Q_No: "",
            ActionOwner: "",
            FromDueDate: null,
            ToDueDate: null,
            CapexamountRequired: null,
            CapexnumberAndName: "",
            Ernumber: "",
            StatusSummaryFilter: resetStatusSummarySelection
              ? 0
              : remediationTrackerTasksPageState
                  .RemediationTrackerTaskSearchParameter
                  .RemediationTrackerTaskSearchParams.StatusSummaryFilter,
          },
          IsAdvanceSearch: isAdvanceSearch,
          SelectedPage: selectedPage,
          ListPageSize: pageSize,
          FilterColumnWise: false,
        })
      );
    }
  };

  const onKeyPressSearchName = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();

      dispatch(
        remediationTrackerTasksReducerActions.resetSelectedRemediationTrackerTask()
      );

      if (
        remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter
          ?.BasicSearchValue !==
        remediationTrackerTasksState.RemediationTrackerTaskSearchParameter
          ?.BasicSearchValue
      ) {
        setRemediationTrackerTasksPageState((values) => {
          return {
            ...values,
            RemediationTrackerTaskSearchParameter: {
              ...values.RemediationTrackerTaskSearchParameter,
              RemediationTrackerTaskSearchParams: {
                ...remediationTrackerTasksPageState
                  .RemediationTrackerTaskSearchParameter
                  .RemediationTrackerTaskSearchParams,
                SelectedLocationIdList: [] as Array<number>,
                SelectedStandardIdList: [] as Array<number>,
                SelectedPriorityIdList: [] as Array<number>,
                SelectedStatusIdList: [] as Array<number>,
                SelectedInspectionIdList: [] as Array<number>,
                SelectedActionOwnerIdList: [] as Array<string>,
                SelectedApproverIdList: [] as Array<number>,
                SelectedPendingApproverIdList: [] as Array<string>,
                SelectedIsCapexRequiredIdList: [] as Array<number>,
                SelectedCapexStatusIdList: [] as Array<number>,
                SelectedAreaOwnerTypeIdList: [] as Array<number>,
                TaskName: "",
                InspectionName: "",
                Q_No: "",
                ActionOwner: "",
                FromDueDate: null,
                ToDueDate: null,
                CapexamountRequired: null,
                CapexnumberAndName: "",
                Ernumber: "",
                StatusSummaryFilter: 0,
              },
              IsAdvanceSearch: false,
            },
          };
        });

        onChangeRemediationTrackerTaskSearchParameter(
          false,
          1, //Default select 1st page,
          remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter
            ?.ListPageSize,
          true
        );
      }
    }
  };

  const serachBtnClick = (_IsAdvanceSearch: boolean) => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...remediationTrackerTasksPageState
              .RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            SelectedLocationIdList: [] as Array<number>,
            SelectedStandardIdList: [] as Array<number>,
            SelectedPriorityIdList: [] as Array<number>,
            SelectedStatusIdList: [] as Array<number>,
            SelectedInspectionIdList: [] as Array<number>,
            SelectedActionOwnerIdList: [] as Array<string>,
            SelectedApproverIdList: [] as Array<number>,
            SelectedPendingApproverIdList: [] as Array<string>,
            SelectedIsCapexRequiredIdList: [] as Array<number>,
            SelectedCapexStatusIdList: [] as Array<number>,
            SelectedAreaOwnerTypeIdList: [] as Array<number>,
            TaskName: "",
            InspectionName: "",
            Q_No: "",
            ActionOwner: "",
            FromDueDate: null,
            ToDueDate: null,
            CapexamountRequired: null,
            CapexnumberAndName: "",
            Ernumber: "",
            StatusSummaryFilter: 0,
          },
          IsAdvanceSearch: false,
        },
      };
    });

    dispatch(
      remediationTrackerTasksReducerActions.resetSelectedRemediationTrackerTask()
    );

    onChangeRemediationTrackerTaskSearchParameter(
      false,
      1, //Default select 1st page
      remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter
        ?.ListPageSize,
      true
    );
  };

  const onPaginationCallBack = (newPage: number, pageSize: number) => {
    if (
      remediationTrackerTasksState.RemediationTrackerTaskSearchParameter
        ?.FilterColumnWise &&
      (remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter
        .SelectedPage !== newPage ||
        remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter
          .ListPageSize !== pageSize)
    ) {
      dispatch(remediationTrackerTasksReducerActions.setDataLoading(true));
      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          SelectedPage: newPage,
          ListPageSize: pageSize,
          FilterColumnWise: true,
        })
      );
    } else if (
      remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter
        .ListPageSize !== pageSize
    ) {
      dispatch(
        remediationTrackerTasksReducerActions.setRemediationTrackerTaskSearchParameter(
          {
            ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
            SelectedPage: 1,
            ListPageSize: pageSize,
            FilterColumnWise: false,
          }
        )
      );

      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          SelectedPage: 1,
          ListPageSize: pageSize,
          FilterColumnWise: false,
        })
      );
    } else if (
      remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter
        .SelectedPage !== newPage
    ) {
      onChangeRemediationTrackerTaskSearchParameter(
        remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter
          ?.IsAdvanceSearch,
        newPage,
        pageSize,
        false
      );
    }
  };

  const handleLocationStandardDropDownListChange = (
    name: string,
    selectedValues: Array<string>
  ) => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            [name]:
              selectedValues && selectedValues?.length > 0
                ? selectedValues.map((item) => parseInt(item))
                : ([] as Array<number>),
          },
        },
      };
    });
  };

  const handleActionOwnerStringDropDownListChange = (
    name: string,
    selectOptions: Array<DropDownDTO>,
    selectedValues: Array<string>
  ) => {
    //reset Action Owner DropDown List because AD search
    dispatch(
      remediationTrackerTasksReducerActions.setDropDownList({
        ...remediationTrackerTasksState.DropDownListFilter,
        ActionOwnerDropDownList: selectOptions,
      })
    );

    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            [name]:
              selectedValues && selectedValues?.length > 0
                ? selectedValues.map((item) => item)
                : ([] as Array<string>),
          },
        },
        DropDownListFilter: {
          ...values.DropDownListFilter,
          ActionOwnerDropDownList: selectOptions,
        },
      };
    });
  };

  const handlePendingApproverStringDropDownListChange = (
    name: string,
    selectOptions: Array<DropDownDTO>,
    selectedValues: Array<string>
  ) => {
    //reset Pending Approver DropDown List because AD search
    dispatch(
      remediationTrackerTasksReducerActions.setDropDownList({
        ...remediationTrackerTasksState.DropDownListFilter,
        PendingApproverDropDownList: selectOptions,
      })
    );

    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            [name]:
              selectedValues && selectedValues?.length > 0
                ? selectedValues.map((item) => item)
                : ([] as Array<string>),
          },
        },
        DropDownListFilter: {
          ...values.DropDownListFilter,
          PendingApproverDropDownList: selectOptions,
        },
      };
    });
  };

  const onDueDateChange = (name: string, value: any) => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            [name]: value ? dayjs(value).format("YYYY-MM-DD") : null,
          },
        },
      };
    });
  };

  const onChangeRemediationTrackerTaskSortBy = (
    selectedSortState: SortDropDownDTO
  ) => {
    if (
      JSON.stringify(
        remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter
          ?.RemediationTrackerTaskSearchParams?.RemediationTrackerTaskSortBy
      ) === JSON.stringify(selectedSortState)
    )
      return;

    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            RemediationTrackerTaskSortBy: selectedSortState,
          },
        },
      };
    });

    if (
      remediationTrackerTasksState.RemediationTrackerTaskSearchParameter
        ?.FilterColumnWise
    ) {
      dispatch(remediationTrackerTasksReducerActions.setDataLoading(true));
      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...remediationTrackerTasksPageState
              .RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            RemediationTrackerTaskSortBy: selectedSortState,
          },
          SelectedPage: 1,
          FilterColumnWise: true,
        })
      );
    } else {
      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...remediationTrackerTasksPageState
              .RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            RemediationTrackerTaskSortBy: selectedSortState,
          },
          SelectedPage: 1,
          FilterColumnWise: false,
        })
      );
    }
  };

  const onclickRemoveAllFilters = () => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            SelectedLocationIdList: [] as Array<number>,
            SelectedStandardIdList: [] as Array<number>,
            SelectedPriorityIdList: [] as Array<number>,
            SelectedStatusIdList: [] as Array<number>,
            SelectedInspectionIdList: [] as Array<number>,
            SelectedActionOwnerIdList: [] as Array<string>,
            SelectedApproverIdList: [] as Array<number>,
            SelectedPendingApproverIdList: [] as Array<string>,
            SelectedIsCapexRequiredIdList: [] as Array<number>,
            SelectedCapexStatusIdList: [] as Array<number>,
            SelectedAreaOwnerTypeIdList: [] as Array<number>,
            TaskName: "",
            InspectionName: "",
            Q_No: "",
            ActionOwner: "",
            FromDueDate: null,
            ToDueDate: null,
            CapexamountRequired: null,
            CapexnumberAndName: "",
            Ernumber: "",
            StatusSummaryFilter: 0,
          },
          BasicSearchValue: "",
          IsAdvanceSearch: false,
          SelectedPage: 1,
        },
      };
    });

    dispatch(
      remediationTrackerTasksReducerActions.resetSelectedRemediationTrackerTask()
    );

    dispatch(
      getRemediationTrackerTaskList({
        ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
        ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
        RemediationTrackerTaskSearchParams: {
          ...remediationTrackerTasksPageState
            .RemediationTrackerTaskSearchParameter
            .RemediationTrackerTaskSearchParams,
          SelectedLocationIdList: [] as Array<number>,
          SelectedStandardIdList: [] as Array<number>,
          SelectedPriorityIdList: [] as Array<number>,
          SelectedStatusIdList: [] as Array<number>,
          SelectedInspectionIdList: [] as Array<number>,
          SelectedActionOwnerIdList: [] as Array<string>,
          SelectedApproverIdList: [] as Array<number>,
          SelectedPendingApproverIdList: [] as Array<string>,
          SelectedIsCapexRequiredIdList: [] as Array<number>,
          SelectedCapexStatusIdList: [] as Array<number>,
          SelectedAreaOwnerTypeIdList: [] as Array<number>,
          TaskName: "",
          InspectionName: "",
          Q_No: "",
          ActionOwner: "",
          FromDueDate: null,
          ToDueDate: null,
          CapexamountRequired: null,
          CapexnumberAndName: "",
          Ernumber: "",
          StatusSummaryFilter: 0,
        },
        BasicSearchValue: "",
        IsAdvanceSearch: false,
        SelectedPage: 1,
        FilterColumnWise: false,
      })
    );
  };

  const onclickRemoveAllAdvancedFilters = () => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            SelectedLocationIdList: [] as Array<number>,
            SelectedStandardIdList: [] as Array<number>,
            SelectedPriorityIdList: [] as Array<number>,
            SelectedStatusIdList: [] as Array<number>,
            SelectedInspectionIdList: [] as Array<number>,
            SelectedActionOwnerIdList: [] as Array<string>,
            SelectedApproverIdList: [] as Array<number>,
            SelectedPendingApproverIdList: [] as Array<string>,
            SelectedIsCapexRequiredIdList: [] as Array<number>,
            SelectedCapexStatusIdList: [] as Array<number>,
            SelectedAreaOwnerTypeIdList: [] as Array<number>,
            TaskName: "",
            InspectionName: "",
            Q_No: "",
            ActionOwner: "",
            FromDueDate: null,
            ToDueDate: null,
            CapexamountRequired: null,
            CapexnumberAndName: "",
            Ernumber: "",
          },
          IsAdvanceSearch: false,
        },
      };
    });

    dispatch(
      remediationTrackerTasksReducerActions.resetSelectedRemediationTrackerTask()
    );

    dispatch(
      getRemediationTrackerTaskList({
        ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
        ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
        RemediationTrackerTaskSearchParams: {
          ...remediationTrackerTasksPageState
            .RemediationTrackerTaskSearchParameter
            .RemediationTrackerTaskSearchParams,
          SelectedLocationIdList: [] as Array<number>,
          SelectedStandardIdList: [] as Array<number>,
          SelectedPriorityIdList: [] as Array<number>,
          SelectedStatusIdList: [] as Array<number>,
          SelectedInspectionIdList: [] as Array<number>,
          SelectedActionOwnerIdList: [] as Array<string>,
          SelectedApproverIdList: [] as Array<number>,
          SelectedPendingApproverIdList: [] as Array<string>,
          SelectedIsCapexRequiredIdList: [] as Array<number>,
          SelectedCapexStatusIdList: [] as Array<number>,
          SelectedAreaOwnerTypeIdList: [] as Array<number>,
          TaskName: "",
          InspectionName: "",
          Q_No: "",
          ActionOwner: "",
          FromDueDate: null,
          ToDueDate: null,
          CapexamountRequired: null,
          CapexnumberAndName: "",
          Ernumber: "",
        },
        SelectedPage: 1,
        IsAdvanceSearch: false,
        FilterColumnWise: false,
      })
    );
  };

  const onclickAdvanceSearchOpen = () => {
    setOpenAdvanceSearchModel(true);
  };

  const onclickAdvanceSearchClose = () => {
    //clear not apply filters
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter:
          remediationTrackerTasksState.RemediationTrackerTaskSearchParameter,
      };
    });

    setOpenAdvanceSearchModel(false);
  };

  const onclickAdvanceSearchbtn = () => {
    let stateObj = { ...remediationTrackerTasksPageState };

    stateObj.RemediationTrackerTaskSearchParameter.RemediationTrackerTaskSearchParams =
      {
        ...stateObj.RemediationTrackerTaskSearchParameter
          .RemediationTrackerTaskSearchParams,
        StatusSummaryFilter: 0,
      };

    dispatch(
      remediationTrackerTasksReducerActions.resetSelectedRemediationTrackerTask()
    );

    dispatch(
      getRemediationTrackerTaskList({
        ...stateObj.RemediationTrackerTaskSearchParameter,
        BasicSearchValue: "",
        SelectedPage: 1,
        IsAdvanceSearch: true,
        FilterColumnWise: false,
      })
    );

    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          BasicSearchValue: "",
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            StatusSummaryFilter: 0,
          },
        },
      };
    });

    setOpenAdvanceSearchModel(false);
  };

  const onSearchTextBoxChange = (name: string, newValue: string) => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            [name]: newValue || "",
          },
        },
      };
    });
  };

  const onclickRemediationTrackerTaskDetailsCallBack = (
    remediationTrackerTask: RemediationTrackerTaskDTO
  ) => {
    dispatch(
      getRemediationTrackerTaskDetails({
        ...remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter,
        RemediationTrackerTaskSearchParams: {
          ...remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
            ?.RemediationTrackerTaskSearchParams,
          Id: remediationTrackerTask.Id,
          InspectionQuestionId: remediationTrackerTask.InspectionQuestionId,
          IsMergeTask: remediationTrackerTask.IsMergeTask,
        },
      })
    );
  };

  const handleStatusSummaryClick = (status: number) => {
    let searchedValue =
      remediationTrackerTasksState.RemediationTrackerTaskSearchParameter
        .BasicSearchValue;
    // let stateObj = { ...remediationTrackerTasksPageState };

    // // Modify the new object
    // stateObj.RemediationTrackerTaskSearchParameter.RemediationTrackerTaskSearchParams =
    //   {
    //     ...stateObj.RemediationTrackerTaskSearchParameter
    //       .RemediationTrackerTaskSearchParams,
    //     StatusSummaryFilter: status,
    //   };

    let stateObj = {
      ...remediationTrackerTasksPageState,
      RemediationTrackerTaskSearchParameter: {
        ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
        RemediationTrackerTaskSearchParams: {
          ...remediationTrackerTasksPageState
            .RemediationTrackerTaskSearchParameter
            .RemediationTrackerTaskSearchParams,
          StatusSummaryFilter: status,
        },
      },
    };

    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            StatusSummaryFilter: status,
          },
        },
      };
    });

    dispatch(
      remediationTrackerTasksReducerActions.resetSelectedRemediationTrackerTask()
    );

    dispatch(
      getRemediationTrackerTaskList({
        ...stateObj.RemediationTrackerTaskSearchParameter,
        FilterColumnWise: false,
        SelectedPage: 1,
        RemediationTrackerTaskSearchParams: {
          ...stateObj.RemediationTrackerTaskSearchParameter
            .RemediationTrackerTaskSearchParams,
          StatusSummaryFilter: status,
        },
      })
    );
    if (!searchedValue || searchedValue?.trim() == "") {
      setRemediationTrackerTasksPageState((values) => {
        return {
          ...values,
          RemediationTrackerTaskSearchParameter: {
            ...values.RemediationTrackerTaskSearchParameter,
            BasicSearchValue: "",
          },
        };
      });
    }
  };

  //================================ End Search ============================================================

  //===================Callback function<Start>============================================================

  const onCheckAllCheckBoxClickedCallBack = (isChecked: boolean) => {
    dispatch(
      remediationTrackerTasksReducerActions.setSelectAllRemediationTrackerTask(
        isChecked
      )
    );
  };

  const onGridCheckBoxClickCallBack = (
    remediationTrackerTask: RemediationTrackerTaskDTO,
    IsChecked: boolean
  ) => {
    dispatch(
      remediationTrackerTasksReducerActions.setSelectedRemediationTrackerTaskIdList(
        {
          ...remediationTrackerTask,
          TaskIsChecked: IsChecked,
        } as RemediationTrackerTaskDTO
      )
    );

    dispatch(
      remediationTrackerTasksReducerActions.checkedRemediationTrackerTask({
        ...remediationTrackerTask,
        TaskIsChecked: IsChecked,
      } as RemediationTrackerTaskDTO)
    );
  };

  const onChildTaskCheckBoxClickCallBack = (
    parentTask: RemediationTrackerTaskDTO,
    childTask: RemediationTrackerTaskQuestionDTO,
    isChecked: boolean
  ) => {
    if (!parentTask?.IsMergeTask) {
      dispatch(
        remediationTrackerTasksReducerActions.setSelectedRemediationTrackerTaskIdList(
          {
            ...childTask.RemediationTrackerTask,
            TaskIsChecked: isChecked,
            FilteredRemediationTrackerTaskIdList: [
              childTask.RemediationTrackerTask.Id,
            ],
            ApproveOrRejectRemediationTrackerTaskIdList:
              childTask.RemediationTrackerTask
                ?.ApproveOrRejectRemediationTrackerTaskIdList || [],
          } as RemediationTrackerTaskDTO
        )
      );
    }

    dispatch(
      remediationTrackerTasksReducerActions.checkedChildRemediationTrackerTask({
        ...parentTask,
        Id: childTask.RemediationTrackerTask.Id,
        InspectionQuestionId: childTask.InspectionQuestion.Id,
        TaskIsChecked: isChecked,
      } as RemediationTrackerTaskDTO)
    );
  };

  const onclickCreateMergeNewTask = () => {
    let inspectionQuestionIdList: Array<number> = [];

    if (remediationTrackerTasksState.IsEnableCreateMergeTask) {
      inspectionQuestionIdList =
        remediationTrackerTasksState.SelectedRemediationTrackerTask?.filter(
          (filter) =>
            !filter.IsMergeTask &&
            filter.RemediationTrackerTaskIdList?.length === 1
        )?.map((item) => {
          return item.InspectionQuestionId;
        });

      navigate("/remediationTrackerTask", {
        state: {
          PageMode: EnumPageMode.AddMode,
          InspectionQuestionIdList: inspectionQuestionIdList,
          TaskSearchParameter:
            remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter,
          IsFullScreen: remediationTrackerTasksPageState.IsFullScreen,
          ColumnOptions: remediationTrackerTasksState.ColumnOptions ?? [],
        } as RemediationTrackerTaskPageParametersType,
      });

      // clear state
      dispatch(
        remediationTrackerTasksReducerActions.resetRemediationTrackerTaskList()
      );
    }
  };

  const onclickAddNewTaskCallBack = (task: RemediationTrackerTaskDTO) => {
    let inspectionQuestionIdList: Array<number> =
      // task?.RemediationTrackerTaskQuestions?.map((item) => {
      //   return item.InspectionQuestion?.Id;
      // }) ??

      [task.InspectionQuestionId];

    navigate("/remediationTrackerTask", {
      state: {
        PageMode: EnumPageMode.AddMode,
        InspectionQuestionIdList: inspectionQuestionIdList,
        TaskSearchParameter:
          remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter,
        IsFullScreen: remediationTrackerTasksPageState.IsFullScreen,
        ColumnOptions: remediationTrackerTasksState.ColumnOptions ?? [],
      } as RemediationTrackerTaskPageParametersType,
    });

    // clear state
    dispatch(
      remediationTrackerTasksReducerActions.resetRemediationTrackerTaskList()
    );
  };

  const onClickViewOrEditTaskCallBack = (
    taskId: number,
    _StatusId: EnumRemediationTrackerTaskStatus
  ) => {
    navigate("/remediationTrackerTask", {
      state: {
        PageMode: EnumPageMode.ViewMode,
        RemediationTrackerTaskId: taskId,
        TaskSearchParameter:
          remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter,
        IsFullScreen: remediationTrackerTasksPageState.IsFullScreen,
        ColumnOptions: remediationTrackerTasksState.ColumnOptions ?? [],
      } as RemediationTrackerTaskPageParametersType,
    });

    //clear state
    dispatch(
      remediationTrackerTasksReducerActions.resetRemediationTrackerTaskList()
    );
  };
  //-----standard list heigh ------
  // const remWrapper = useRef(null);
  // const searchRef = useRef(null);
  // const optionBar = useRef(null);
  // const pagination = useRef(null);

  //Set Hight
  // const [listHeight, setListHeight] = useState(0);
  // const [calendarHeight, setCalendarHeight] = useState(0);

  //-----Page load-----
  // useEffect(() => {
  //   let listHeight =
  //     wrapperCard.current?.innerHeight -
  //     searchRef.current?.offsetHeight -
  //     optionBar.current?.offsetHeight -
  //     pagination.current?.offsetHeight -
  //     20;
  //   setListHeight(listHeight);

  const deleteRemediationTrackerTaskCallBack = (
    remediationTrackerTaskQuestion: RemediationTrackerTaskQuestionDTO
  ) => {
    dispatch(deleteRemediationTrackerTask(remediationTrackerTaskQuestion));
  };

  const onClickRemoveFiltersCallBack = (
    filterField: TaskFilteredSummaryType
  ) => {
    if (filterField.Field === "DueDate") {
      setRemediationTrackerTasksPageState((values) => {
        return {
          ...values,
          RemediationTrackerTaskSearchParameter: {
            ...values.RemediationTrackerTaskSearchParameter,
            RemediationTrackerTaskSearchParams: {
              ...values.RemediationTrackerTaskSearchParameter
                .RemediationTrackerTaskSearchParams,
              FromDueDate: filterField.DefaultValue,
              ToDueDate: filterField.DefaultValue,
              StatusSummaryFilter: 0,
            },
            SelectedPage: 1,
          },
        };
      });

      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...remediationTrackerTasksPageState
              .RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            FromDueDate: filterField.DefaultValue,
            ToDueDate: filterField.DefaultValue,
            StatusSummaryFilter: 0,
          },
          SelectedPage: 1,
          FilterColumnWise: false,
        })
      );
    } else {
      setRemediationTrackerTasksPageState((values) => {
        return {
          ...values,
          RemediationTrackerTaskSearchParameter: {
            ...values.RemediationTrackerTaskSearchParameter,
            RemediationTrackerTaskSearchParams: {
              ...values.RemediationTrackerTaskSearchParameter
                .RemediationTrackerTaskSearchParams,
              [filterField.Field]: filterField.DefaultValue,
              StatusSummaryFilter: 0,
            },
            SelectedPage: 1,
          },
        };
      });

      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...remediationTrackerTasksPageState
              .RemediationTrackerTaskSearchParameter
              .RemediationTrackerTaskSearchParams,
            [filterField.Field]: filterField.DefaultValue,
            StatusSummaryFilter: 0,
          },
          SelectedPage: 1,
          FilterColumnWise: false,
        })
      );
    }

    dispatch(
      remediationTrackerTasksReducerActions.resetSelectedRemediationTrackerTask()
    );
  };

  const onClickRefreshBtnCallBack = () => {
    dispatch(remediationTrackerTasksReducerActions.setDataLoading(true));

    dispatch(
      getRemediationTrackerTaskList({
        ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
        FilterColumnWise: false,
        // SelectedPage: 1,
      })
    );
  };

  const onColumnFiltersCallBack = (columns: Array<ColumnFiltertypeDTO>) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    let identifier = "";
    let locationName = "";
    let inspectionName = "";
    let inspectionStandardName = "";
    let q_No = "";
    let piority = "";
    let dueDate = "";
    let status = "";
    let isCapexRequired = "";
    let capexAmountRequired = "";
    let category = "";
    let noOfTask = -1;
    let approver = "";
    let pendingApprovers = "";

    columns?.map((column) => {
      if (column.Column === "LocationName-1") {
        locationName = column.Value;
      } else if (column.Column === "InspectionName-2") {
        inspectionName = column.Value;
      } else if (column.Column === "InspectionStandardName-3") {
        inspectionStandardName = column.Value;
      } else if (column.Column === "Q_No-4") {
        q_No = column.Value;
      } else if (column.Column === "Status-6") {
        status = column.Value;
      } else if (column.Column === "Piority-8") {
        piority = column.Value;
      } else if (column.Column === "DueDate-9") {
        dueDate = column.Value;
      } else if (column.Column === "IsCapexRequired-11") {
        isCapexRequired = column.Value;
      } else if (column.Column === "CapexAmountRequired-12") {
        capexAmountRequired = column.Value;
      } else if (column.Column === "Category-15") {
        category = column.Value;
      } else if (column.Column === "Identifier-16") {
        identifier = column.Value;
      } else if (column.Column === "noOfTask-17") {
        noOfTask = column.Value ? parseInt(column.Value) : -1;
      } else if (column.Column === "pendingApprovers-18") {
        pendingApprovers = column.Value;
      }
    });

    const columnSearchParameter = {
      ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
      RemediationTrackerTaskSearchParams: {
        ...remediationTrackerTasksPageState
          .RemediationTrackerTaskSearchParameter
          .RemediationTrackerTaskSearchParams,
        Identifier: identifier,
        LocationName: locationName,
        InspectionName: inspectionName,
        InspectionStandardName: inspectionStandardName,
        ColumnFilterQ_No: q_No,
        PiorityNavigation: {
          PriorityName: "",
          Description: piority,
          RemediationTrackerTasks: [],
        },
        DueDateString: dueDate,
        StatusNavigation: {
          ...remediationTrackerTasksPageState
            .RemediationTrackerTaskSearchParameter
            .RemediationTrackerTaskSearchParams.StatusNavigation,
          Description: status,
        },
        IsCapexrequiredNavigation: {
          ...remediationTrackerTasksPageState
            .RemediationTrackerTaskSearchParameter
            .RemediationTrackerTaskSearchParams.IsCapexrequiredNavigation,
          TypeDescription: isCapexRequired,
        },
        ColumnFilterCapexamountRequired: capexAmountRequired,
        AreaOwnerNavigation: {
          ...remediationTrackerTasksPageState
            .RemediationTrackerTaskSearchParameter
            .RemediationTrackerTaskSearchParams.AreaOwnerNavigation,
          Description: category,
        },
        NoOfRemediationTrackerTaskCount: noOfTask,
        Approver: approver,
        PendingApprovers: pendingApprovers,
      },
    } as SearchParameterDTO;

    const isColumnWiseFilter = columns?.some(
      (column) =>
        column.Value !== undefined &&
        column.Value !== null &&
        column.Value !== ""
    );

    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: columnSearchParameter,
      };
    });

    if (isColumnWiseFilter) {
      dispatch(remediationTrackerTasksReducerActions.setDataLoading(true));

      searchTimeout.current = setTimeout(async () => {
        dispatch(
          getRemediationTrackerTasksColumnWiseFilter({
            ...columnSearchParameter,
            FilterColumnWise: isColumnWiseFilter,
          })
        );

        searchTimeout.current = null;
      }, 1000);
    } else if (
      remediationTrackerTasksState.RemediationTrackerTaskSearchParameter
        .FilterColumnWise
    ) {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
      searchTimeout.current = null;
      dispatch(remediationTrackerTasksReducerActions.setDataLoading(true));
      searchTimeout.current = null;
      dispatch(
        getRemediationTrackerTaskList({
          ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
          SelectedPage: 1,
          FilterColumnWise: false,
        })
      );
    }
  };

  const onIsFullScreenChangeCallBack = (isFullScreen: boolean) => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        IsFullScreen: isFullScreen,
      };
    });
  };

  const onChangeColumnOptionsCallBack = (columnOptions: Array<ListColumn>) => {
    dispatch(
      remediationTrackerTasksReducerActions.setRemediationTrackerTaskColumnOptions(
        columnOptions ?? []
      )
    );
  };

  //===================Callback function<End>============================================================

  const BacktnOnclick = () => {
    navigate("/");
  };

  const perPageList: Array<DropDownDTO> = [
    {
      Label: "5",
      Value: EnumListShowRowCountList.ListShowRowCountPerPage_1.toString(),
    },
    {
      Label: "10",
      Value: EnumListShowRowCountList.ListShowRowCountPerPage_2.toString(),
    },
    {
      Label: "15",
      Value: EnumListShowRowCountList.ListShowRowCountPerPage_3.toString(),
    },
    {
      Label: "20",
      Value: EnumListShowRowCountList.ListShowRowCountPerPage_4.toString(),
    },
    {
      Label: "25",
      Value: EnumListShowRowCountList.ListShowRowCountPerPage_5.toString(),
    },
  ];

  //sort by option list
  const sortByItemList = [
    {
      Label: "Location",
      Value: EnumRemediationTrackerTaskColumnType.Location.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Inspection",
      Value: EnumRemediationTrackerTaskColumnType.Inspection.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Standard",
      Value: EnumRemediationTrackerTaskColumnType.Standard.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Q.No",
      Value: EnumRemediationTrackerTaskColumnType.QuestionNumber.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Task Description",
      Value: EnumRemediationTrackerTaskColumnType.TaskName.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Status",
      Value: EnumRemediationTrackerTaskColumnType.Status.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Action Owner",
      Value: EnumRemediationTrackerTaskColumnType.ActionOwner.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Priority",
      Value: EnumRemediationTrackerTaskColumnType.Priority.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Due Date",
      Value: EnumRemediationTrackerTaskColumnType.DueDate.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    // {
    //   Label: "Approver",
    //   Value: EnumRemediationTrackerTaskColumnType.Approver.toString(),
    //   SortType: EnumSortTypes.Ascending,
    // },
    {
      Label: "Is CAPEX Required",
      Value: EnumRemediationTrackerTaskColumnType.IsCapexrequired.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "CAPEX Amount Required (M$)",
      Value:
        EnumRemediationTrackerTaskColumnType.CapexamountRequired.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "CAPEX Number And Name",
      Value: EnumRemediationTrackerTaskColumnType.CapexnumberAndName.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "CAPEX Status",
      Value: EnumRemediationTrackerTaskColumnType.Capexstatus.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Category", // "Area Owner",
      Value: EnumRemediationTrackerTaskColumnType.AreaOwner.toString(),
      SortType: EnumSortTypes.Ascending,
    },
    {
      Label: "Pending Approver(s)",
      Value: EnumRemediationTrackerTaskColumnType.PendingApprovers.toString(),
      SortType: EnumSortTypes.Ascending,
    },
  ];

  const showHideClearBtn = (): boolean => {
    if (
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedLocationIdList?.length !==
        0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedInspectionIdList
        ?.length !== 0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedStandardIdList?.length !==
        0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedActionOwnerIdList
        ?.length !== 0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedPriorityIdList?.length !==
        0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedStatusIdList?.length !==
        0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedActionOwnerIdList
        ?.length !== 0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedApproverIdList?.length !==
        0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedPendingApproverIdList
        ?.length !== 0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedIsCapexRequiredIdList
        ?.length !== 0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedCapexStatusIdList
        ?.length !== 0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.SelectedAreaOwnerTypeIdList
        ?.length !== 0 ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.Q_No ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.TaskName ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.FromDueDate ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.ToDueDate ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.CapexamountRequired ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.CapexnumberAndName ||
      remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter
        ?.RemediationTrackerTaskSearchParams?.Ernumber
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleFilterCheckboxChange = (name: string, checked: boolean) => {
    setRemediationTrackerTasksPageState((values) => {
      return {
        ...values,
        RemediationTrackerTaskSearchParameter: {
          ...values.RemediationTrackerTaskSearchParameter,
          RemediationTrackerTaskSearchParams: {
            ...values.RemediationTrackerTaskSearchParameter
              ?.RemediationTrackerTaskSearchParams,
            [name]: checked,
            StatusSummaryFilter: 0,
          },
        },
      };
    });

    dispatch(
      getRemediationTrackerTaskList({
        ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
        ...remediationTrackerTasksPageState.RemediationTrackerTaskSearchParameter,
        RemediationTrackerTaskSearchParams: {
          ...remediationTrackerTasksPageState
            .RemediationTrackerTaskSearchParameter
            .RemediationTrackerTaskSearchParams,
          [name]: checked,
          StatusSummaryFilter: 0,
        },
        SelectedPage: 1,
        FilterColumnWise: false,
      })
    );
  };

  // Approve selected task(s)
  const onclickApproveRemediationTrackerTask = () => {
    // dispatch(
    //   openPopup({
    //     Open: true,
    //     BodyText: "Are you sure you want to approve the selected  task(s)?",
    //     HeaderText: "Confirmation",
    //     PopupType: EnumPopupType.YesNoConfirmation,
    //     YesBtnClick() {
    dispatch(
      bulkApproveRemediationTrackerTask({
        SelectedRemediationTrackerTaskIdList:
          remediationTrackerTasksState.SelectedApproveRejectRemediationTrackerTaskIdList,
        CheckSameUserHasMultipleRole: true,
      } as BulkApproveRemediationTrackerTaskDTO)
    );
    //     },
    //   })
    // );
  };

  // reject popup confirm handle
  const handleRejectPopupYesClick = async (
    value: string,
    closePopup: () => void
  ) => {
    if (value.trim().length > 0) {
      const bulkRejectRemediationTrackerTaskWithComment = {
        SelectedRemediationTrackerTaskIdList:
          remediationTrackerTasksState.SelectedApproveRejectRemediationTrackerTaskIdList,
        RemediationTrackerTaskRejectReason: {
          Id: 0,
          Comment: value,
        } as RemediationTrackerTaskCommentDTO,
      } as BulkRejectRemediationTrackerTaskDTO; // create the new object including the comment

      dispatch(
        bulkRejectRemediationTrackerTask(
          bulkRejectRemediationTrackerTaskWithComment
        )
      );
      closePopup();
    } else {
      dispatch(
        updatePopupProps({
          ErrorMessage: "Please enter a reason for rejection",
        })
      );
    }
  };

  // Reject selected task(s)
  const onclickRejectRemediationTrackerTask = () => {
    dispatch(
      openPopup({
        Open: true,
        HeaderText: "Reason for Rejection",
        LableText: "Reason for Rejection",
        InputText: "",
        Required: true,
        CancelButtonText: "Discard",
        YesButtonText: "Confirm",
        PopupType: EnumPopupType.TextInputPopup,
        InputTextCount: 100,
        ActionBtnClick: (value, closePopup) => {
          handleRejectPopupYesClick(value, closePopup);
        },
      } as PopupProps)
    );
  };

  return (
    <div
      className="content-section-card rem-tracker top-main-padding"
      ref={wrapperCard}
    >
      <Grid container>
        <div
          className="flex--container fx-wrap width100-p top-main-border mb-10 rem-btn-wrap"
          ref={searchRef}
        >
          <div className="flex__item--inherit pr-10 pl-10">
            <h2>
              <Button
                aria-label="back"
                className="sub-head-back-btn"
                onClick={BacktnOnclick}
                title="Back to home page"
              >
                {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>
            </h2>
          </div>
          <div
            className="global-header-wrap rem-trak-head-res"
            ref={mainHeading}
          >
            <h1>Remediation Tracker</h1>
          </div>
          <div className="flex__item search-bar--small mr-10 pr-20 fx-around">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <InputBase
                value={
                  remediationTrackerTasksPageState
                    ?.RemediationTrackerTaskSearchParameter?.BasicSearchValue ??
                  ""
                }
                onChange={(event) => {
                  onChangeSearchName(event.target?.value);
                }}
                onKeyPress={onKeyPressSearchName}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Remediation Tasks"
                inputProps={{ "aria-label": "TaskName" }}
                // type="search"
              />

              {isMouseOver &&
                remediationTrackerTasksPageState
                  ?.RemediationTrackerTaskSearchParameter?.BasicSearchValue &&
                remediationTrackerTasksPageState
                  ?.RemediationTrackerTaskSearchParameter?.BasicSearchValue
                  ?.length > 0 && (
                  <Tooltip title="Clear" placement={"left-end"}>
                    <IconButton
                      className="mr-0"
                      onClick={() => onChangeSearchName("")}
                    >
                      <CloseIcon
                        style={{ color: "#219ebc", fontSize: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              <Tooltip title="Search" placement={"left-end"}>
                <IconButton
                  title="Search"
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="m-0"
                  onClick={() => {
                    serachBtnClick(false);
                  }}
                >
                  <SearchNormal1
                    variant="Outline"
                    className="ico-secondary"
                    size={18}
                  />
                </IconButton>
              </Tooltip>
              <Button
                className="ternery-btn-small adv-filter-btn"
                disableElevation
                size="small"
                variant="outlined"
                startIcon={<Filter variant="Outline" size={20} />}
                onClick={onclickAdvanceSearchOpen}
              >
                Advanced filters
              </Button>

              {showHideClearBtn() &&
                remediationTrackerTasksPageState.TaskFilteredSummary?.length >
                  0 && (
                  <Button
                    onClick={onclickRemoveAllFilters}
                    className={`danger-btn-small `}
                    disableElevation
                    size="small"
                    variant="outlined"
                    startIcon={<BrushBig variant="Outline" />}
                  >
                    Clear filters
                  </Button>
                )}
            </Paper>
          </div>

          {!(remediationTrackerTasksPageState.IsFullScreen ?? false) && (
            <div className="flex__item--inherit text-right sub-header-btn-wrap pr-10 pl-10">
              {(checkSystemFunctionRight(
                EnumSystemFunctions.RemTrackerTaskPage_Reject_For_SME_Review,
                EnumSystemFunctionTypes.ButtonAccess
              ) ||
                checkSystemFunctionRight(
                  EnumSystemFunctions.RemTrackerTaskPage_Reject_For_Site_Review,
                  EnumSystemFunctionTypes.ButtonAccess
                ) ||
                checkSystemFunctionRight(
                  EnumSystemFunctions.RemTrackerTaskPage_Reject_For_Closure,
                  EnumSystemFunctionTypes.ButtonAccess
                )) &&
                remediationTrackerTasksState
                  .SelectedApproveRejectRemediationTrackerTaskIdList?.length >
                  0 && (
                  <Tooltip title="Approve selected task(s)">
                    <Button
                      className="primary-btn-small"
                      disableElevation
                      variant="outlined"
                      startIcon={<Slash variant="Outline" size={20} />}
                      onClick={onclickRejectRemediationTrackerTask}
                      // disabled={
                      //   props?.ViewRemediationTrackerTaskTableToolBarButtonProps
                      //     ?.DisabledRejectButton
                      // }
                    >
                      Reject
                    </Button>
                  </Tooltip>
                )}

              {(checkSystemFunctionRight(
                EnumSystemFunctions.RemTrackerTaskPage_Approval_For_SME_Review,
                EnumSystemFunctionTypes.ButtonAccess
              ) ||
                checkSystemFunctionRight(
                  EnumSystemFunctions.RemTrackerTaskPage_Approval_For_Site_Review,
                  EnumSystemFunctionTypes.ButtonAccess
                ) ||
                checkSystemFunctionRight(
                  EnumSystemFunctions.RemTrackerTaskPage_Approval_For_Closure,
                  EnumSystemFunctionTypes.ButtonAccess
                )) &&
                remediationTrackerTasksState
                  .SelectedApproveRejectRemediationTrackerTaskIdList?.length >
                  0 && (
                  <Tooltip title="Reject selected task(s)">
                    <Button
                      className="primary-btn-small"
                      disableElevation
                      variant="outlined"
                      startIcon={<TickCircle variant="Outline" size={20} />}
                      onClick={onclickApproveRemediationTrackerTask}
                      // disabled={
                      //   props?.ViewRemediationTrackerTaskTableToolBarButtonProps
                      //     ?.DisabledApproveButton
                      // }
                    >
                      Approve
                    </Button>
                  </Tooltip>
                )}
            </div>
          )}
        </div>
        {/* <Grid container>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      remediationTrackerTasksPageState
                        .RemediationTrackerTaskSearchParameter
                        .RemediationTrackerTaskSearchParams
                        .ShowTasksAssignedToMe
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFilterCheckboxChange(
                        "ShowTasksAssignedToMe",
                        event.target.checked
                      )
                    }
                  />
                }
                label="Tasks(s) Assigned to me"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      remediationTrackerTasksPageState
                        .RemediationTrackerTaskSearchParameter
                        .RemediationTrackerTaskSearchParams
                        .ShowTasksPendingMyApproval
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFilterCheckboxChange(
                        "ShowTasksPendingMyApproval",
                        event.target.checked
                      )
                    }
                  />
                }
                label="Tasks(s) Pending my approval"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      remediationTrackerTasksPageState
                        .RemediationTrackerTaskSearchParameter
                        .RemediationTrackerTaskSearchParams.ShowCompletedTasks
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFilterCheckboxChange(
                        "ShowCompletedTasks",
                        event.target.checked
                      )
                    }
                  />
                }
                label="Completed Tasks(s)"
              />
            </FormGroup>
          </Grid> */}
        <Grid container></Grid>
        {/* <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="pt-0 justify-right"
          ></Grid>
        </Grid> */}
        {/* filter strat */}
        {/* <div className="main-content-inner"> */}
        {remediationTrackerTasksPageState?.TaskFilteredSummary?.length > 0 && (
          <div
            className="flex--container advance-filter-token mb-10 pl-10"
            ref={advanceFilter}
          >
            <span className="body5 mr-5">Filtered condition(s):</span>
            {remediationTrackerTasksPageState?.TaskFilteredSummary?.map(
              (item) => {
                return (
                  <div className="filter-icon" key={item.Label}>
                    <div
                      className="flex__item--inherit mr-5"
                      style={{ display: "inline-flex" }}
                    >
                      <span className="body5 mr-5 no-wrap">{item.Label}:</span>
                      <Tooltip title={item.Value}>
                        <span className="truncate" style={{ maxWidth: 135 }}>
                          {item.Value}
                        </span>
                      </Tooltip>
                    </div>
                    <IconButton
                      className="icon-btn-close"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickRemoveFiltersCallBack(item);
                      }}
                    >
                      <CloseCircle size="15"></CloseCircle>
                    </IconButton>
                  </div>
                );
              }
            )}
          </div>
        )}
        {/* filter end */}
        {/* check box filter strt */}
        {/* <Grid container ref={checkboxFilter}>
          <div className="checkbox-filter">
            <HPRCheckBox
              Id={"ShowTasksAssignedToMe"}
              Name={"ShowTasksAssignedToMe"}
              Label={"Show Task(s) assigned to me"}
              checked={
                remediationTrackerTasksPageState
                  .RemediationTrackerTaskSearchParameter
                  .RemediationTrackerTaskSearchParams.ShowTasksAssignedToMe
              }
              onCheckBoxChange={handleFilterCheckboxChange}
              ClassName={"inline-single-element"}
            ></HPRCheckBox>
            <HPRCheckBox
              Id={"ShowTasksPendingMyApproval"}
              Name={"ShowTasksPendingMyApproval"}
              Label={"Show Task(s) pending my approval"}
              checked={
                remediationTrackerTasksPageState
                  .RemediationTrackerTaskSearchParameter
                  .RemediationTrackerTaskSearchParams.ShowTasksPendingMyApproval
              }
              onCheckBoxChange={handleFilterCheckboxChange}
              ClassName={"inline-single-element"}
            ></HPRCheckBox>
            <HPRCheckBox
              Id={"ShowCompletedTasks"}
              Name={"ShowCompletedTasks"}
              Label={"Show Closed Task(s)"}
              checked={
                remediationTrackerTasksPageState
                  .RemediationTrackerTaskSearchParameter
                  .RemediationTrackerTaskSearchParams.ShowCompletedTasks
              }
              onCheckBoxChange={handleFilterCheckboxChange}
              ClassName={"inline-single-element"}
            ></HPRCheckBox>
          </div>
        </Grid> */}
        {/* check box filter end */}
        {/* Status */}

        <Grid
          container
          style={{
            visibility: `${
              remediationTrackerTasksPageState?.Data?.Data?.length > 0
                ? "visible"
                : "hidden"
            }`,
          }}
          ref={statusFilter}
          className="pl-10 pt-5"
        >
          {remediationTrackerTasksPageState?.TaskStatusSummaryCount?.filter(
            (filter) => filter.NoOfTaskCount > 0
          ).length > 0 && (
            <div
              className={`standart-status-change mb-5 d-flex`}
              style={{
                borderRight: "unset",
                borderLeft: "unset",
                zIndex: "10",
              }}
            >
              {remediationTrackerTasksPageState?.TaskStatusSummaryCount?.filter(
                (filter) => filter.NoOfTaskCount > 0
              ).map((item) => {
                return (
                  <div
                    className="standards-status"
                    key={`Status_${item.Id}`}
                    onClick={() => {
                      handleStatusSummaryClick(item.Id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className={`status-color ${
                        item.Id === 0 ? "common" : getStatusColorClass(item.Id)
                      } ${
                        item.Id ===
                        remediationTrackerTasksPageState
                          .RemediationTrackerTaskSearchParameter
                          ?.RemediationTrackerTaskSearchParams
                          ?.StatusSummaryFilter
                          ? "active"
                          : ""
                      }`}
                    >
                      {item.Description}
                      {item.NoOfTaskCount || item.NoOfTaskCount === 0
                        ? ` (${item.NoOfTaskCount})`
                        : ""}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </Grid>

        {/* Status*/}
        <Grid container>
          {gridHeight > 0 && (
            <Grid
              item
              xs={12}
              style={{ height: gridHeight }}
              className="grid-height pl-10 pr-10"
            >
              <ViewRemediationTrackerTaskTable
                GridHeight={gridHeight}
                SearchValue={
                  remediationTrackerTasksPageState
                    ?.RemediationTrackerTaskSearchParameter?.BasicSearchValue ??
                  ""
                }
                AdvanceFilterHeight={advanceFilterHeight}
                RemediationTrackerTaskList={
                  remediationTrackerTasksPageState?.Data?.Data
                }
                ShowDataLoading={remediationTrackerTasksState.DataLoading}
                IsFullScreen={
                  remediationTrackerTasksPageState.IsFullScreen ?? false
                }
                FilterColumnWise={
                  remediationTrackerTasksState
                    .RemediationTrackerTaskSearchParameter?.FilterColumnWise ??
                  false
                }
                ClearExpandedRows={
                  remediationTrackerTasksState?.ClearExpandedRows
                }
                PaginationProps={
                  {
                    TotalRecordsCount:
                      remediationTrackerTasksPageState?.Data?.TotalRecordsCount,
                    PageSize: remediationTrackerTasksState.Data?.PageSize,
                    SelectedPage:
                      remediationTrackerTasksState.Data?.SelectedPage,
                    // TaskStatusSummaryCount:
                    //   remediationTrackerTasksPageState.TaskStatusSummaryCount,
                    // TaskFilteredSummary:
                    //   remediationTrackerTasksPageState?.TaskFilteredSummary,
                    onPaginationChangeCallBack: onPaginationCallBack,
                  } as ViewRemediationTrackerTaskListPagination
                }
                ViewRemediationTrackerTaskListToolBar={
                  {
                    // ViewRemediationTrackerTaskListPerOptions: {
                    //   Id: "",
                    //   Name: "",
                    //   defaultValue: 15,
                    //   PerPageItemList: perPageList,
                    //   onChangePerPage: onChangePageSize,
                    // } as ViewRemediationTrackerTaskListPerPageOptionsProps,
                    ViewRemediationTrackerTaskListSortByOptions: {
                      SortByItemList: sortByItemList,
                      // InitialSortByState: {
                      //   Label: "Location",
                      //   Value:
                      //     EnumRemediationTrackerTaskColumnType.Location.toString(),
                      //   SortType: EnumSortTypes.Ascending,
                      // },
                      InitialSortByState:
                        remediationTrackerTasksPageState
                          ?.RemediationTrackerTaskSearchParameter
                          ?.RemediationTrackerTaskSearchParams
                          ?.RemediationTrackerTaskSortBy,
                      onChangeSortBy: onChangeRemediationTrackerTaskSortBy,
                    },
                    ViewRemediationTrackerTaskListToolbarReportProps: {
                      ReportViewer: {
                        ReportSearchParameter: {
                          ...remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter,
                          FilterColumnWise: false,
                        },
                        IsReportDataFound:
                          remediationTrackerTasksPageState?.Data?.Data?.length >
                          0,
                      },
                      ShowReport: checkSystemFunctionRight(
                        EnumSystemFunctions.RemTrackerTasksPage_Export,
                        EnumSystemFunctionTypes.ButtonAccess
                      ),
                    },
                    ViewRemediationTrackerTaskListToolbarColumnProps: {
                      ShowColumnOption: true,
                      ListColumns:
                        remediationTrackerTasksState.ColumnOptions ??
                        listOfColumns,
                    },
                    ViewRemediationTrackerTaskTableToolBarButtonProps: {
                      HideApproveButton:
                        remediationTrackerTasksState
                          .SelectedApproveRejectRemediationTrackerTaskIdList
                          ?.length === 0 ||
                        !(
                          remediationTrackerTasksPageState.IsFullScreen ?? false
                        ),
                      HideRejectButton:
                        remediationTrackerTasksState
                          .SelectedApproveRejectRemediationTrackerTaskIdList
                          ?.length === 0 ||
                        !(
                          remediationTrackerTasksPageState.IsFullScreen ?? false
                        ),
                      HideCreateMergeTaskButton:
                        !remediationTrackerTasksState.IsEnableCreateMergeTask,
                      onClickApproveButton:
                        onclickApproveRemediationTrackerTask,
                      onClickRejectButton: onclickRejectRemediationTrackerTask,
                      onClickCreateMergeTaskButton: onclickCreateMergeNewTask,
                    },
                    FilterCheckboxsProps: {
                      CheckedTasksAssignedToMe:
                        remediationTrackerTasksPageState
                          .RemediationTrackerTaskSearchParameter
                          .RemediationTrackerTaskSearchParams
                          .ShowTasksAssignedToMe,
                      CheckedTasksPendingMyApproval:
                        remediationTrackerTasksPageState
                          .RemediationTrackerTaskSearchParameter
                          .RemediationTrackerTaskSearchParams
                          .ShowTasksPendingMyApproval,
                      CheckedCompletedTasks:
                        remediationTrackerTasksPageState
                          .RemediationTrackerTaskSearchParameter
                          .RemediationTrackerTaskSearchParams
                          .ShowCompletedTasks,
                      CheckedCancelledTasks:
                        remediationTrackerTasksPageState
                          .RemediationTrackerTaskSearchParameter
                          .RemediationTrackerTaskSearchParams
                          .ShowCancelledTasks,
                      onFilterCheckboxChange: handleFilterCheckboxChange,
                    },
                  } as ViewRemediationTrackerTaskListToolBarProps
                }
                CallbackFunction={{
                  onClickViewOrEditTask: onClickViewOrEditTaskCallBack,
                  onClickRefreshBtnCallBack: onClickRefreshBtnCallBack,
                  onCheckBoxClicked: onGridCheckBoxClickCallBack,
                  onChildTaskCheckBoxClicked: onChildTaskCheckBoxClickCallBack,
                  onclickRemediationTrackerTaskDetails:
                    onclickRemediationTrackerTaskDetailsCallBack,
                  onDeleteRemediationTrackerTask:
                    deleteRemediationTrackerTaskCallBack,
                  onclickAddNewTaskCallBack: onclickAddNewTaskCallBack,
                  onChangeSortByCallBack: onChangeRemediationTrackerTaskSortBy,
                  onColumnFiltersCallBack: onColumnFiltersCallBack,
                  onChangeFullScreenGlobalFilterCallBack: (searchValue) => {
                    onChangeSearchName(searchValue);
                  },
                  onKeyPressFullScreenGlobalFilterCallBack:
                    onKeyPressSearchName,
                  onFullScreenGlobalFilterBtnClickCallBack() {
                    serachBtnClick(false);
                  },
                  onIsFullScreenChangeCallBack: onIsFullScreenChangeCallBack,
                  onCheckAllCheckBoxClickedCallBack:
                    onCheckAllCheckBoxClickedCallBack,
                  onChangeColumnOptions: onChangeColumnOptionsCallBack,
                }}
              />

              {/* <ViewRemediationTrackerTaskList
                  GridHeight={gridHeight}
                  RemediationTrackerTaskList={
                    remediationTrackerTasksPageState?.Data?.Data
                  }
                  PaginationProps={
                    {
                      TotalRecordsCount:
                        remediationTrackerTasksPageState?.Data
                          ?.TotalRecordsCount,
                      PageSize: remediationTrackerTasksPageState.Data?.PageSize,
                      SelectedPage:
                        remediationTrackerTasksPageState.Data?.SelectedPage,
                      TaskStatusSummaryCount:
                        remediationTrackerTasksPageState.TaskStatusSummaryCount,
                      TaskFilteredSummary:
                        remediationTrackerTasksPageState?.TaskFilteredSummary,
                      onPaginationChangeCallBack: onPaginationCallBack,
                    } as ViewRemediationTrackerTaskListPagination
                  }
                  CallbackFunction={
                    {
                      onClickViewOrEditTask: onClickViewOrEditTaskCallBack,
                      onCheckBoxClicked: onGridCheckBoxClicked,
                      onclickAddNewTaskCallBack: onclickAddNewTaskCallBack,
                      onclickRemediationTrackerTaskDetails:
                        onclickRemediationTrackerTaskDetailsCallBack,
                      onDeleteRemediationTrackerTask:
                        deleteRemediationTrackerTaskCallBack,
                      onClickRemoveFiltersCallBack:
                        onClickRemoveFiltersCallBack,
                    } as ViewRemediationTrackerTaskListCallBackFunctionType
                  }
                  HideAddTaskBtn={
                    remediationTrackerTasksState.SelectedRemediationTrackerTask
                      ?.length >= 1
                  }
                  ViewRemediationTrackerTaskListToolBar={
                    {
                      ViewRemediationTrackerTaskListPerOptions: {
                        Id: "",
                        Name: "",
                        defaultValue: 15,
                        PerPageItemList: perPageList,
                        onChangePerPage: onChangePageSize,
                      } as ViewRemediationTrackerTaskListPerPageOptionsProps,
                      ViewRemediationTrackerTaskListSortByOptions: {
                        SortByItemList: sortByItemList,
                        InitialSortByState: {
                          Label: "Location",
                          Value:
                            EnumRemediationTrackerTaskColumnType.Location.toString(),
                          SortType: EnumSortTypes.Ascending,
                        },
                        onChangeSortBy: onChangeRemediationTrackerTaskSortBy,
                      },

                      ViewRemediationTrackerTaskListToolbarReportProps: {
                        ReportViewer: {
                          ReportSearchParameter:
                            remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter,
                          IsReportDataFound:
                            remediationTrackerTasksPageState?.Data?.Data
                              ?.length > 0,
                        },
                        ShowReport: checkSystemFunctionRight(
                          EnumSystemFunctions.RemTrackerTasksPage_Export,
                          EnumSystemFunctionTypes.ButtonAccess
                        ),

                        // remediationTrackerTasksPageState?.Data?.Data?.length >
                        // 0,
                      },

                      ViewRemediationTrackerTaskListToolbarColumnProps: {
                        // ShowColumnOption: true,
                        ListColumns: listOfColumns,
                      },
                    } as ViewRemediationTrackerTaskListToolBarProps
                  }
                /> */}
            </Grid>
          )}
        </Grid>
        {/* </div> */}
        {/* <Grid container>
            {gridHeight && gridHeight > 0 && (
              <Grid item xs={12} style={{ height: gridHeight }}>
                <HPRDataGrid
                  Columns={columns}
                  Data={remediationTrackerTasksPageState?.Data?.Data}
                  TotalRecordsCount={
                    remediationTrackerTasksPageState?.Data?.TotalRecordsCount
                  }
                  // Height={66}
                  // AutoHeight
                  onPaginationChangeCallBack={onPaginationCallBack}
                  SelectedPage={
                    remediationTrackerTasksPageState.Data?.SelectedPage
                  }
                  MenuFunctions={{
                    onChangeSortBy: onChangeRemediationTrackerTaskSortBy,
                  }}
                  showMenu={true}
                  GridPagination={{
                    defaultValue:
                      remediationTrackerTasksPageState.Data?.PageSize,
                    onChangePerPage: onChangePageSize,
                    HidePerPage: false,
                    PerPageItemList: [
                      {
                        Label: "5",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_1.toString(),
                      },
                      {
                        Label: "10",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_2.toString(),
                      },
                      {
                        Label: "15",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_3.toString(),
                      },
                      {
                        Label: "20",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_4.toString(),
                      },
                      {
                        Label: "25",
                        Value:
                          EnumListShowRowCountList.ListShowRowCountPerPage_5.toString(),
                      },
                    ] as DropDownDTO[],
                  }}
                  PageSize={remediationTrackerTasksPageState.Data?.PageSize}
                  HPRDataGridToolbarProps={
                    {
                      HPRDataGridToolbarColumnProps: {
                        ShowColumnOption: true,
                      } as HPRDataGridToolbarColumnProps,
                      HPRDataGridToolbarReportProps: {
                        ShowReport: true,
                        ReportType:
                          EnumReportType.RemediationTrackerTasksReport,
                        ReportViewer: {
                          ReportType:
                            EnumReportType.RemediationTrackerTasksReport,
                          ReportSearchParameter:
                            remediationTrackerTasksState?.RemediationTrackerTaskSearchParameter,
                        },
                      } as HPRDataGridToolbarReportProps,
                      ShowTotalRecordsCount: true,
                    } as HPRDataGridToolbarProps
                  }
                ></HPRDataGrid>
              </Grid>
            )}
          </Grid> */}

        <Modal
          className="modal-outter sub-form-section"
          open={openAdvanceSearchModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          //onClose={onclickAdvanceSearchClose}
        >
          <Box className="advance-search-modal" sx={style}>
            <div className="modal-header">
              <h1>Advanced filters</h1>
            </div>
            <div
              className="modal-content flex-wrap mt-10"
              style={{ height: "auto" }}
            >
              <Grid container>
                <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  {/* 1st row */}
                  <Grid container spacing={2} className="fx-align-center">
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMultipleSelect
                          Id="SelectedLocationIdList"
                          Name="SelectedLocationIdList"
                          Label="Location"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedLocationIdList?.map(
                            (item) => item.toString()
                          )}
                          Items={
                            remediationTrackerTasksState?.DropDownListFilter
                              ?.LocationDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleLocationStandardDropDownListChange(
                              "SelectedLocationIdList",
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMultipleSelect
                          Id="SelectedInspectionIdList"
                          Name="SelectedInspectionIdList"
                          Label="Inspection Name"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedInspectionIdList?.map(
                            (item) => item.toString()
                          )}
                          Items={
                            remediationTrackerTasksState?.DropDownListFilter
                              ?.InspectionDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleLocationStandardDropDownListChange(
                              "SelectedInspectionIdList",
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMultipleSelect
                          Id="SelectedStandardIdList"
                          Name="SelectedStandardIdList"
                          Label="Standard Name"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedStandardIdList?.map(
                            (item) => item.toString()
                          )}
                          Items={
                            remediationTrackerTasksState?.DropDownListFilter
                              ?.StandardDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleLocationStandardDropDownListChange(
                              "SelectedStandardIdList",
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRTextBox
                          Id="Q_No"
                          Name="Q_No"
                          Label="Q.No"
                          Value={
                            remediationTrackerTasksPageState
                              ?.RemediationTrackerTaskSearchParameter
                              ?.RemediationTrackerTaskSearchParams?.Q_No
                          }
                          Type={EnumTextBoxType.Search}
                          onTextBoxChange={onSearchTextBoxChange}
                          TextLength={5}
                          InputProps={{ maxLength: 5 }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  {/* 2nd row */}
                  <Grid container spacing={2} className="fx-align-center">
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRTextBox
                          Id="TaskName"
                          Name="TaskName"
                          Label="Task Description"
                          Value={
                            remediationTrackerTasksPageState
                              ?.RemediationTrackerTaskSearchParameter
                              ?.RemediationTrackerTaskSearchParams?.TaskName
                          }
                          Type={EnumTextBoxType.Search}
                          onTextBoxChange={onSearchTextBoxChange}
                          TextLength={750}
                          InputProps={{ maxLength: 750 }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMsGraphMultipleSelect
                          Id="SelectedActionOwnerIdList"
                          Name="SelectedActionOwnerIdList"
                          Label="Task Action Owner"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedActionOwnerIdList?.map(
                            (item) => item
                          )}
                          Url="RemediationTrackerTasks/GetActionOwnerListForDropDown"
                          Items={
                            remediationTrackerTasksPageState?.DropDownListFilter
                              ?.ActionOwnerDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleActionOwnerStringDropDownListChange(
                              "SelectedActionOwnerIdList",
                              selectOptions,
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMultipleSelect
                          Id="SelectedStatusIdList"
                          Name="SelectedStatusIdList"
                          Label="Task Status"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedStatusIdList?.map(
                            (item) => item.toString()
                          )}
                          Items={
                            remediationTrackerTasksState?.DropDownListFilter
                              ?.StatusDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleLocationStandardDropDownListChange(
                              "SelectedStatusIdList",
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMultipleSelect
                          Id="SelectedPriorityIdList"
                          Name="SelectedPriorityIdList"
                          Label="Task Priority"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedPriorityIdList?.map(
                            (item) => item.toString()
                          )}
                          Items={
                            remediationTrackerTasksState?.DropDownListFilter
                              ?.PriorityDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleLocationStandardDropDownListChange(
                              "SelectedPriorityIdList",
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>
                  </Grid>

                  {/* 3rd row */}
                  <Grid container spacing={2} className="fx-align-center">
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMultipleSelect
                          Id="SelectedIsCapexRequiredIdList"
                          Name="SelectedIsCapexRequiredIdList"
                          Label="Is CAPEX Required"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedIsCapexRequiredIdList?.map(
                            (item) => item.toString()
                          )}
                          Items={
                            remediationTrackerTasksState?.DropDownListFilter
                              ?.IsCapexRequiredDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleLocationStandardDropDownListChange(
                              "SelectedIsCapexRequiredIdList",
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRTextBox
                          Id="CapexnumberAndName"
                          Name="CapexnumberAndName"
                          Label="CAPEX Number & Name"
                          Value={
                            remediationTrackerTasksPageState
                              ?.RemediationTrackerTaskSearchParameter
                              ?.RemediationTrackerTaskSearchParams
                              ?.CapexnumberAndName
                          }
                          Type={EnumTextBoxType.Search}
                          onTextBoxChange={onSearchTextBoxChange}
                          TextLength={100}
                          InputProps={{ maxLength: 100 }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMultipleSelect
                          Id="SelectedCapexStatusIdList"
                          Name="SelectedCapexStatusIdList"
                          Label="CAPEX Status"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedCapexStatusIdList?.map(
                            (item) => item.toString()
                          )}
                          Items={
                            remediationTrackerTasksState?.DropDownListFilter
                              ?.CapexStatusDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleLocationStandardDropDownListChange(
                              "SelectedCapexStatusIdList",
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRTextBox
                          Id="CapexamountRequired"
                          Name="CapexamountRequired"
                          Label="CAPEX Amount Required (M$)"
                          Value={
                            remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.CapexamountRequired?.toString() ??
                            ""
                          }
                          Type={EnumTextBoxType.Search}
                          onTextBoxChange={onSearchTextBoxChange}
                          ComponentProps={{
                            inputComponent: NumericFormatCustom as any,
                          }}
                          InputProps={{ maxLength: 12 }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {/* <Grid
                      container
                      spacing={2}
                      className="fx-align-center"
                    ></Grid> */}

                  {/* 4th row */}
                  <Grid container spacing={2} className="fx-align-center">
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group mb-0">
                        <HPRDatePicker
                          Name={"FromDueDate"}
                          Label={"Task Due Date(From)"}
                          ClassName="width100-p"
                          Value={
                            remediationTrackerTasksPageState
                              ?.RemediationTrackerTaskSearchParameter
                              ?.RemediationTrackerTaskSearchParams?.FromDueDate
                          }
                          DefaultValue={dayjs(new Date()).toString()}
                          onDateChange={onDueDateChange}
                          IsDisabledDate={(currentDate) => {
                            if (currentDate?.year() < 2023) {
                              return true;
                            } else {
                              const toDate =
                                remediationTrackerTasksPageState
                                  ?.RemediationTrackerTaskSearchParameter
                                  ?.RemediationTrackerTaskSearchParams
                                  ?.ToDueDate;
                              return (
                                dayjs(toDate).isValid() &&
                                currentDate.isAfter(
                                  dayjs(toDate).subtract(0, "day")
                                )
                              );
                            }
                          }}
                          Type={EnumDateType.DateOnly}
                        ></HPRDatePicker>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group mb-0">
                        <HPRDatePicker
                          Name={"ToDueDate"}
                          Label={"Task Due Date(To)"}
                          ClassName="width100-p"
                          Value={
                            remediationTrackerTasksPageState
                              ?.RemediationTrackerTaskSearchParameter
                              ?.RemediationTrackerTaskSearchParams?.ToDueDate
                          }
                          DefaultValue={dayjs(new Date()).toString()}
                          onDateChange={onDueDateChange}
                          IsDisabledDate={(currentDate) => {
                            if (currentDate?.year() < 2023) {
                              return true;
                            } else {
                              const fromDate =
                                remediationTrackerTasksPageState
                                  ?.RemediationTrackerTaskSearchParameter
                                  ?.RemediationTrackerTaskSearchParams
                                  ?.FromDueDate;
                              return (
                                dayjs(fromDate).isValid() &&
                                currentDate.isBefore(
                                  dayjs(fromDate).subtract(0, "day")
                                )
                              );
                            }
                          }}
                          Type={EnumDateType.DateOnly}
                        ></HPRDatePicker>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <div className="form-group">
                          <HPRDropDownMultipleSelect
                            Id="SelectedApproverIdList"
                            Name="SelectedApproverIdList"
                            Label="Task Closure Approver"
                            Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedApproverIdList?.map(
                              (item) => item.toString()
                            )}
                            Items={
                              remediationTrackerTasksState?.DropDownListFilter
                                ?.ActionOwnerDropDownList
                            }
                            onMultipleSelectDropDownChange={(
                              selectOptions: Array<DropDownDTO>,
                              selectedValues: Array<string>
                            ) =>
                              handleLocationStandardDropDownListChange(
                                "SelectedApproverIdList",
                                selectedValues
                              )
                            }
                            IsSearchable
                          />
                        </div>
                      </Grid> */}

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMultipleSelect
                          Id="SelectedAreaOwnerTypeIdList"
                          Name="SelectedAreaOwnerTypeIdList"
                          Label="Category"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedAreaOwnerTypeIdList?.map(
                            (item) => item.toString()
                          )}
                          Items={
                            remediationTrackerTasksState?.DropDownListFilter
                              ?.AreaOwnerTypeDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handleLocationStandardDropDownListChange(
                              "SelectedAreaOwnerTypeIdList",
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRDropDownMsGraphMultipleSelect
                          Id="SelectedPendingApproverIdList"
                          Name="SelectedPendingApproverIdList"
                          Label="Task Pending Approver"
                          Select={remediationTrackerTasksPageState?.RemediationTrackerTaskSearchParameter?.RemediationTrackerTaskSearchParams?.SelectedPendingApproverIdList?.map(
                            (item) => item
                          )}
                          Url="RemediationTrackerTasks/GetPendingApproverListForDropDown"
                          Items={
                            remediationTrackerTasksPageState?.DropDownListFilter
                              ?.PendingApproverDropDownList
                          }
                          onMultipleSelectDropDownChange={(
                            selectOptions: Array<DropDownDTO>,
                            selectedValues: Array<string>
                          ) =>
                            handlePendingApproverStringDropDownListChange(
                              "SelectedPendingApproverIdList",
                              selectOptions,
                              selectedValues
                            )
                          }
                          IsSearchable
                        />
                      </div>
                    </Grid>
                  </Grid>

                  {/* 5th row */}
                  <Grid container spacing={2} className="fx-align-center">
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                      <div className="form-group">
                        <HPRTextBox
                          Id="Ernumber"
                          Name="Ernumber"
                          Label="ER Number"
                          Value={
                            remediationTrackerTasksPageState
                              ?.RemediationTrackerTaskSearchParameter
                              ?.RemediationTrackerTaskSearchParams?.Ernumber
                          }
                          Type={EnumTextBoxType.Search}
                          onTextBoxChange={onSearchTextBoxChange}
                          TextLength={50}
                          InputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
              </Grid>
            </div>
            <div className="modal-button-bar">
              <Button
                variant="contained"
                disableElevation
                onClick={onclickAdvanceSearchClose}
                startIcon={<CloseCircle variant="Outline" />}
                className="secondary-btn-small"
              >
                Close
              </Button>
              <Button
                variant="contained"
                disableElevation
                onClick={onclickRemoveAllAdvancedFilters}
                startIcon={<BrushBig variant="Outline" />}
                className="danger-btn-small"
              >
                Clear filters
              </Button>
              <Button
                variant="contained"
                disableElevation
                onClick={onclickAdvanceSearchbtn}
                startIcon={<TickCircle variant="Outline" />}
                className="primary-btn-small"
              >
                Apply filters
              </Button>
            </div>
          </Box>
        </Modal>
      </Grid>
    </div>
  );
};
export default RemediationTrackerTasksPage;
