import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import {
  LOGIN_WITH_MICROSOFT_AZURE_ACTIVE_DIRECTORY_LOGIN,
  LOGIN,
  LOGOUT,
} from "./LoginPage.action";
import { login, newLogin } from "./LoginPage.api";
import { LoginDataType } from "./LoginPage.types";
import { AnyAction } from "redux";
import { authReducerActions } from "../../../core/reducers/authReducer";
import { loginReducerActions } from "./LoginPage.reducer";
import {
  ActionResponseType,
  AppOptions,
  LoginDTO,
  LoginResult,
} from "../../../common/types/common.types";
import {
  AccountInfo,
  AuthenticationResult,
  PublicClientApplication,
} from "@azure/msal-browser";
import { GetAppOptions } from "../../../common/functions/common.functions";
import { createMSALPublicClientApplicationObject } from "./LoginPage.helper";
import { EnumAuthenticationModes } from "../../../common/enums/common.enums";
import { AuthType } from "../../../core/reducers/auth.types";
import { RootState } from "../../../core/store";

// ==============================|| VIEWS - PAGES - Login - SAGA ||============================== //

const msalInstance: PublicClientApplication =
  createMSALPublicClientApplicationObject();

const loginWatcher = function* (): any {
  yield takeEvery(LOGIN, function* (action: AnyAction): any {
    yield put(loginReducerActions.clearError());
    yield put(loginReducerActions.setLoading(true));

    const loginData: LoginDataType = action.payload;

    const result: ActionResponseType = yield call(() => login(loginData));

    if (result.IsSuccess) {
      localStorage.setItem("hpr_token_store", JSON.stringify(result.Data));
      yield put(
        authReducerActions.setAuth({
          AccessToken: result?.Data?.AccessToken,
          GeneralToken: result?.Data?.GeneralToken,
          Stamp: result?.Data?.Stamp,
          SystemFunctions: result.Data.SystemFunctions,
          FunctionRights: result.Data.FunctionRights,
          AuthenticationMode: result.Data.AuthenticationMode,
          AuthenticationMod: result.Data.AuthenticationMod,
          Authenticated: true,
        } as AuthType)
      );
      yield put(loginReducerActions.clearForm());
      localStorage.setItem("loggedIn", "true");
    } else {
      yield put(loginReducerActions.setError(result.Data.ErrorMessage));
    }

    yield put(loginReducerActions.setLoading(false));
  });

  yield takeEvery(
    LOGIN_WITH_MICROSOFT_AZURE_ACTIVE_DIRECTORY_LOGIN,
    function* (action: AnyAction): any {
      yield put(loginReducerActions.clearError());
      yield put(loginReducerActions.clearProgress());
      const appOptions: AppOptions = GetAppOptions();
      // Set loading before login
      yield put(loginReducerActions.setLoading(true));
      // const authState = useAppSelector((state) => state.authReducer);
      // yield call(async () => await authState.MSALlInstance.logoutPopup());

      let signInResult: AuthenticationResult | undefined;
      try {
        // Get microsoft azure MSAL PublicClientApplication Object
        // msalInstance = yield call(createMSALPublicClientApplicationObject);

        if (msalInstance) {
          // If MSAL instance not null
          // yield put(authReducerActions.setMSALInstance(msalInstance));
          yield put(
            loginReducerActions.setProgress("Waiting for microsoft sign-in")
          );

          signInResult = yield call(() =>
            msalInstance.loginPopup({
              scopes:
                appOptions.AuthDetails.MicrosoftAzureActiveDirectoryOptions
                  .Scopes,
            })
          );
          // signInResult = yield call(
          //   async (): Promise<AuthenticationResult> =>
          //     await loginWithMicrosoftAzureActiveDirectory(msalInstance)
          // );
          yield put(loginReducerActions.clearProgress());
          if (signInResult) {
            yield put(loginReducerActions.setProgress("Logging in"));
            const apiLoginResult: ActionResponseType = yield call(() =>
              newLogin({
                MsaaIdToken: signInResult!.idToken,
                MsaaAccessToken: signInResult!.accessToken,
                LanguageId: 0,
                AuthenticationMode:
                  EnumAuthenticationModes.MicrosoftAzureActiveDirectoryAuthentication,
              } as LoginDTO)
            );
            if (apiLoginResult.IsSuccess) {
              yield put(loginReducerActions.clearProgress());
              const loginDTO: LoginResult = apiLoginResult?.Data;
              loginDTO.HomeAccountId = signInResult.account?.homeAccountId;
              // const data = {
              //   AccessToken: apiLoginResult?.Data?.accessToken,
              //   GeneralToken: apiLoginResult?.Data?.generalToken,
              //   Stamp: apiLoginResult?.Data?.stamp,
              //   Authenticated: apiLoginResult?.Data?.authenticated,
              //   HomeAccountId: signInResult.account?.homeAccountId,
              // } as AuthType;

              localStorage.setItem("hpr_token_store", JSON.stringify(loginDTO));
              yield put(
                authReducerActions.setAuth({
                  AccessToken: loginDTO?.AccessToken,
                  GeneralToken: loginDTO?.GeneralToken,
                  Stamp: loginDTO?.Stamp,
                  Authenticated: true,
                  HomeAccountId: signInResult.account?.homeAccountId,
                } as AuthType)
              );
              localStorage.setItem("loggedIn", "true");
            } else {
              yield put(loginReducerActions.clearProgress());
              yield put(
                loginReducerActions.setError(apiLoginResult.Data.ErrorMessage)
              );
            }
          } else {
            // No implementation yet
          }
        } else {
        }
      } catch (error) {
        yield put(loginReducerActions.clearProgress());
        yield put(loginReducerActions.setLoading(false));
      }
      yield put(loginReducerActions.setLoading(false));
    }
  );
};

const logoutWatcher = function* (): any {
  yield takeEvery(LOGOUT, function* (): any {
    const state: AuthType = yield select((s: RootState) => s.authReducer);

    try {
      if (state.HomeAccountId) {
        const account: AccountInfo | null = msalInstance.getAccountByHomeId(
          state.HomeAccountId
        );

        yield call(async () => {
          await msalInstance.logoutPopup({ account: account });
        });
      } else {
        yield call(async () => {
          await msalInstance.logoutPopup();
        });
      }
    } catch {}
    //    debugger;
    localStorage.removeItem("hpr_token_store");  
    yield put(authReducerActions.removeAuth());
  });
};

const loginSaga = function* () {
  yield all([loginWatcher(), logoutWatcher()]);
};

export default loginSaga;
